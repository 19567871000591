import { createStyles } from "@material-ui/core";
import adminTheme from "../../themes/admin/adminTheme";

const LeagueStyles = (theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      color: "#49495C",
    },
    topMenuContainer: {
      borderBottom: "1px solid #ebebec",
      backgroundColor: "#f5f6f7",
      marginBottom: 50,
      position: "relative",
    },
    headLogoText: {
      display: "flex",
      padding: "16px 20px",
      position: "absolute",
      top: 0,
      left: 0,
    },
    topLogo: {
      maxWidth: 40,
      maxHeight: 40,
      marginRight: 10,
    },
    topTitle: {
      fontWeight: 700,
    },
    topSummary: {
      fontSize: 10,
      color: "#00000080",
    },
    headMenu: {
      "& >div": {
        textAlign: "center",
        margin: "0 15px",
      },
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
    },
    hMenuContainer: {
      "& >div": {
        padding: "15px 30px",
        cursor: "pointer",
      },
      display: "flex",
    },
    activeMenu: {
      borderBottom: "2px solid #2241F2",
    },
    hMenuTitle: {
      fontSize: 16,
      color: "#000000",
      fontWeight: 700,
    },
    hMenuSTitle: {
      fontSize: 12,
    },
    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      headLogoText: {
        display: "none",
      },
      hMenuContainer: {
        "& >div": {
          padding: "10px 8px",
          width: "33%",
        },
      },
      hMenuTitle: {
        fontSize: 12,
      },
      hMenuSTitle: {
        display: "none",
      },
      headMenu: {
        "& >div": {
          padding: "0 7px",
          width: "100%",
        },
      },
      topMenuContainer: {
        marginBottom: 16,
      },
    },
  });

export default LeagueStyles;
