import React from "react";
import { Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import { TypeCategoryElement } from "../../../components/general/data-types/DataTypes";

import ForecastStyles from "./Forecast.styles";

interface Props extends WithStyles<typeof ForecastStyles> {
  category: TypeCategoryElement[];
}

const StatsComponent: React.FC<Props> = ({ classes, category }) => {
  const getClassColor = (item: TypeCategoryElement, team: string) => {
    if (item.isTied) {
      return "#49495C";
    }
    if (team === "home") {
      return item.homeTeamWin ? "#17612E" : "#DB2721";
    } else {
      return item.homeTeamWin ? "#DB2721" : "#17612E";
    }
  };

  return (
    <Grid container style={{ flexDirection: "column" }}>
      <div className={classes.statWhiteTop}>
        {category.map((item, index) => (
          <Typography
            style={{ color: getClassColor(item, "home") }}
            key={`${index}-${item.category}`}
          >
            {item.homeTeamValue}
            {item.isPercentage ? "%" : ""}
          </Typography>
        ))}
      </div>
      <div className={classes.stateMidleRow} style={{ display: "flex" }}>
        {category.map((item, index) => (
          <Typography key={`${index}-${item.category}`}>
            {item.category}
          </Typography>
        ))}
      </div>
      <div className={classes.statWhiteTop}>
        {category.map((item, index) => (
          <Typography
            style={{ color: getClassColor(item, "visiting") }}
            key={`${index}-${item.category}`}
          >
            {item.visitingTeamValue}
            {item.isPercentage ? "%" : ""}
          </Typography>
        ))}
      </div>
    </Grid>
  );
};

export default withStyles(ForecastStyles)(StatsComponent);
