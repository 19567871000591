import { withStyles, WithStyles } from "@material-ui/core";
import React from "react";
import LinearProgressBarStyles from "./LinearProgressBar.styles";

interface Props extends WithStyles<typeof LinearProgressBarStyles> {
  progress: number;
  reverse?: boolean;
  bgColor: string;
  color: string;
  height: number;
}

const LinearProgressBar: React.FC<Props> = ({
  classes,
  progress,
  reverse,
  bgColor,
  color,
  height,
}) => {
  return (
    <div
      className={classes.container}
      style={{ backgroundColor: bgColor, height: height }}
    >
      <div
        style={{
          transform: `translateX(${
            reverse ? 100 - progress : -(100 - progress)
          }%)`,
          backgroundColor: color,
        }}
        className={classes.item}
      ></div>
    </div>
  );
};

export default withStyles(LinearProgressBarStyles)(LinearProgressBar);
