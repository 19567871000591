import { createStyles } from "@material-ui/core";

const CreateAccPopupStyles = (theme) =>
  createStyles({
    popupContainer: {
      
      background: "#fff",
      width: 668,
      borderRadius: 12,
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
    popupDiv: {
      width: 364,
      margin: "0 auto",
      padding: "47px 0",
    },
    logoText: {
      margin: "15px 0",
      fontSize: 18,
      textAlign: "center",
      color: "#191847",
      fontWeight: "bold",
    },
    underLogoText: {
      "& span": {
        color: "#DB2721",
        cursor: "pointer",
      },
      margin: "20px 0 32px",
      fontSize: 16,
      textAlign: "center",
      color: "#49495C",
    },
    yahooLogin: {
      borderColor: "#DADCE0",
      borderWidth: 1,
      color: "#49495C",
      width: 364,
      borderRadius: 30,
      textTransform: "capitalize",
      marginTop: 20,
      maxHeight: 42,
    },
    input: {
      "& .MuiInputBase-root": {
        borderRadius: "30px",
      },
      "& label": {
        fontSize: 13,
        lineHeight: "16px",
      },
      width: "100%",
      marginBottom: 16,
    },
    loginBtn: {
      width: "100%",
      margin: "32px 0 17px",
      color: "#fff",
      fontWeight: "normal",
      borderRadius: "30px",
      textTransform: "capitalize",
    },
    orSeparator: {
      "&::after": {
        content: "''",
        position: "absolute",
        width: "40%",
        background: "#777785",
        height: 1,
        top: 10,
        right: 0,
      },
      "&::before": {
        content: "''",
        position: "absolute",
        width: "40%",
        background: "#777785",
        height: 1,
        top: 10,
        left: 0,
      },
      position: "relative",
    },
    closeIcon: {
      fontSize: 27,
      position: "absolute",
      top: 10,
      right: 10,
      cursor: "pointer",
      color: "#868686",
    },
    disclaimer: {
      "& span": {
        color: "#DB2721",
        cursor: "pointer",
      },
      fontSize: 13,
      color: "#49495C",
      textAlign: "center",
      margin: "25px 0 4px",
    },
    nameInputs: {
      display: "flex",
      justifyContent: "space-between",
    },
    ninp: {
      width: "48%",
    },
  });

export default CreateAccPopupStyles;
