import { createStyles } from "@material-ui/core";
import adminTheme from "../../../../themes/admin/adminTheme";

const BotStatsStyles = (theme) =>
  createStyles({
    matchupStats: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 5,
    },
    mlpFirst: {
      display: "flex",
      minWidth: 300,
    },
    mlpScond: {
      display: "flex",
      minWidth: 300,
    },
    firstParagraph: {
      minWidth: 55,
      marginLeft: 16,
      fontWeight: 600,
    },
    secondParagraph: {
      minWidth: 55,
      marginRight: 16,
      textAlign: "right",
      fontWeight: 600,
    },
    categoryTxt: {
      color: "#191847",
      fontWeight: 600,
    },
    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      firstParagraph: {
        fontSize: 14,
        minWidth: 42,
        marginLeft: 10,
      },
      categoryTxt: {
        fontSize: 14,
        color: "#7C828A",
        minWidth: 50,
        textAlign: "center",
      },
      secondParagraph: {
        fontSize: 14,
        minWidth: 42,
        marginRight: 10,
      },
      mlpScond: {
        minWidth: 150,
      },
      mlpFirst: {
        minWidth: 150,
      },
    },
  });

export default BotStatsStyles;
