import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Typography } from "@material-ui/core";

import styles from "./ThreeLineSkeleton.module.scss";

interface IThreeLineSkeletonProps {}

const ThreeLineSkeleton: React.FunctionComponent<IThreeLineSkeletonProps> = () => {
  return (
    <div className={styles.skeletonContainer}>
      <Typography variant="h3">
        <Skeleton variant="text" />
      </Typography>
      <Skeleton variant="text" style={{ margin: "10px 0" }} />
      <Skeleton variant="text" />
    </div>
  );
};

export default ThreeLineSkeleton;
