import * as React from "react";
import {
  TypeFilterPosition,
  TypeFilters,
  TypeFrachise,
  TypeLeaguePlayers,
  TypeOverview,
} from "../../../components/general/data-types/DataTypes";
import FiltersPage from "../../../components/player-filters/filters-page/FiltersPage";
import MobileFilters from "../../../components/player-filters/MobileFilters";

interface IFiltersPageProps {
  isMobile: boolean;
  filters: TypeFilters;
  setFilters: React.Dispatch<React.SetStateAction<TypeFilters>>;
  getPlayers: (
    pageNmber: number,
    filters: any,
    withFilter?: boolean | undefined
  ) => void;
  startDay: Date | undefined;
  setStartDay: React.Dispatch<React.SetStateAction<Date | undefined>>;
  selectedOverview: TypeOverview | undefined;
  players: TypeLeaguePlayers;
  teams: any[];
  franchises: TypeFrachise[];
  positions: TypeFilterPosition[];
  endDay: Date | undefined;
}

const MainFiltersPage: React.FunctionComponent<IFiltersPageProps> = ({
  isMobile,
  filters,
  setFilters,
  getPlayers,
  startDay,
  setStartDay,
  selectedOverview,
  players,
  teams,
  franchises,
  positions,
  endDay,
}) => {
  const onFilterChange = (filter: string, value: any) => {
    if (filter === "date") {
      setFilters({ ...filters, [filter]: value.toISOString().slice(0, 10) });
    } else if (filter === "name") {
      if (value) {
        setFilters({ ...filters, [filter]: value.name });
      } else {
        setFilters({ ...filters, [filter]: null });
      }
    } else {
      setFilters({ ...filters, [filter]: value });
    }

    switch (filter) {
      case "name":
        if (value) {
          const nameFilters = {
            ...filters,
            name: value.name,
          };
          return getPlayers(0, nameFilters, true);
        } else {
          const nameFilters = {
            ...filters,
            name: null,
          };
          return getPlayers(0, nameFilters, true);
        }
      case "status":
        const stFilters = {
          ...filters,
          status: value,
        };
        return getPlayers(0, stFilters, true);
      case "fantasyTeam":
        const teamFilters = {
          ...filters,
          fantasyTeam: value,
        };
        return getPlayers(0, teamFilters, true);
      case "health":
        const healthFilters = {
          ...filters,
          health: value,
        };
        return getPlayers(0, healthFilters, true);
      case "franchise":
        const frFilters = {
          ...filters,
          franchise: value,
        };
        return getPlayers(0, frFilters, true);
      case "position":
        const psFilters = {
          ...filters,
          position: value,
        };
        return getPlayers(0, psFilters, true);
      case "gamesRemaining":
        const gsFilter = {
          ...filters,
          gamesRemaining: value,
        };
        return getPlayers(0, gsFilter, true);
      case "date":
        setStartDay(startOfWeek(value.toISOString().slice(0, 10)));
        const dateFilter = {
          ...filters,
          date: value.toISOString().slice(0, 10),
        };
        return getPlayers(0, dateFilter, true);
      default:
        return "";
    }
  };
  const startOfWeek = (date: string) => {
    const newDate = new Date(date);
    const diff =
      newDate.getDate() - newDate.getDay() + (newDate.getDay() === 0 ? -6 : 1);
    return new Date(newDate.setDate(diff));
  };

  return (
    <>
      {isMobile ? (
        <MobileFilters
          selectedOverview={selectedOverview}
          players={players}
          getPlayers={getPlayers}
          onFilterChange={onFilterChange}
          teams={teams}
          filters={filters}
          setFilters={setFilters}
          franchises={franchises}
          positions={positions}
          startDay={startDay}
          endDay={endDay}
        />
      ) : (
        <FiltersPage
          selectedOverview={selectedOverview}
          players={players}
          getPlayers={getPlayers}
          onFilterChange={onFilterChange}
          teams={teams}
          filters={filters}
          setFilters={setFilters}
          franchises={franchises}
          positions={positions}
        />
      )}
    </>
  );
};

export default MainFiltersPage;
