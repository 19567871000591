import { createStyles } from "@material-ui/core";
import adminTheme from "../../../../../themes/admin/adminTheme";

const MatchupCardStyles = () =>
  createStyles({
    gamesPlayed: {
      "&::after": {
        top: 19,
        right: -11,
        width: 21,
        height: 21,
        content: "'vs'",
        position: "absolute",
        background: "#F5F6F7",
        textAlign: "center",
        borderRadius: "50%",
        fontSize: 12,
        fontWeight: 600,
        lineHeight: "21px",
        fontFamily: "Poppins",
      },
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      position: "relative",
    },
    secondCardContainer: {
      "& $gamesPlayedDataUnder": {
        "& img": {
          marginRight: 10,
          marginLeft: 0,
        },
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    mainContainer: {
      "&:last-child": {
        marginBottom: 0,
      },
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
      marginBottom: 20,
      padding: "0 16px",
      maxHeight: 65,
      height: 65,
      cursor: "pointer",
    },
    secondGamesPlayed: {
      "& $gpuNumber": {
        paddingRight: 15,
      },
      "& $gamesPlayedData": {
        paddingRight: 0,
      },
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      maxWidth: 177,
      paddingLeft: 15,
      width: "100%",
    },
    gamesPlayedData: {
      width: "100%",
      maxWidth: 177,
      paddingRight: 15,
    },
    gamesPlayedText: {
      display: "flex",
      justifyContent: "flex-start",
    },
    gpSecondText: {
      fontSize: 10,
      fontWeight: 600,
    },
    gamesPlayedDataUnder: {
      "& img": {
        borderRadius: "50%",
        maxHeight: 34,
        marginLeft: 8,
        marginTop: 2,
      },
      display: "flex",
      justifyContent: "space-between",
    },
    gamesPlayedUnder: {
      "& >div": {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
      },
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
    },
    gpuText: {
      fontFamily: "Roboto",
      fontSize: 14,
      color: "#000000DE",
    },
    gpuRankText: {
      fontSize: 13,
      color: "#0000008A",
      textAlign: "right",
    },
    gpuNumber: {
      fontSize: 20,
    },
    gpText: {
      fontSize: 10,
    },
    gamesSecondPlayedUnder: {
      "& >div": {
        display: "flex",
        flexDirection: "column",
      },
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
    },
    mobileOnly: {
      display: "none",
    },
    desktopOnly: {
      display: "block",
    },

    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      mobileOnly: {
        display: "block",
      },
      desktopOnly: {
        display: "none",
      },
      gamesPlayedUnder: {
        justifyContent: "flex-start",
        order: 2,
      },
      leftWinsNum: {
        order: 3,
      },
      gamesPlayedDataUnder: {
        "& img": {
          marginLeft: 0,
          marginRight: 5,
        },
      },
      gamesPlayedData: {
        paddingRight: 20,
      },
      gpuRankText: {
        textAlign: "left",
        fontSize: 10,
      },
      secondGamesPlayed: {
        paddingLeft: 20,
      },
      secondCardContainer: {
        "& $gamesPlayedDataUnder": {
          "& img": {
            marginRight: 0,
            order: 3,
            marginLeft: 5,
          },
        },
      },
      gamesSecondPlayedUnder: {
        "& >div": {
          "& p": {
            textAlign: "right",
          },
        },
        justifyContent: "flex-end",
      },
    },
  });

export default MatchupCardStyles;
