import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

interface Props {}

const StandingsSkelet: React.FC<Props> = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        minHeight: 470,
        boxSizing: "border-box",
        width: "100%",
        padding: "30px 20px",
      },
      wave: {
        marginBottom: 15,
      },
    })
  );
  const classes = useStyles();
  const nrOfLines = 12;

  return (
    <Grid className={classes.container}>
      {[...Array(nrOfLines)].map((line, i) => (
        <Skeleton key={i} animation="wave" className={classes.wave} />
      ))}
    </Grid>
  );
};

export default StandingsSkelet;
