import { Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { RootState } from "../../../../redux/reducers";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { loadTransactions } from "../../../../redux/actions/transactionActions/transactionActions";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import MobileTransactionStyles from "./MobileTransactions.styles";

interface Props extends WithStyles<typeof MobileTransactionStyles> {
}

const MobileTransactions: React.FC<Props> = ({ classes }) => {
  const { transactions } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const currentLeageIdFromLS = localStorage.getItem("selectedOverview") || "";

  useEffect(() => {
    if (!transactions.length) {
      dispatch(loadTransactions(currentLeageIdFromLS));
    }
  }, [dispatch, transactions, currentLeageIdFromLS]);

  const getTransacColor = (value: string) => {
    switch (value) {
      case "add":
        return "#17612E";
      case "drop":
        return "#db2721";
      case "add/drop":
        return "#f49719";
      case "trade":
        return "#2241f2";
      default:
        return "#f49719";
    }
  };

  const getMobileTransacs = () => {
    const newData = transactions.slice(0, 5);
    return newData.map((item) => {
      if (item.datas.length < 2) {
        return item.datas[0].type === "add"
          ? {
              ...item,
              datas: [{} as any, ...item.datas],
            }
          : { ...item, datas: [...item.datas, {} as any] };
      } else {
        return item;
      }
    });
  };

  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.mainComp}>
        <Grid container className={classes.header}>
          <Typography>Transactions</Typography>
          <EventNoteIcon style={{ color: "#DB2721" }} />
        </Grid>
        <div className={classes.container}>
          {getMobileTransacs().map((value, i) => (
            <div key={i} className={classes.item}>
              <div
                style={{
                  display: "flex",
                  direction: "initial",
                  marginLeft: i % 2 === 0 ? -160 : 160,
                }}
              >
                <img src={value.teamLogo} alt="" className={classes.teamLogo} />
                <div className={classes.topText}>
                  <Typography style={{ fontWeight: 600, fontSize: 10 }}>
                    {value.teamName}
                  </Typography>
                  <Typography style={{ fontSize: 8 }}>
                    {moment(value.timestamp)
                      .format("ddd MMM DD, hh:mm")
                      .replace(" ", "\n")}
                  </Typography>
                </div>
              </div>
              <Grid className={classes.profileContent}>
                {value.datas.map((player, i) => (
                  <Grid
                    key={i}
                    className={classes.playerContent}
                    style={{
                      width: value.datas.length === 1 ? "160px" : "110px",
                    }}
                  >
                    <span
                      style={{ borderColor: getTransacColor(value.type) }}
                      className={classes.line}
                    ></span>
                    <img
                      className={classes.playerImg}
                      src={player.playerImage}
                      alt=""
                    />
                    <Typography
                      style={{ marginBottom: 0, fontWeight: 500 }}
                      className={classes.pName}
                    >
                      {player.playerFirstName}
                    </Typography>
                    <Typography className={classes.pName}>
                      {player.playerLastName}
                    </Typography>
                    <Typography style={{ fontSize: 10, fontWeight: 700 }}>
                      {player.playerPositions}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      {player.playerFranchise &&
                        player.playerFranchise.substr(
                          0,
                          player.playerFranchise.indexOf(" ")
                        )}
                      {"\n"}
                      {player.playerFranchise &&
                        player.playerFranchise.substr(
                          player.playerFranchise.indexOf(" "),
                          player.playerFranchise.length
                        )}
                    </Typography>
                    <Grid className={classes.iconContainer}>
                      <div style={{ display: "flex", direction: "initial" }}>
                        {player.type && (
                          <>
                            {player.type === "add" ? (
                              <>
                                <ControlPointIcon
                                  className={classes.icon}
                                  style={{ color: "#17612E" }}
                                />
                                <Typography className={classes.iconText}>
                                  {player.sourceType}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <RemoveCircleOutlineIcon
                                  className={classes.icon}
                                  style={{ color: "#DB2721" }}
                                />
                                <Typography className={classes.iconText}>
                                  {player.destinationType}
                                </Typography>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(MobileTransactionStyles)(MobileTransactions);
