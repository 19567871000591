import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from "@material-ui/core";
import Header from "../../components/main/header/Header";
import AdminTheme from "../../themes/admin/adminTheme";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import PeopleIcon from "@material-ui/icons/People";
import Cookie from "js-cookie";

import styles from "./ProtectedLayout.module.scss";
import { fetchUser, signUp } from "../../components/api/general/api";
import { useAuth } from "../../components/login/AuthContext";
import { TypeLogedUser } from "../../components/general/data-types/DataTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { loadOverviewsData } from "../../redux/actions/overviewsActions/overviewsActions";
import { getPageNumber } from "../../components/general/utils/Utils";

interface IProtectedLayoutProps {
  setUserData: React.Dispatch<React.SetStateAction<TypeLogedUser | undefined>>;
}

const ProtectedLayout: React.FunctionComponent<IProtectedLayoutProps> = ({
  setUserData,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const isMobile = useMediaQuery(AdminTheme.breakpoints.down("xs"));
  const [botMenuVal, setBotMenuVal] = useState(0);

  const {
    overviews: { selectedOverview },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      currentUser &&
        currentUser
          .getIdToken(true)
          .then((token: any) => Cookie.set("tokenId", token, { path: "/" }));
    }, 30 * 60 * 1000);
    return () => clearInterval(interval);
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchUser(currentUser.email)
        .then((res) => {
          const statusCode = res.status;
          const data = res.json();
          return Promise.all([statusCode, data]);
        })
        .then(([statusCode, data]) => {
          if (statusCode === 200) {
            setUserData(data);
          } else if (statusCode === 404 && data.message === "User not found!") {
            const payload = {
              email: currentUser.email,
              name: currentUser.displayName,
            };
            signUp(payload)
              .then((res) => {
                const statusCode = res.status;
                const data = res.json();
                return Promise.all([statusCode, data]);
              })
              .then(([statusCode, data]) => {
                if (statusCode === 201) {
                  setUserData(data);
                }
              });
          }
        });
    }
  }, [currentUser, location.pathname, setUserData]);

  useEffect(() => {
    if (currentUser) {
      dispatch(loadOverviewsData());
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    setBotMenuVal(getPageNumber(location.pathname));
  }, [location]);

  const onBotMenuChange = (page: number) => {
    setBotMenuVal(page);
    switch (page) {
      case 0:
        return navigate("/overview");
      case 1:
        if (
          selectedOverview &&
          selectedOverview.league &&
          selectedOverview.league.id
        ) {
          return navigate(`/league/${selectedOverview.league.id}`);
        } else {
          return navigate("/overview");
        }
      case 2:
        if (
          selectedOverview &&
          selectedOverview.matchup &&
          selectedOverview.matchup.id
        ) {
          return navigate(`/my-matchup/${selectedOverview.matchup.id}`);
        } else {
          return navigate("/overview");
        }
      case 3:
        if (
          selectedOverview &&
          selectedOverview.league &&
          selectedOverview.league.id
        ) {
          return navigate(`/players/${selectedOverview.league.id}`);
        } else {
          return navigate("/overview");
        }
      default:
        return navigate("/overview");
    }
  };

  return (
    currentUser && (
      <>
        <div className={styles.root}>
          <Header userData={currentUser} />
          <div className={styles.toolbar} />
          <div className={styles.containerStyles}>
            <Outlet />
          </div>
          {isMobile && (
            <>
              <BottomNavigation
                value={botMenuVal}
                onChange={(_, newValue) => onBotMenuChange(newValue)}
                showLabels
                className={styles.botMenu}
              >
                <BottomNavigationAction
                  label="Overview"
                  icon={<GroupWorkIcon />}
                />
                <BottomNavigationAction
                  label="League"
                  icon={<DashboardIcon />}
                />
                <BottomNavigationAction
                  label="Matchup"
                  icon={<BubbleChartIcon />}
                />
                <BottomNavigationAction label="Players" icon={<PeopleIcon />} />
              </BottomNavigation>
            </>
          )}
        </div>
      </>
    )
  );
};

export default ProtectedLayout;
