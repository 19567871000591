import { createStyles } from "@material-ui/core";

const MobileMatchupCardStyles = () =>
  createStyles({
    mainContainer: {
      "&:last-child": {
        borderBottom: "none",
      },
      padding: "16px 0px",
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      borderBottom: "3px dashed #ededed",
    },
    gpuText: {
      fontFamily: "Roboto",
      fontSize: 14,
      color: "#000000DE",
    },
    matchupContent: {
      maxWidth: 180,
      minWidth: 180,
    },
    gamesPlayedText: {
      display: "flex",
      justifyContent: "space-between",
    },
    smallText: {
      fontSize: 10,
      fontWeight: 600,
    },
    gamesPlayedDataUnder: {
      "& img": {
        borderRadius: "50%",
        maxHeight: 34,
        marginRight: 10,
        marginTop: 2,
      },
      display: "flex",
      justifyContent: "space-between",
    },
    gamesPlayedUnder: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    gpuRankText: {
      fontSize: 13,
      color: "#0000008A",
    },
    gpuNumber: {
      fontSize: 24,
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
    },
    card: {
      display: "flex",
      justifyContent: "space-between",
    },
  });

export default MobileMatchupCardStyles;
