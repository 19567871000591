import { Grid } from "@material-ui/core";
import { isEmpty } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import AddsThisWeek from "../../general-components/AddsThisWeek";
import ThreeLineSkeleton from "../../skeletons/ThreeLineSkeleton/ThreeLineSkeleton";

import styles from "./AddsThisWeekBox.module.scss";

interface IAddsThisWeekBoxProps {}

const AddsThisWeekBox: React.FunctionComponent<IAddsThisWeekBoxProps> = () => {
  const {
    matchups: { weekAdds },
  } = useSelector((state: RootState) => state);

  if (isEmpty(weekAdds)) {
    return (
      <Grid
        item
        xs={12}
        sm={4}
        className={`${styles.weekDataContainer} ${styles.shadowContainer}`}
      >
        <ThreeLineSkeleton />
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={12}
      sm={4}
      className={`${styles.weekDataContainer} ${styles.shadowContainer}`}
    >
      <AddsThisWeek weekAdds={weekAdds} />
    </Grid>
  );
};

export default AddsThisWeekBox;
