import React, { useState } from "react";
import CreateAccPopup from "../../components/createAccPopup/CreateAccPopup";
import ForgotPassPopup from "../../components/forgot-pass-popup/ForgotPassPopup";
import LoginPopup from "../../components/login-popup/LoginPopup";

interface ISignInPageProps {}

export const SignInPage: React.FunctionComponent<ISignInPageProps> = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const action = queryParams.get("action") || "signIn";
  const [pageType, setPageType] = useState(action);

  const RenderPage = () => {
    switch (pageType) {
      case "signIn":
        return <LoginPopup setPageType={setPageType} />;
      case "signUp":
        return <CreateAccPopup setPageType={setPageType} />;
      case "forgotPass":
        return <ForgotPassPopup setPageType={setPageType} />;
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <RenderPage />
    </div>
  );
};
