import { TypeTeam } from "../../components/general/data-types/DataTypes";
import * as types from "../actions/action-types/actionTypes";
import { TeamActions } from "../actions/teamsActions/teamActionsInterface";

export default function teamsReducer(
  state = [] as TypeTeam[],
  action: TeamActions
) {
  switch (action.type) {
    case types.FETCH_TEAMS_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
