import { createStyles } from "@material-ui/core";

const RosterStyles = (theme) =>
  createStyles({
    shadowContainer: {
      boxShadow: "0px 1px 3px #00000029",
      borderRadius: 12,
      background: "#fff",
    },
    amaStatsTitle: {
      textAlign: "center",
    },
    amaStatsbot: {
      fontSize: 10,
    },
    amaStatsTitleTopB: {
      fontSize: 34,
      color: "#212529",
      fontWeight: 700,
    },
    amaStatsTitleTopG: {
      fontSize: 34,
      color: "#558B95",
      fontWeight: 700,
    },
    amaStatsTitleTopY: {
      fontSize: 34,
      color: "#FF9718",
      fontWeight: 700,
    },
    amaStatsTopContainer: {
      textAlign: "center",
      maxHeight: 62,
    },
    healthContainer: {
      display: "flex",
      padding: "14px 26px",
      alignItems: "center",
      flexDirection: "column",
      width: "20%",
      boxSizing: "border-box",
      maxWidth: 236,
    },
    teamBodyContainer: {
      "& div": {
        display: "flex",
        alignItems: "center",
      },
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    hcTitle: {
      display: "flex",
      marginBottom: 24,
    },
    hoverIcon: {
      marginRight: 5,
      color: "#558B95",
    },
    iconHeal: {
      color: "#FF4133",
      marginRight: 10,
    },
    iconQuestion: {
      color: "#FF9718",
      marginRight: 10,
    },
    iconCheck: {
      color: "#188038",
      marginRight: 10,
    },
    playingToday: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "14px 0px",
      width: "17%",
      maxWidth: 186,
      boxSizing: "border-box",
    },
    playingTodayTitle: {
      marginBottom: 24,
      display: "flex",
    },
    playingTodayBody: {
      display: "flex",
      justifyContent: "space-around",
    },
    iconPeople: {
      color: "#49495C",
      marginRight: 10,
    },
    addsThisWeek: {
      "& $shadowContainer": {
        padding: "16px 15px",
      },
      width: "20%",
      padding: "14px 9px",
      maxWidth: 220,
    },
    gpSummary: {},
    gpContainer: {
      marginTop: 26,
    },
    gamesPlayed: {
      "& $shadowContainer": {
        padding: "16px",
      },
      "& $gpSummary": {
        fontSize: 24,
        color: "#558B95",
        fontWeight: 700,
        textAlign: "center",
      },
      width: "13%",
      padding: "14px 9px",
      textAlign: "center",
      maxWidth: 148,
    },
    teamData: {
      "& img": {
        width: 80,
        height: 80,
        borderRadius: "50%",
        margin: "19px 10px 19px 32px",
      },
      width: "30%",
      display: "flex",
      maxWidth: 348,
    },
    tdTitle: {
      fontSize: 24,
      color: "#191847",
      fontWeight: 600,
    },
    rosterContainer: {
      maxWidth: 1266,
      width: "100%",
      display: "flex",
      margin: "0 auto 50px",
      justifyContent: "space-between",
    },
    playersTable: {
      maxWidth: "1280px",
      margin: "35px auto 60px",
    },
    optionSelect: {
      "& p": {
        padding: "6px 16px",
        fontSize: 12,
        cursor: "pointer",
      },
      marginBottom: 16,
      display: "flex",
      boxShadow: "0px 1px 3px #00000029",
      borderRadius: 12,
      overflow: "hidden",
    },
    opActive: {
      backgroundColor: "#FF9718",
      color: "#fff",
    },
    nothingToDisplay: {
      padding: "10px 0px 10px",
      fontSize: 14,
      textAlign: "center",
    },
  });

export default RosterStyles;
