export const backendCfg = {
  apiGateway: process.env.REACT_APP_API_GATEWAY,
  domain: process.env.REACT_APP_DOMAIN,
};

export const payment = {
  createDonateSession:
    backendCfg.apiGateway +
    "/payment-service/api/payment/create-donate-session",
  stripePriceList:
    backendCfg.apiGateway + "/payment-service/api/payment/list-donate-prices",
};

export const auth = {
  signIn: backendCfg.apiGateway + "/user-management-service/api/public/sign-in",
  signUp: backendCfg.apiGateway + "/user-management-service/api/public/sign-up",
  users: backendCfg.apiGateway + "/user-management-service/api/users",
};

export const yahooAuth = {
  fetchUrl:
    backendCfg.apiGateway +
    "/yahoo-authorization-service/api/authentication-url",
  generateToken:
    backendCfg.apiGateway + "/yahoo-authorization-service/api/oauth",
  apiUrl: process.env.REACT_APP_YAHOO_AUTH_URI,
  clientId: process.env.REACT_APP_YAHOO_CLIENT_ID,
  redirectUri: process.env.REACT_APP_YAHOO_REDIRECT_URI,
};

export const yahooFantasyBasket = {
  fetchUserLeagues:
    backendCfg.apiGateway + "/yahoo-fantasy-basketball/api/leagues/yahoo",
  fetchLeaguesOverview:
    backendCfg.apiGateway + "/yahoo-fantasy-basketball/api/leagues/overview",
  leagues: backendCfg.apiGateway + "/yahoo-fantasy-basketball/api/leagues",
  matchups: backendCfg.apiGateway + "/yahoo-fantasy-basketball/api/matchups",
  teams: backendCfg.apiGateway + "/yahoo-fantasy-basketball/api/teams",
  players: backendCfg.apiGateway + "/yahoo-fantasy-basketball/api/players",
  games: backendCfg.apiGateway + "/yahoo-fantasy-basketball/api/games",
};

export const nbaService = {
  franchises: backendCfg.apiGateway + "/nba-service/api/franchises",
};
