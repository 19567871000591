import { createStyles } from "@material-ui/core";

const MobileTransactionStyles = (theme) =>
  createStyles(
    {
      container: {
        "& .active": {
          cursor: "grabbing",
        },
        position: "relative",
        paddingTop: 30,
        paddingBottom: 30,

        "&::after": {
          content: "''",
          width: 3,
          position: "absolute",
          background: "#558B95",
          opacity: 0.5,
          height: "calc(100% - 40px)",
          top: 20,
          right: "50%",
        },
      },
      item: {
        "&:first-child": {
          marginRight: "0px !important",
          boxSizing: "border-box",
        },
        "&:last-child": {
          marginLeft: "0px !important",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: 40,
      },
      topText: {
        textAlign: "left",
        paddingLeft: 8,
      },
      profileContent: {
        display: "flex",
        minHeight: 170,
        direction: "initial",
        marginTop: 23,
        position: "relative",
      },
      playerContent: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      },
      top: {
        "& img": {
          width: 27,
          height: 27,
          borderRadius: "50%",
        },
        "& p": {
          fontSize: 10,
        },
        display: "flex",
        marginBottom: 20,
        justifyContent: "center",
      },
      line: {
        content: "''",
        position: "absolute",
        top: -43,
        left: "calc(50% - 10px)",
        width: 11,
        height: 11,
        background: "#fff",
        border: "3px solid",
        borderRadius: "50%",
        zIndex: 9,
      },
      playerImg: {
        width: 60,
        marginBottom: 5,
      },
      pName: {
        fontWeight: 600,
        color: "#212529",
        marginBottom: 12,
        fontSize: 14,
        textAlign: "center",
      },
      subtitle: {
        fontSize: 10,
        color: "#00000080",
        textTransform: "uppercase",
        textAlign: "center",
        whiteSpace: "break-spaces",
      },
      icon: {
        fontSize: 20,
        marginRight: 7,
      },
      iconText: {
        fontSize: 10,
        lineHeight: "21px",
      },
      iconContainer: {
        display: "flex",
        justifyContent: "center",
        paddingTop: 6,
      },
      teamLogo: {
        width: 25,
        height: 25,
        borderRadius: "50%",
      },
      header: {
        "& p": {
          color: "#49495C",
          fontSize: 16,
          fontWeight: 700,
        },
        borderBottom: "1px solid #e9e8e9",
        display: "flex",
        justifyContent: "space-between",
        padding: 16,
      },
      mainComp: {
        boxShadow: "0px 1px 3px #00000029",
        borderRadius: 12,
        backgroundColor: "#fff",
      },
      mainContainer: {
        padding: "0 16px",
        marginBottom: 35,
        flexDirection: "column",
        width: "100%",
      },
    },
    {
      name: "",
    }
  );

export default MobileTransactionStyles;
