import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthContext";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";
import * as yup from "yup";
import { useFormik } from "formik";
import GoogleLogin from "../login/GoogleLogin";
import YahooIcon from "../../static/logo/YahooIcon.png";

import CreateAccPopupStyles from "./CreateAccPopup.styles";
import { signUp } from "../api/general/api";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
});
interface Props extends WithStyles<typeof CreateAccPopupStyles> {
  setPageType: React.Dispatch<React.SetStateAction<string>>;
}

const CreateAccPopup: React.FC<Props> = React.forwardRef(
  ({ classes, setPageType }, _) => {
    const openLogin = () => {
      setPageType("signIn");
    };
    const [error, setError] = useState("");
    const { loginWithGoogle, loginWithYahoo, firebaseSignUp } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (values: any) => {
      if (values.password !== values.confirmPassword) {
        return setError("Passwords do not match");
      } else {
        try {
          setError("");
          await firebaseSignUp(values.email, values.password)
            .then(() => {
              const payload = {
                email: values.email,
                name: values.firstName + " " + values.lastName,
              };
              signUp(payload);
              setPageType("login");
              toast.success(
                "Verification link was sent to your email. Please verify your account!"
              );
            })
            .catch((error: any) => {
              console.log("Create user error: ", error.message);
              toast.error(
                "We are having trouble creating your account right now, please try again later."
              );
            });
        } catch {
          setError("Failed to create an account");
        }
      }
    };

    const formik = useFormik({
      initialValues: {
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmit(values);
      },
    });

    const handleGoogleLogin = async () => {
      try {
        setError("");
        await loginWithGoogle(signUp)
          .then(() => {
            navigate("/overview");
          })
          .catch((error: any) => {
            toast.error(error.message);
          });
      } catch {
        setError("Failed to log in");
      }
    };

    const handleYahooLogin = async () => {
      try {
        setError("");
        await loginWithYahoo(signUp)
          .then(() => {
            navigate("/overview");
          })
          .catch((error: any) => {
            toast.error(error.message);
          });
      } catch {
        setError("Failed to log in");
      }
    };

    return (
      <Grid container className={classes.popupContainer}>
        <Grid container className={classes.popupDiv}>
          <Grid item xs={12}>
            <Typography className={classes.logoText}>Create Account</Typography>
            <Typography className={classes.underLogoText}>
              Already have an account? <span onClick={openLogin}>Log in</span>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <GoogleLogin handleGoogleLogin={handleGoogleLogin} />
            <Button
              variant="outlined"
              size="large"
              disableElevation
              className={classes.yahooLogin}
              onClick={handleYahooLogin}
              startIcon={
                <img
                  style={{ maxWidth: 22, borderRadius: "50%" }}
                  src={YahooIcon}
                  alt="Yahoo Icon"
                />
              }
            >
              Continue with Yahoo
            </Button>
            <Typography
              className={classes.orSeparator}
              style={{ margin: "32px 0" }}
            >
              Or
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid item container className={classes.nameInputs}>
                <div className={classes.ninp}>
                  <TextField
                    value={formik.values.firstName}
                    label="First Name"
                    id="firstName"
                    name="firstName"
                    variant="outlined"
                    className={classes.input}
                    size="small"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </div>
                <div className={classes.ninp}>
                  <TextField
                    value={formik.values.lastName}
                    label="Last Name"
                    id="lastName"
                    name="lastName"
                    variant="outlined"
                    className={classes.input}
                    size="small"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </div>
              </Grid>
              <TextField
                value={formik.values.email}
                label="Email address"
                id="email"
                name="email"
                variant="outlined"
                className={classes.input}
                size="small"
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                value={formik.values.password}
                label="Password"
                variant="outlined"
                id="password"
                name="password"
                type="password"
                className={classes.input}
                size="small"
                style={{ marginBottom: 11 }}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <TextField
                value={formik.values.confirmPassword}
                label="Confirm password"
                variant="outlined"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                className={classes.input}
                size="small"
                style={{ marginBottom: 11 }}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
              {error && <Alert severity="error">{error}</Alert>}
              <Typography className={classes.disclaimer}>
                By creating an account, I agree with Fantasy Insights
                <br />
                <span>Privacy Policy</span> and <span>Terms of Service</span>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.loginBtn}
              >
                Create account
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default withStyles(CreateAccPopupStyles)(CreateAccPopup);
