export const FETCH_OVERVIEWS_SUCCESS = "FETCH_OVERVIEWS_SUCCESS";
export const FETCH_TEAMS_SUCCESS = "FETCH_TEAMS_SUCCESS";
export const FETCH_MATCHUPS_SUCCESS = "FETCH_MATCHUPS_SUCCESS";
export const FETCH_TEAM_SCHEDULE_SUCCESS = "FETCH_TEAM_SCHEDULE_SUCCESS";
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const FETCH_OVERVIEWS_FAILED = "FETCH_OVERVIEWS_FAILED";
export const BEGIN_API_CALL_OVERVIEW = "BEGIN_API_CALL_OVERVIEW";
export const BEGIN_API_CALL_MATCHUPS = "BEGIN_API_CALL_MATCHUPS";
export const BEGIN_API_CALL_PLAYERS = "BEGIN_API_CALL_PLAYERS";
export const FETCH_ALL_PLAYERS_SUCCESS = "FETCH_ALL_PLAYERS_SUCCESS";
export const CHANGE_SELECTED_OVERVIEW_SUCCESS =
  "CHANGE_SELECTED_OVERVIEW_SUCCESS";
export const FETCH_ALL_PLAYER_NAMES_SUCCESS = "FETCH_ALL_PLAYER_NAMES_SUCCESS";
export const FETCH_MATCHUPS_WINS_SUCCESS = "FETCH_MATCHUPS_WINS_SUCCESS";
export const FETCH_MATCHUPS_WEEKLY_ADDITIONS_SUCCESS =
  "FETCH_MATCHUPS_WEEKLY_ADDITIONS_SUCCESS";
export const FETCH_NEXT_MATCHUP_SUCCESS = "FETCH_NEXT_MATCHUP_SUCCESS";
export const FETCH_TEAM_ROSTER_SUCCESS = "FETCH_TEAM_ROSTER_SUCCESS";
export const FETCH_TEAM_AV_STATS_SUCCESS = "FETCH_TEAM_AV_STATS_SUCCESS";
export const FETCH_ALL_PLAYERS_FAILED = "FETCH_ALL_PLAYERS_FAILED";
export const FETCH_TEAM_TOTALS_SUCCESS = "FETCH_TEAM_TOTALS_SUCCESS";
export const FETCH_TEAM_WINS_SUCCESS = "FETCH_TEAM_WINS_SUCCESS";
export const FETCH_NBA_SCHEDULE = "FETCH_NBA_SCHEDULE";
export const BEGIN_API_CALL_MATCHUPS_SCORE = "BEGIN_API_CALL_MATCHUPS_SCORE";
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS";
export const FETCH_NO_RESPONSE = "FETCH_NO_RESPONSE";
export const BEGIN_API_CALL_WEEK_ADDS = "BEGIN_API_CALL_WEEK_ADDS";
export const BEGIN_API_CALL_LOAD_ROOSTER = "BEGIN_API_CALL_LOAD_ROOSTER";
export const CHANGE_SELECTED_TEAM_SUCCESS = "CHANGE_SELECTED_TEAM_SUCCESS";
export const FETCH_FAILED = "FETCH_FAILED";
