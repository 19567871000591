import { createStyles } from "@material-ui/core";

const PlayersTableStyles = (theme) =>
  createStyles({
    playersData: {
      minWidth: 160,
      padding: "6px 0px 6px 0px",
    },
    avImage: {
      float: "left",
      marginRight: 8,
    },
    fixedCategory: {
      minWidth: 48,
      maxWidth: 50,
      padding: "5px 0 !important",
      borderLeft: "2px solid transparent",
    },
    playerName: {
      fontSize: 12,
      fontWeight: 600,
      fontFamily: "'Poppins'",
      display: "flex",
    },
    selectedDay: {
      borderTop: "2px solid #f49719 !important",
      borderRight: "2px solid #f49719 !important",
      borderLeft: "2px solid #f49719 !important",
    },
    weekDayLogos: {
      "&:last-child": {
        borderRight: "2px solid #e0e0e0",
      },
      borderLeft: "2px solid transparent",
    },
    selectedDayRow: {
      borderRight: "2px solid #f49719 !important",
      borderLeft: "2px solid #f49719",
    },
    playerDetails: {
      fontSize: 10,
      display: "flex",
      alignItems: "end",
      fontWeight: 500,
    },
    playerImg: {
      maxHeight: 37,
      marginRight: 5,
      float: "left",
    },
    weekDays: {
      minWidth: 50,
      maxWidth: 50,
      whiteSpace: "break-spaces",
      cursor: "pointer",
      borderTop: "2px solid transparent",
      borderLeft: "2px solid transparent",
    },
    lessPadding: {
      padding: 6,
    },
    iconContainer: {
      "& svg": {
        float: "left",
        fontSize: 18,
        marginRight: 3,
      },
      "& p": {
        fontSize: 12,
        float: "left",
      },
      width: 50,
      margin: "0 auto",
    },
    generalTable: {
      width: "840px",
      float: "left",
    },
    statsTable: {
      "& thead": {
        "& tr": {
          height: 63,
        },
      },
      "& tbody": {
        "& tr": {
          height: 53,
        },
      },
      width: "440px",
      position: "relative",
      scrollBehavior: "smooth",
      overflow: "hidden",
    },
    statsTableHeader: {
      "& button": {
        width: 35,
        height: 35,
      },
      position: "absolute",
      top: 0,
      right: 0,
      display: "flex",
      zIndex: 9,
      justifyContent: "space-between",
      width: 445,
    },
    tableRow: {
      verticalAlign: "bottom",
    },
    scrollLeft: {
      color: "#d8d8d8",
    },
    scrollRight: {
      color: "#d8d8d8",
    },
    tablesContainer: {
      position: "relative",
      width: "100%",
    },
    withColor: {
      color: "#f49719",
    },
    tdText: {
      fontSize: 14,
      fontWeight: 600,
    },
    smallTD: {
      width: "30px !important",
    },
    greenPlus: {
      color: "#17612E",
      fontSize: 15,
      marginLeft: 10,
    },
    redMinus: {
      color: "#DB2721",
      fontSize: 15,
      marginLeft: 10,
    },
    yellowPlus: {
      color: "#FF9718",
      fontSize: 15,
      marginLeft: 10,
    },
    graySwap: {
      color: "#49495C",
      fontSize: 15,
      marginLeft: 10,
    },
  });

export default PlayersTableStyles;
