import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  TypeOverview,
  TypeTeam,
  TypeTeamStat,
} from "../../../components/general/data-types/DataTypes";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import LoadingCircle from "../../../components/general/LoadingCircle/LoadingCircle";

import TeamStatStyles from "./TeamStats.styles";
import { trimText } from "../../../components/general/utils/Utils";

interface Props extends WithStyles<typeof TeamStatStyles> {
  selectedOverview: TypeOverview;
  teamAverages: TypeTeamStat[][];
  teamTotals: TypeTeamStat[][];
  teamWins: TypeTeamStat[][];
  teamsData: TypeTeam[];
}

const TeamStats: React.FC<Props> = ({
  classes,
  selectedOverview,
  teamAverages,
  teamTotals,
  teamWins,
  teamsData,
}) => {
  const [options, setOptions] = useState("Averages");
  const [teamsFilter, setTeamsFilter] = useState<string[]>([]);
  const { innerWidth } = window;

  const selectedData = () => {
    switch (options) {
      case "Totals":
        return getFilteredData(teamTotals);
      case "Wins":
        return getFilteredData(teamWins);
      case "Averages":
        return getFilteredData(teamAverages);
      default:
        return teamAverages;
    }
  };

  const getFilteredData = (data: TypeTeamStat[][]) => {
    if (teamsFilter.length === 0) {
      return data;
    } else {
      return data.map((item) =>
        item.map((val) =>
          teamsFilter.includes(val.teamName) ? val : { ...val, hidden: true }
        )
      );
    }
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  return (
    <Grid container>
      <Grid container style={{ justifyContent: "center" }}>
        <div className={classes.optionSelect}>
          <Typography
            className={options === "Totals" ? classes.opActive : ""}
            onClick={() => setOptions("Totals")}
          >
            Totals
          </Typography>
          <Typography
            className={options === "Wins" ? classes.opActive : ""}
            onClick={() => setOptions("Wins")}
          >
            Wins
          </Typography>
          <Typography
            className={options === "Averages" ? classes.opActive : ""}
            onClick={() => setOptions("Averages")}
          >
            Averages
          </Typography>
        </div>
      </Grid>
      <Grid container className={classes.filterContainer}>
        <FormControl className={classes.filterControl}>
          <InputLabel id="demo-mutiple-chip-label" color="secondary">
            Teams
          </InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            color="secondary"
            value={teamsFilter}
            onChange={(e: any) => setTeamsFilter(e.target.value)}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (selected as string[]).join(", ")}
            MenuProps={MenuProps}
          >
            {teamsData.map((team, i) => (
              // duplicate Id's here
              <MenuItem key={i} value={team.name}>
                <Checkbox checked={teamsFilter.indexOf(team.name) > -1} />
                <ListItemText primary={team.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Tooltip title="Reset">
          <IconButton
            className={classes.resetBtn}
            onClick={() => setTeamsFilter([])}
          >
            <RotateLeftIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid container className={classes.container}>
        {selectedOverview &&
        teamTotals.length &&
        teamWins.length &&
        teamAverages.length ? (
          <TableContainer style={{ maxWidth: innerWidth - 30 }}>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.sticky}>
                    <Typography>Rank</Typography>
                  </TableCell>
                  {selectedOverview.league.categoryList.map((item, i) => (
                    <TableCell key={i}>
                      <Typography align="center" style={{ fontSize: 14 }}>
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedData().map((team, i: number) => (
                  <TableRow key={i} hover>
                    <TableCell align="center" className={classes.sticky}>
                      {i + 1}
                    </TableCell>
                    {team.map((item, i: number) => (
                      <TableCell key={i} className={classes.tableCell}>
                        <div
                          className={`${classes.tableDataContainer} ${
                            item.hidden ? classes.hidden : ""
                          }`}
                          style={{
                            background:
                              item.teamName === selectedOverview.team.name
                                ? "rgba(244, 151, 25, 0.3)"
                                : "",
                          }}
                        >
                          <img src={item.teamLogo} alt="Team logo" />
                          <div className={classes.asColumn}>
                            <Typography>
                                {trimText(item.teamName, 15)}
                            </Typography>
                            <Typography>
                              {options === "Wins" ? item.details : item.value}
                              {item.isPercentage ? "%" : ""}
                            </Typography>
                          </div>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <LoadingCircle />
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(TeamStatStyles)(TeamStats);
