import { Dispatch } from "redux";
import { fetchLeagueSchedule } from "../../../components/api/general/api";
import * as types from "../action-types/actionTypes";
import { TeamScheduleActions } from "./teamScheduleActionInterface";
import { TypeTeamSchedule } from "../../../components/general/data-types/DataTypes";

export function fetchTeamScheduleSuccess(
  data: TypeTeamSchedule[]
): TeamScheduleActions {
  return { type: types.FETCH_TEAM_SCHEDULE_SUCCESS, data };
}

export function loadTeamScheduleData(id: string) {
  return function (dispatch: Dispatch) {
    return fetchLeagueSchedule(id)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          dispatch(fetchTeamScheduleSuccess(data));
        }
      })
      .catch((error) => {
        console.log("fetch Teams Schedule error: ", error);
      });
  };
}
