import { Dispatch } from "redux";
import {
  fetchLeagueMatchups,
  fetchMatchupWins,
  fetchNextMatchup,
  fetchWeeklyAdditions,
} from "../../../components/api/general/api";
import * as types from "../action-types/actionTypes";
import { MatchupsAction } from "./matchupActionsInterface";
import { beginApiCall } from "../apiStatusAction/apiStatusAction";
import {
  TypeMatchup,
  TypeMatchupWins,
  TypeWeekAdds,
} from "../../../components/general/data-types/DataTypes";

export function fetchMatchupsSuccess(data: TypeMatchup[]): MatchupsAction {
  return { type: types.FETCH_MATCHUPS_SUCCESS, data };
}

export function fetchMatchupWinsSuccess(data: TypeMatchupWins): MatchupsAction {
  return { type: types.FETCH_MATCHUPS_WINS_SUCCESS, data };
}

export function fetchWeeklyAdditionsSuccess(
  data: TypeWeekAdds
): MatchupsAction {
  return { type: types.FETCH_MATCHUPS_WEEKLY_ADDITIONS_SUCCESS, data };
}

export function fetchNextMatchupSuccess(data: TypeMatchup): MatchupsAction {
  return { type: types.FETCH_NEXT_MATCHUP_SUCCESS, data };
}

export function onFetchNoResponse(): MatchupsAction {
  return { type: types.FETCH_NO_RESPONSE };
}

export function loadMatchupsData(id: string) {
  return function (dispatch: Dispatch) {
    dispatch(beginApiCall("BEGIN_API_CALL_MATCHUPS"));
    return fetchLeagueMatchups(id)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          dispatch(fetchMatchupsSuccess(data));
        }
      })
      .catch((error) => {
        console.log("fetch Matchups error: ", error);
      });
  };
}

export function loadMatchupWins(id: string) {
  return function (dispatch: Dispatch) {
    return fetchMatchupWins(id)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          dispatch(fetchMatchupWinsSuccess(data));
        }
      })
      .catch((error) => {
        console.log("fetch Matchups Score error: ", error);
      });
  };
}

export function loadWeeklyAdditions(id: string) {
  return function (dispatch: Dispatch) {
    dispatch(beginApiCall("BEGIN_API_CALL_WEEK_ADDS"));
    return fetchWeeklyAdditions(id)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          dispatch(fetchWeeklyAdditionsSuccess(data));
        }
      })
      .catch((error) => {
        console.log("fetch Matchups Score error: ", error);
      });
  };
}

export function loadNextMatchup(id: string) {
  return function (dispatch: Dispatch) {
    return fetchNextMatchup(id)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          if (data === null) {
            dispatch(onFetchNoResponse());
          } else {
            dispatch(fetchNextMatchupSuccess(data));
          }
        }
      })
      .catch((error) => {
        console.log("fetch Matchups Score error: ", error);
      });
  };
}
