import { createStyles } from "@material-ui/core";
import adminTheme from "../../../themes/admin/adminTheme";

const HeaderStyles = (theme) =>
  createStyles({
    appBar: {
      backgroundColor: "white",
      color: "#1F2026",
      height: 61,
    },
    headerFont: {
      cursor: "pointer",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    topNav: {
      "& li": {
        display: "inline-block",
        margin: "0 13px",
        padding: 0,
      },
      "& a": {
        padding: "22px 0 11px",
        display: "block",
        textDecoration: "none",
        color: "#1f2026",
        width: 80,
      },
      margin: 0,
      textAlign: "center",
    },
    avImage: {
      marginLeft: 20,
      marginTop: 4,
      backgroundColor: "#f49719",
    },
    userDropdown: {
      position: "absolute",
      top: 76,
      right: 15,
      width: 280,
      borderRadius: 10,
      boxShadow: "0px 3px 6px #00000029",
      backgroundColor: "#fff",
      color: "#191847",
    },
    popupEmail: {
      color: "#19184799",
      fontSize: 12,
    },
    popupUserName: {
      fontSize: 15,
      color: "#191847",
      fontWeight: 600,
    },
    headName: {
      fontWeight: 600,
      fontSize: 14,
      color: "#191847",
    },
    headEmail: {
      fontSize: 10,
      color: "#19184799",
      fontWeight: 600,
    },
    headImgAndText: {
      display: "flex",
      padding: 20,
      borderBottom: "1px solid #e4e4e4",
      flexDirection: "column",
      alignItems: "center",
    },
    headOption: {
      "& p": {
        fontSize: 14,
        fontWeight: "bold",
        cursor: "pointer",
      },
    },
    headOptionLogout: {
      "& p": {
        fontSize: 14,
        fontWeight: "bold",
        cursor: "pointer",
      },
      borderTop: "1px solid #e4e4e4",
    },
    headOptionsAndImg: {
      "& $headOption": {
        "& svg": {
          marginRight: 10,
          fontSize: 22,
        },
        display: "flex",
        padding: "0 16px",
      },
      display: "flex",
      borderBottom: "1px solid #e4e4e4",
      padding: 16,
    },
    upgradeBtn: {
      "& svg": {
        border: "1px solid #fff",
        borderRadius: "50%",
        padding: 2,
        fontSize: "11px !important",
        color: "#fff",
      },
      "&:hover": {
        backgroundColor: "#DB2721",
      },
      borderRadius: 50,
      fontSize: 12,
      fontWeight: 500,
      color: "#fff",
      backgroundColor: "#83ACB7",
      textTransform: "capitalize",
      width: 120,
      height: 36,
    },
    headBtn: {
      width: "100%",
    },
    headOptions: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    avImagePop: {
      borderRadius: "50%",
      width: 60,
      height: 60,
      fontSize: 37,
      backgroundColor: "#f49719",
      marginBottom: 10,
    },
    popupProfileContainer: {
      textAlign: "center",
    },
    popupProfile: {
      display: "flex",
      flexDirection: "column",
    },
    headNotifIcon: {
      margin: "0 20px 0 15px",
    },
    headPersonalData: {
      margin: "0 15px 0 15px",
    },
    headActions: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 0",
    },
    logoutBtn: {
      margin: "0 !important",
      padding: "7px 34px !important",
    },
    headToolbar: {
      minHeight: 60,
    },
    dropdownTriggerContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    manageAccBtn: {
      textTransform: "none",
      borderRadius: 50,
      margin: "17px 0 0",
      fontSize: 13,
      padding: "6px 21px",
      fontWeight: 600,
    },
    policyTerms: {
      "& a": {
        color: "#5f6368",
        textDecoration: "none",
        font: "400 12px / 16px Roboto,RobotoDraft,Helvetica,Arial,sans-serif",
      },
      "& span": {
        margin: "0 8px",
      },
      padding: 14,
      textAlign: "center",
    },
    headOptionLinks: {
      borderBottom: "1px solid #e4e4e4",
      padding: "10px 0",
    },
    optionLink: {
      "&:hover": {
        backgroundColor: "#f7f8f8",
      },
      "& svg": {
        marginRight: 15,
        fontSize: 20,
      },
      "& p": {
        alignItems: "center",
        display: "flex",
        fontFamily: "Poppins",
        fontSize: 16,
        letterSpacing: 0.4,
        paddingLeft: 35,
        padding: "8px 0 8px 30px",
        cursor: "pointer",
        marginBottom: 5,
      },
    },
    logoContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      paddingLeft: 30,
    },
    headerContainer: {
      justifyContent: "flex-start",
    },
    menuContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
    },

    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      logoContainer: {
        paddingLeft: 0,
        justifyContent: "flex-start",
      },
      menuContainer: {
        justifyContent: "flex-end",
      },
      headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0 15px",
        alignItems: "center",
        height: "100%",
      },
      headToolbar: {
        display: "none",
      },
      avImage: {
        marginLeft: 0,
        marginTop: 0,
      },
      headOptions: {
        "& svg": {
          marginRight: 10,
        },
      },
      headerFont: {
        color: "inherit",
        textDecoration: "none",
      },
    },
  });

export default HeaderStyles;
