import { createStyles } from "@material-ui/core";

const MobileTransactionStyles = (theme) =>
  createStyles(
    {
      installButton: {
        color: "#fff",
        borderRadius: 50,
        padding: "5px 25px",
        fontWeight: "bold",
        textTransform: "capitalize",
      },
    },
    {
      name: "",
    }
  );

export default MobileTransactionStyles;
