import React from "react";
import { Grid, Typography, withStyles, WithStyles } from "@material-ui/core";

import PlayerStyles from "../Players.styles";

interface Props extends WithStyles<typeof PlayerStyles> {
  isMobile: boolean;
  options: string;
  setOptions: React.Dispatch<React.SetStateAction<string>>;
  className: string;
}

const Options: React.FC<Props> = ({
  classes,
  isMobile,
  options,
  setOptions,
  className,
}) => {
  return (
    <>
      <Grid container item className={className}>
        <div className={classes.optionSelect}>
          <Typography
            className={options === "Played" ? classes.opActive : ""}
            onClick={() => setOptions("Played")}
          >
            Played
          </Typography>
          <Typography
            className={options === "Averages" ? classes.opActive : ""}
            onClick={() => setOptions("Averages")}
          >
            Averages
          </Typography>
          <Typography
            className={options === "Projected" ? classes.opActive : ""}
            onClick={() => setOptions("Projected")}
          >
            Projected
          </Typography>
        </div>
      </Grid>
    </>
  );
};

export default withStyles(PlayerStyles)(Options);
