import React, { useEffect, useState } from "react";
import { Button, Typography, withStyles, WithStyles } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

import InstallPWAButtonStyles from "./InstallPWAButton.styles";

interface Props extends WithStyles<typeof InstallPWAButtonStyles> {
  type: string;
}

const InstallPWAComponent: React.FC<Props> = ({ classes, type }) => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt: any) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }

  switch (type) {
    case "button":
      return (
        <Button
          startIcon={<GetAppIcon />}
          variant="contained"
          color="primary"
          size="large"
          disableElevation
          className={classes.installButton}
          onClick={onClick}
        >
          Install
        </Button>
      );
    case "text":
      return (
        <Typography onClick={onClick}>
          <GetAppIcon /> Install
        </Typography>
      );
    default:
      return <></>;
  }
};

export default withStyles(InstallPWAButtonStyles)(InstallPWAComponent);
