import moment from "moment";
import { Avatar, Typography, withStyles, WithStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  TypeLeaguePlayer,
  TypeOverview,
  TypePlayerStat,
  TypePlayerStats,
  TypeTeamPlayer,
} from "../general/data-types/DataTypes";
import { getHealth } from "../general/utils/Utils";

import MobileTableStyles from "./MobileTable.styles";

interface Props extends WithStyles<typeof MobileTableStyles> {
  players: TypeLeaguePlayer[] | TypeTeamPlayer[];
  selectedOverview: TypeOverview;
  weeksPosFromTop: number;
  startDay?: Date | undefined;
  endDay?: Date | undefined;
  withNoSelection?: boolean;
  options?: string;
}

const MobileTable: React.FC<Props> = ({
  classes,
  players,
  selectedOverview,
  weeksPosFromTop,
  startDay,
  endDay,
  withNoSelection,
  options,
}) => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > weeksPosFromTop) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  const getSelectedDay = () => {
    if (
      moment(selectedOverview.league.currentDate).diff(
        selectedOverview.matchup.weekEnd,
        "days"
      ) > 0
    ) {
      return selectedOverview.matchup.weekEnd;
    } else {
      return selectedOverview.league.currentDate;
    }
  };
  const [selectedDay, setSelectedDay] = useState(getSelectedDay());
  const [weekDays, setWeekDays] = useState<string[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<
    TypeLeaguePlayer[] | TypeTeamPlayer[]
  >([]);

  useEffect(() => {
    let week: string[] = [];
    if (startDay) {
      const nrOfDays = moment(endDay).diff(moment(startDay), "days");
      for (let i = 0; i <= nrOfDays; i++) {
        let first = moment(startDay).add(i, "days");
        let day = first.toDate().toISOString().slice(0, 10);
        week = [...week, day];
      }
      setWeekDays(week);
    }
  }, [startDay, endDay]);

  useEffect(() => {
    if (players.length > 0 && players[0].type === "team") {
      setSelectedPlayers(
        (players as TypeTeamPlayer[]).filter(
          (item) => item.date === selectedDay
        )
      );
    } else {
      setSelectedPlayers(players);
    }
  }, [players, selectedDay]);

  const selectWeekDay = (item: string) => {
    setSelectedDay(item);
  };

  const completeStats = (statsList: TypePlayerStat[]) => {
    const newStat: TypePlayerStat = {
      category: "",
      date: "",
      percentage: false,
      value: "-",
      composite: false,
    };
    let alteredList: TypePlayerStat[] = statsList;
    while (alteredList.length < selectedOverview.league.categoryList.length) {
      alteredList = [...alteredList, newStat];
    }
    return alteredList;
  };

  const categories = (stats: TypePlayerStats) => {
    if (stats) {
      switch (options) {
        case "Forecast":
          return completeStats(stats.forecast);
        case "Projected":
          return completeStats(stats.projected);
        case "Score":
          return completeStats(stats.played);
        case "Played":
          return completeStats(stats.played);
        case "Averages":
          return completeStats(stats.averages);
        default:
          return [];
      }
    } else {
      return [];
    }
  };

  const showCategories = (player: TypeLeaguePlayer | TypeTeamPlayer) => {
    if (withNoSelection) {
      return categories(player.stats).filter(
        (item) => item.date === selectedDay || item.date === null
      );
    } else {
      return categories(player.stats);
    }
  };

  return (
    <div style={{ width: "100vw" }}>
      <div className={scrolled ? classes.sticky : ""}>
        <div className={`${classes.weekContainer}`}>
          {weekDays.map((item, index) => (
            <div
              className={`${selectedDay === item ? classes.selectedDay : ""} ${
                classes.weekDay
              }`}
              key={`thr-${index}`}
              onClick={() => selectWeekDay(item)}
            >
              {moment(item).format("ddd MMM DD").replace(" ", "\n")}
            </div>
          ))}
        </div>
        <div className={`${classes.mobileHeader}`}>
          {selectedOverview.league.categoryList.map((item, index) => (
            <div key={index} className={classes.categoryItem}>
              <Typography>{item}</Typography>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.body} style={{ marginTop: scrolled ? 56 : 0 }}>
        <div>
          {(selectedPlayers as Array<TypeLeaguePlayer | TypeTeamPlayer>).map(
            (player, index) => (
              <div key={index} className={classes.tableRow}>
                <div style={{ display: "flex", margin: "5px 0" }}>
                  <div className={classes.rank}>
                    <Typography>
                      {player.type === "league" ? player.rank : player.position}
                    </Typography>
                  </div>
                  <Avatar
                    alt={player.name}
                    src={player.headShotUrl}
                    className={classes.avImage}
                  />
                  <div className={classes.summaryContainer}>
                    <div className={classes.playerName}>
                      <Typography>{player.name}</Typography>
                    </div>
                    <div className={classes.playerdetail}>
                      <span>
                        <Typography>{player.details}</Typography>
                      </span>
                      {getHealth(player, true)}
                    </div>
                  </div>
                </div>
                <div className={classes.tableRowStats}>
                  {showCategories(player).length > 0
                    ? showCategories(player).map((item, ix) => (
                        <div key={`str-${ix}`} className={classes.categoryVal}>
                          <Typography className={classes.tdText}>
                            {item.value}
                            {item.percentage && item.value !== "-" ? "%" : ""}
                          </Typography>
                        </div>
                      ))
                    : selectedOverview.league.categoryList.map((_, i) => (
                        <div key={`stre-${i}`} className={classes.categoryVal}>
                          {"-"}
                        </div>
                      ))}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(MobileTableStyles)(MobileTable);
