import { Dispatch } from "redux";
import * as types from "../action-types/actionTypes";
import {
  fetchAllPlayers,
  fetchLeaguePlayerNames,
} from "../../../components/api/general/api";
import { PlayerAction } from "./playerActionsInterface";
import {
  noResponse,
  TypeLeaguePlayers,
} from "../../../components/general/data-types/DataTypes";
import { beginApiCall } from "../apiStatusAction/apiStatusAction";

export function loadPlayersSuccess(
  data: TypeLeaguePlayers,
  leagueId: string
): PlayerAction {
  return { type: types.FETCH_ALL_PLAYERS_SUCCESS, data, leagueId };
}

export function loadPlayersFailed(data: any, leagueId: string): PlayerAction {
  return { type: types.FETCH_ALL_PLAYERS_FAILED, data, leagueId };
}

export function loadPlayersListSuccess(data: any[]): PlayerAction {
  return { type: types.FETCH_ALL_PLAYER_NAMES_SUCCESS, data };
}

export function loadPlayers(
  pageNumber: number,
  leagueId: string,
  filters?: any
) {
  return function (dispatch: Dispatch) {
    dispatch(beginApiCall("BEGIN_API_CALL_PLAYERS"));
    return fetchAllPlayers(pageNumber, leagueId, filters)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          if (data.content.length === 0) {
            dispatch(loadPlayersFailed(noResponse, leagueId));
          } else {
            dispatch(loadPlayersSuccess(data, leagueId));
          }
        }
      })
      .catch(() => {
        dispatch(loadPlayersFailed(noResponse, leagueId));
      });
  };
}

export function loadPlayerNames(id: string) {
  return function (dispatch: Dispatch) {
    return fetchLeaguePlayerNames(id)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          dispatch(loadPlayersListSuccess(data));
        }
      });
  };
}
