import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { TypeMatchup } from "../../../../../components/general/data-types/DataTypes";
import ThreeLineSkeleton from "../../skeletons/ThreeLineSkeleton/ThreeLineSkeleton";
import LinearProgressBar from "../../../../../components/general/linear-progress-bar/LinearProgressBar";

import styles from "./GamesPlayedBox.module.scss";

interface IGamesPlayedBoxProps {
  overviewMatchup: TypeMatchup;
}

const GamesPlayedBox: React.FunctionComponent<IGamesPlayedBoxProps> = ({
  overviewMatchup,
}) => {
  if (overviewMatchup == null) {
    return (
      <Grid item className={styles.stSecondWeeks} xs={8}>
        <Grid
          style={{
            padding: 16,
            height: "100%",
            boxSizing: "border-box",
          }}
          className={styles.shadowContainer}
        >
          <ThreeLineSkeleton />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item className={styles.stSecondWeeks} xs={8}>
      <Grid
        style={{
          padding: 16,
          height: "100%",
          boxSizing: "border-box",
        }}
        className={styles.shadowContainer}
      >
        <Typography style={{ fontSize: 18, marginBottom: 14 }}>
          Games Played
        </Typography>
        <div style={{ display: "flex" }}>
          <LinearProgressBar
            progress={
              (overviewMatchup.homeTeamCompletedGames /
                overviewMatchup.homeTeamTotalGames) *
              100
            }
            bgColor="#7388A95A"
            color="#7EBFBF"
            height={8}
          />
          <Typography className={styles.gpPercent}>
            {Math.trunc(
              (overviewMatchup.homeTeamCompletedGames /
                overviewMatchup.homeTeamTotalGames) *
                100
            )}
            %
          </Typography>
        </div>
        <Typography style={{ marginTop: 4 }} className={styles.cwStats}>
          {overviewMatchup.homeTeamCompletedGames} of{" "}
          {overviewMatchup.homeTeamCompletedGames +
            overviewMatchup.homeTeamRemainingGames}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GamesPlayedBox;
