import { Dispatch } from "redux";
import { fetchNBASchedule } from "../../../components/api/general/api";
import { GamesAction } from "./gameActionsInterface";
import * as types from "../action-types/actionTypes";
import { GameSchedule } from "../../../components/general/data-types/DataTypes";

export function fetchNBAScheduleSuccess(data: GameSchedule[]): GamesAction {
  return { type: types.FETCH_NBA_SCHEDULE, data };
}

export function loadNBASchedule() {
  return function (dispatch: Dispatch) {
    return fetchNBASchedule()
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          dispatch(fetchNBAScheduleSuccess(data));
        }
      })
      .catch((error) => {
        console.log("fetch NBA schedjule error: ", error);
      });
  };
}
