import { createStyles } from "@material-ui/core";

const TransactionStyles = (theme) =>
  createStyles({
    child: {
      textAlign: "center",
      width: "100%",
      marginRight: 70,
    },
    transacTitle: {
      textAlign: "center",
      fontSize: 24,
      margin: "20px 0 50px",
      fontWeight: "bold",
    },
    container: {
      "& .active": {
        cursor: "grabbing",
      },
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "#fff",
      boxShadow: "0px 1px 3px #00000029",
      marginBottom: 70,
      position: "relative",
      overflow: "hidden",

      "&::after": {
        content: "''",
        width: "100%",
        position: "absolute",
        background: "#558B95",
        opacity: 0.5,
        height: 3,
        top: 80,
        right: 0,
      },
    },
    item: {
      "&:first-child": {
        marginRight: "0px !important",
        paddingRight: 40,
        boxSizing: "border-box",
      },
      "&:last-child": {
        marginLeft: "0px !important",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  });

export default TransactionStyles;
