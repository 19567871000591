import { Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  TypeTeam,
  TypeTeamPlayer,
  TypeWeekAdds,
} from "../../components/general/data-types/DataTypes";
import { RootState } from "../../redux/reducers";
import { loadWeeklyAdditions } from "../../redux/actions/matchupsActions/matchupsActions";
import {
  loadTeamRoster,
  changeSelectedTeam,
} from "../../redux/actions/teamActions/teamActions";
import Roster from "./roster/Roster";
import { isEmpty } from "lodash";
import { useParams, useLocation } from "react-router-dom";
import {
  fetchTeam,
  fetchTeamRooster,
  fetchWeeklyAdditions,
} from "../../components/api/general/api";
import { useSelector, useDispatch } from "react-redux";

import TeamStyles from "./Team.styles";

interface Props extends WithStyles<typeof TeamStyles> {}

const Team: React.FC<Props> = ({ classes }) => {
  const [subPage, setSubPage] = useState("roster");
  const { id } = useParams<{ id: string }>();
  const [team, setTeam] = useState<TypeTeam>();
  const location = useLocation();
  const [teamRoster, setTeamRoster] = useState<TypeTeamPlayer[]>([]);
  const [teamWeekAdds, setTeamWeekAdds] =
    useState<TypeWeekAdds | undefined>(undefined);
  const [otherTeamLoading, setOtherTeamLoading] = useState(true);
  const partial = location.pathname.substring(1);
  const url = partial.substring(0, partial.indexOf("/"));
  const isMyTeam = url === "my-team" ? true : false;
  const currentLeageIdFromLS = localStorage.getItem("selectedOverview") || "";

  const {
    overviews: { selectedOverview, loading: overviewLoading },
    matchups: { weekAdds },
    team: { roster, loading: teamLoading },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedOverview && isEmpty(weekAdds) && isMyTeam) {
      dispatch(loadWeeklyAdditions(currentLeageIdFromLS));
    } else if (selectedOverview && !isMyTeam) {
      fetchWeeklyAdditions(currentLeageIdFromLS)
        .then((res) => {
          const statusCode = res.status;
          const data = res.json();
          return Promise.all([statusCode, data]);
        })
        .then(([statusCode, data]) => {
          if (statusCode === 200) {
            setTeamWeekAdds(data);
          }
        });
    }
  }, [dispatch, selectedOverview, weekAdds, isMyTeam, currentLeageIdFromLS]);

  useEffect(() => {
    if (id && !roster.length && isMyTeam) {
      dispatch(loadTeamRoster(id));
    } else if (id && !isMyTeam) {
      fetchTeamRooster(id)
        .then((res) => {
          const statusCode = res.status;
          const data = res.json();
          return Promise.all([statusCode, data]);
        })
        .then(([statusCode, data]) => {
          if (statusCode === 200) {
            setTeamRoster(data);
          }
          setOtherTeamLoading(false);
        });
    }
  }, [dispatch, id, roster, isMyTeam]);

  useEffect(() => {
    if (id)
      fetchTeam(id)
        .then((res) => res.json())
        .then((val) => {
          dispatch(changeSelectedTeam(val));
          setTeam(val);
        });
  }, [id, dispatch]);

  return (
    <Grid className={classes.content}>
      <Grid container className={classes.topMenuContainer}>
        {selectedOverview && (
          <Grid item className={classes.headLogoText}>
            <img
              className={classes.topLogo}
              src={selectedOverview.league.logoUrl}
              alt="Team logo"
            />
            <div>
              <Typography className={classes.topTitle}>
                {selectedOverview.league.name}
              </Typography>
              <Typography className={classes.topSummary}>
                {selectedOverview.league.summary}
              </Typography>
            </div>
          </Grid>
        )}
        <Grid className={classes.headMenu}>
          <div className={classes.hMenuContainer}>
            <div
              className={subPage === "roster" ? classes.activeMenu : ""}
              onClick={() => setSubPage("roster")}
            >
              <Typography className={classes.hMenuTitle}>Roster</Typography>
              <Typography className={classes.hMenuSTitle}>
                Team players and Stats
              </Typography>
            </div>
            {/* <div
              className={subPage === "research" ? classes.activeMenu : ""}
              onClick={() => setSubPage("research")}
            >
              <Typography className={classes.hMenuTitle}>Research</Typography>
              <Typography className={classes.hMenuSTitle}>
                Check witch players fit your team
              </Typography>
            </div> */}
          </div>
        </Grid>
      </Grid>
      {selectedOverview &&
        team &&
        (subPage === "roster" ? (
          <Roster
            selectedOverview={selectedOverview}
            weekAdds={isMyTeam ? weekAdds : teamWeekAdds}
            roster={isMyTeam ? roster : teamRoster}
            team={team}
            overviewLoading={overviewLoading}
            teamLoading={isMyTeam ? teamLoading : otherTeamLoading}
          />
        ) : (
          ""
        ))}
    </Grid>
  );
};

export default withStyles(TeamStyles)(Team);
