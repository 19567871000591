import { Grid } from "@material-ui/core";
import React from "react";
import ThreeLineSkeleton from "../../skeletons/ThreeLineSkeleton/ThreeLineSkeleton";
import ReactApexChart from "react-apexcharts";

import styles from "./ChartBox.module.scss";

interface IChartBoxProps {
  chartOptions: any;
}

const ChartBox: React.FunctionComponent<IChartBoxProps> = ({
  chartOptions,
}) => {
  const getChartSeries = () => {
    const isNotNum = chartOptions.series.find((item: any) =>
      Number.isNaN(item)
    );
    if (isNotNum === undefined) {
      return chartOptions.series;
    }
  };

  if (getChartSeries() == null) {
    return (
      <Grid item className={styles.stFirstChart} xs={5}>
        <ThreeLineSkeleton />
      </Grid>
    );
  }

  return (
    <Grid item className={styles.stFirstChart} xs={5}>
      <Grid
        container
        className={styles.shadowContainer}
        style={{ height: "100%" }}
      >
        <Grid
          className={styles.chartContainer}
          style={{ paddingBottom: 0, paddingTop: 9 }}
        >
          <ReactApexChart
            options={chartOptions.options}
            series={chartOptions.series}
            type="donut"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChartBox;
