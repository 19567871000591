import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  WithStyles,
  TextField,
  withStyles,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import locale from "date-fns/locale/en-US";
import DateFnsUtils from "@date-io/date-fns";
import {
  TypeFilterPosition,
  TypeFilters,
  TypeFrachise,
  TypeLeaguePlayers,
  TypeOverview,
} from "../../general/data-types/DataTypes";
import { health, status } from "../../general/utils/Utils";

import PlayerStyles from "../../../containers/players/Players.styles";

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

interface Props extends WithStyles<typeof PlayerStyles> {
  selectedOverview: TypeOverview | undefined;
  players: TypeLeaguePlayers;
  getPlayers: (
    pageNmber: number,
    filters: any,
    withFilter?: boolean | undefined
  ) => void;
  teams: any[];
  filters: TypeFilters;
  setFilters: React.Dispatch<React.SetStateAction<TypeFilters>>;
  onFilterChange: (filter: string, value: any) => void | "";
  franchises: TypeFrachise[];
  positions: TypeFilterPosition[];
}

const FiltersPage: React.FC<Props> = ({
  classes,
  selectedOverview,
  players,
  getPlayers,
  teams,
  filters,
  setFilters,
  onFilterChange,
  franchises,
  positions,
}) => {
  const resetDateFilter = () => {
    setFilters({ ...filters, date: null });
    const newFilters = {
      ...filters,
      date: null,
    };
    getPlayers(0, newFilters, true);
  };

  return (
    <Grid container item className={classes.filterContainer}>
      <Grid className={classes.filterItem}>
        <Autocomplete
          id="combo-box-demo"
          options={players.playerNames}
          freeSolo
          getOptionLabel={(option: any) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Player Name" />
          )}
          className={classes.filterMargin}
          onChange={(_, newValue) => {
            onFilterChange("name", newValue);
          }}
        />

        <FormControl className={classes.filtersInput}>
          <InputLabel id="status-select-label" color="secondary">
            Status
          </InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            value={filters.status}
            color="secondary"
            onChange={(e) => onFilterChange("status", e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {status.map((item, i) => {
              return (
                <MenuItem key={i} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid className={classes.filterItem} style={{ margin: "0 32px 0 64px" }}>
        <FormControl
          className={`${classes.filtersInput} ${classes.filterMargin}`}
        >
          <InputLabel id="team-select-label" color="secondary">
            Fantasy Team
          </InputLabel>
          <Select
            labelId="team-select-label"
            id="team-select"
            value={filters.fantasyTeam}
            color="secondary"
            onChange={(e) => onFilterChange("fantasyTeam", e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {teams.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.filtersInput}>
          <InputLabel id="health-select-label" color="secondary">
            Health
          </InputLabel>
          <Select
            labelId="health-select-label"
            id="health-select"
            value={filters.health}
            color="secondary"
            onChange={(e) => onFilterChange("health", e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {health.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid className={classes.filterItem} style={{ margin: "0 64px 0 32px" }}>
        <FormControl
          className={`${classes.filtersInput} ${classes.filterMargin}`}
        >
          <InputLabel id="franchise-select-label" color="secondary">
            Franchises
          </InputLabel>
          <Select
            labelId="franchise-select-label"
            id="franchise-select"
            value={filters.franchise}
            color="secondary"
            onChange={(e) => onFilterChange("franchise", e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {franchises.map((item, i) => (
              <MenuItem key={i} value={item.tricode}>
                {item.fullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.filtersInput}>
          <InputLabel id="position-select-label" color="secondary">
            Position
          </InputLabel>
          <Select
            labelId="position-select-label"
            id="position-select"
            value={filters.position}
            color="secondary"
            onChange={(e) => onFilterChange("position", e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {positions.map((item, i) => (
              <MenuItem key={i} value={item.gamePosition.abbreviation}>
                {item.gamePosition.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid className={classes.filterItem}>
        <FormControl
          className={classes.filtersInput}
          style={{ marginBottom: 4 }}
        >
          <InputLabel id="games-select-label" color="secondary">
            Games Remaining
          </InputLabel>
          <Select
            labelId="games-select-label"
            id="games-select"
            color="secondary"
            value={filters.gamesRemaining}
            onChange={(e) => onFilterChange("gamesRemaining", e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
        <div className={classes.datePickerContainer}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <KeyboardDatePicker
              value={filters.date}
              onChange={(e) => onFilterChange("date", e)}
              color="secondary"
              maxDate={selectedOverview && selectedOverview.matchup.weekEnd}
              minDate={selectedOverview && selectedOverview.matchup.weekStart}
              autoOk={true}
              disableToolbar
              disablePast
              variant="inline"
              format="EEE MMM dd"
              margin="normal"
              id="date-picker-inline"
              label="Playing on"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              autoComplete="off"
            />
          </MuiPickersUtilsProvider>
          {filters.date && (
            <CloseIcon
              className={classes.dateCloseIcon}
              onClick={resetDateFilter}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(PlayerStyles)(FiltersPage);
