import React from "react";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { Grid, Typography } from "@material-ui/core";
import UserLeaguesSchedule from "../../tables/user-leagues-schedule/UserLeaguesSchedule";
import { TypeTeamSchedule } from "../../../../components/general/data-types/DataTypes";
import TableSkeleton from "../table-skeleton/TableSkeleton";

import styles from "./Schedule.module.scss";

interface Props {
  leagues: TypeTeamSchedule[];
  elemHeight: number;
}

const Schedule: React.FC<Props> = ({ leagues, elemHeight }) => {
  const renderTable = () => {
    if (leagues.length > 0) {
      return <UserLeaguesSchedule leagues={leagues} elemHeight={elemHeight} />;
    }
    return <TableSkeleton />;
  };

  return (
    <Grid item className={styles.topStatThird}>
      <Grid container className={styles.mainComp}>
        <Grid container className={styles.header}>
          <Typography>Schedule</Typography>
          <EventNoteIcon style={{ color: "#DB2721" }} />
        </Grid>
        <Grid container className={styles.body} style={{ height: elemHeight }}>
          {renderTable()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Schedule;
