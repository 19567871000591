import React from "react";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Button, Typography, withStyles, WithStyles } from "@material-ui/core";
import { useAuth } from "./AuthContext";

import LoginStyles from "./Login.styles";

interface Props extends WithStyles<typeof LoginStyles> {}

const Logout: React.FC<Props> = ({ classes }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const onSignOut = () => {
    logout();
    Cookie.remove("tokenId", { path: "/" });
    navigate("/");
  };

  return (
    <Button
      variant="outlined"
      color="secondary"
      size="large"
      disableElevation
      className={classes.logoutBtn}
      onClick={onSignOut}
    >
      <Typography style={{ fontSize: 12, fontWeight: 600 }}>
        Sign Out
      </Typography>
    </Button>
  );
};

export default withStyles(LoginStyles)(Logout);
