import { createStyles } from "@material-ui/core";
import adminTheme from "../../themes/admin/adminTheme";

const PlayerStyles = (theme) =>
  createStyles({
    optionSelect: {
      "& p": {
        padding: "6px 16px",
        fontSize: 12,
        cursor: "pointer",
      },
      marginBottom: 16,
      display: "flex",
      boxShadow: "0px 1px 3px #00000029",
      borderRadius: 12,
      overflow: "hidden",
    },
    infinitContainer: {
      overflow: "hidden !important",
    },
    playersTableContainer: {
      "& .infinite-scroll-component__outerdiv": {
        width: "100%",
      },
      marginTop: 50,
      overflow: "auto",
      height: 605,
      maxWidth: 1457,
      margin: "0 auto",
    },
    opActive: {
      backgroundColor: "#FF9718",
      color: "#fff",
    },
    filterContainer: {
      maxWidth: 1082,
      margin: "0 auto",
    },
    filtersInput: {
      width: "100%",
    },
    filterItem: {
      "& .MuiInput-root": {
        "& svg": {
          color: "#558b95",
        },
      },
      width: 222,
    },
    filterMargin: {
      marginBottom: 20,
    },
    datePickerContainer: {
      position: "relative",
    },
    dateCloseIcon: {
      "&:hover": {
        background: "#efefef",
      },
      position: "absolute",
      top: 35,
      right: 45,
      fontSize: 20,
      color: "#558b95",
      cursor: "pointer",
      borderRadius: "50%",
      padding: 3,
    },
    loadMoreBtn: {
      borderRadius: 30,
      textTransform: "capitalize",
      margin: "30px auto 50px",
      height: 42,
    },
    filterPageContainer: {
      position: "relative",
      paddingTop: 50,
      height: "calc(100% - 127px)",
      paddingBottom: 76,
    },
    active: {},
    filterHeader: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
    },
    applyButtonContainer: {
      padding: "45px 0 0",
      display: "flex",
      width: "100%",
      background: "#fff",
    },
    applyButton: {
      color: "#49495C",
      fontWeight: "bold",
      width: "92%",
      margin: "0 auto",
    },
    selectableButtons: {
      "&.active": {
        background: "#FF9718",
        color: "#fff",
      },
      "&:last-child": {
        marginRight: 0,
      },
      "& p": {
        fontSize: 12,
      },
      background: "#F5F6F7",
      color: "#7C828A",
      borderRadius: 5,
      marginRight: 8,
      padding: 6,
      flexGrow: 1,
      textAlign: "center",
    },
    filterItemBox: {
      borderBottom: "2px solid #ededed",
      padding: "0 10px",
      overflow: "hidden",
    },
    filterTopT: {
      fontSize: 18,
      fontWeight: 600,
      textAlign: "center",
      color: "#49495C",
      padding: "15px 0 5px",
      borderBottom: "2px solid #ededed",
    },
    filterTitle: {
      fontSize: 18,
      fontWeight: "bold",
      color: "#49495C",
      padding: "10px 0",
    },
    filterOpenerTitle: {
      fontSize: 18,
      fontWeight: "bold",
      color: "#49495C",
    },
    sButonsContainer: {
      display: "flex",
      marginBottom: 16,
    },
    sliderNumbers: {
      "& span": {
        fontSize: 10,
        color: "#7C828A",
        fontFamily: "Montserrat, Roboto, sans-serif",
      },
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    slideComp: { paddingTop: 5, paddingBottom: 10 },
    weekDay: {
      background: "#F5F6F7",
      minWidth: 45,
      height: 30,
      fontSize: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      marginRight: 4,
      flexGrow: 1,
      borderRadius: 4,
      fontFamily: "Montserrat, Roboto, sans-serif",
      whiteSpace: "break-spaces",
    },
    selectedDay: {
      background: "#FF9718",
      color: "#fff",
    },
    sideMenuContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#fff",
      zIndex: 9,
      padding: 20,
    },
    darkOverlay: {
      background: "rgba(0, 0, 0, 0.5)",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 9,
    },
    sideFilterTitle: {
      textTransform: "capitalize",
      fontSize: 25,
      fontFamily: "Roboto",
      marginTop: 35,
    },
    sideFilterCloseIcon: {
      position: "absolute",
      top: 10,
      left: 10,
      fontSize: 35,
    },
    filterPageOpener: {
      display: "flex",
      justifyContent: "space-between",
      padding: "15px 0",
      alignItems: "center",
    },
    filterOpenerArrow: {
      color: "#49495C",
      fontSize: 25,
    },
    filtersOptionsContainer: {
      marginTop: 15,
      height: "78%",
      overflow: "auto",
      width: "71%",
      flexWrap: "unset",
    },
    filterArrowClose: {
      position: "absolute",
      top: -13,
      left: "calc(50% - 21px)",
      fontSize: 40,
      color: "#49495C",
    },
    options: {
      justifyContent: "center",
      paddingTop: 33,
    },

    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      playersTableContainer: {
        height: "unset",
        marginTop: 10,
        order: 3,
      },
      options: {
        order: 2,
        paddingTop: 15,
      },
    },
  });

export default PlayerStyles;
