import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";

import DonateStyles from "./Donate.styles";
import {
  fetchStripDonatePrices,
  retrieveStripeDonateSessionUrl,
} from "../../components/api/general/api";
import { ReactComponent as DonatePiggyBank } from "../../static/images/Savings-bro.svg";
import { mapToArray } from "../../components/general/utils/Utils";
import LoadingCircle from "../../components/general/LoadingCircle/LoadingCircle";

interface IDonateProps extends WithStyles<typeof DonateStyles> {}

const Donate: React.FC<IDonateProps> = ({ classes }) => {
  const [selectedAmmount, setSelectedAmmount] = useState<any>();
  const [pricesList, setPricesList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchStripDonatePrices()
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          const objectsList = mapToArray(data, "value", "price");
          setPricesList(objectsList);
          setSelectedAmmount(objectsList[0].price);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const onSubmitDonate = () => {
    retrieveStripeDonateSessionUrl(selectedAmmount)
      .then((res) => res.text())
      .then((val) => (window.location.href = val));
  };

  return (
    <Grid container className={classes.container}>
      <Grid item sm={6} xs={12}>
        <Grid container className={classes.donateTopContainer}>
          <div style={{ minHeight: 180 }}>
            <Typography className={classes.profileTtle}>Support us</Typography>
            <Typography className={classes.profileText}>
              If you like what we do, you can support and help us grow to
              improve the product.
            </Typography>
          </div>
          <DonatePiggyBank className={classes.donateIllust} />
        </Grid>
        <div className={classes.itemBox}>
          <Typography className={classes.lineTitle}>Select sum</Typography>
          {loading ? (
            <div style={{ width: "100%", height: 100 }}>
              <LoadingCircle />
            </div>
          ) : (
            <div className={classes.priceBoxContainer}>
              {pricesList?.map((item, index) => (
                <div
                  key={index}
                  className={`${classes.priceBox} ${
                    selectedAmmount === item.price ? "active" : ""
                  }`}
                  onClick={() => setSelectedAmmount(item.price)}
                >
                  <Typography variant="h3">${item.value}</Typography>
                </div>
              ))}
            </div>
          )}

          <Button
            variant="contained"
            color="primary"
            type="button"
            className={classes.donateBtn}
            onClick={onSubmitDonate}
            disabled={pricesList.length === 0}
          >
            Donate
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(DonateStyles)(Donate);
