import React from "react";
import { WithStyles, withStyles } from "@material-ui/core";

import ForecastStyles from "./Forecast.styles";

interface Props extends WithStyles<typeof ForecastStyles> {
  future: boolean;
  homeAdds: number;
  visitingAdds: number;
  homeGames: number;
  visitingGames: number;
}

const WeeklyMovesComponent: React.FC<Props> = ({
  classes,
  future,
  homeAdds,
  visitingAdds,
  homeGames,
  visitingGames,
}) => {
  return (
    <>
      <div>
        <div className={classes.numberBox}>{homeAdds}</div>
        <div className={classes.midTextBox}>
          Moves <br /> {future ? "remaining" : "made"}
        </div>
        <div className={classes.numberBox}>{visitingAdds}</div>
      </div>
      <div>
        <div className={classes.numberBox}>{homeGames}</div>
        <div className={classes.midTextBox}>
          Games <br /> {future ? "remaining" : "played"}
        </div>
        <div className={classes.numberBox}>{visitingGames}</div>
      </div>
    </>
  );
};

export default withStyles(ForecastStyles)(WeeklyMovesComponent);
