import { createStyles } from "@material-ui/core";
import adminTheme from "../../../themes/admin/adminTheme";

const ScheduleStyles = (theme) =>
  createStyles({
    mainComp: {
      width: 1620,
      margin: "0 auto",
    },
    header: {
      "& p": {
        color: "#49495C",
        fontSize: 16,
        fontWeight: 700,
      },
      borderBottom: "1px solid #e9e8e9",
      display: "flex",
      justifyContent: "space-between",
      padding: 16,
    },
    body: {
      "& .MuiTableCell-root": {
        padding: "0px 5px 0px 5px",
      },
      paddingBottom: 30,
    },
    tableHead: {
      "& th": {
        fontSize: 12,
        color: "#49495C",
        padding: "6px 15px 6px 16px",
      },
    },
    number: {
      float: "left",
    },
    summary: {
      float: "left",
      fontSize: 10,
      marginTop: 7,
      marginLeft: 4,
    },
    headNumbers: {
      fontSize: "16px !important",
      fontWeight: 600,
      padding: "7px 0 !important",
      color: "#000 !important",
      cursor: "pointer",
    },
    emptyCell: {
      "& p": {
        fontSize: 30,
        textAlign: "center",
        color: "#00000080",
      },
      minWidth: 47,
    },
    activeHead: {
      borderTop: "2px solid #f49719 !important",
      borderLeft: "2px solid #f49719 !important",
      borderRight: "2px solid #f49719 !important",
    },
    activeCell: {
      borderLeft: "2px solid #f49719 !important",
      borderRight: "2px solid #f49719 !important",
    },
    selectTeam: {},
    tableSearch: {
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
      width: 250,
      padding: "0 !important",
    },
    tableImg: {
      width: 24,
      height: 24,
      float: "left",
      marginRight: 5,
    },
    tableImgTxt: {
      fontSize: 18,
      lineHeight: "26px",
    },
    numberFont: {
      fontSize: 30,
    },
    sfText: {
      marginBottom: 3,
    },

    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      sticky: {
        left: 0,
        position: "sticky",
        background: "#fbfbfb",
        minWidth: "205px !important",
        zIndex: 9,
      },
      tableImg: {
        width: 20,
        height: 20,
      },
      tableImgTxt: {
        fontSize: 14,
      },
      numberFont: {
        fontSize: 24,
      },
      body: {
        "& .MuiTableCell-root": {
          padding: "0px 7px 0px 7px",
          minWidth: 40,
        },
      },
      summary: {
        marginTop: 3,
      },
      sfText: {
        marginBottom: 0,
      },
    },
  });

export default ScheduleStyles;
