import { Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import {
  TypeOverview,
  TypeWeekAdds,
  TypeTeamPlayer,
  TypeTeam,
} from "../../../components/general/data-types/DataTypes";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import PeopleIcon from "@material-ui/icons/People";
import HealingIcon from "@material-ui/icons/Healing";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import PlayersTable from "../../../components/players-table/PlayersTable";
import LoadingCircle from "../../../components/general/LoadingCircle/LoadingCircle";
import ValueCheckContainer from "../../../components/value-check-container/ValueCheckContainer";
import StatOptionsBar from "../../../components/stat-options-bar/StatOptionsBar";

import RosterStyles from "./Roster.styles";

interface Props extends WithStyles<typeof RosterStyles> {
  selectedOverview: TypeOverview;
  weekAdds: TypeWeekAdds | undefined;
  roster: TypeTeamPlayer[];
  team: TypeTeam;
  overviewLoading: boolean;
  teamLoading: boolean;
}

const Roster: React.FC<Props> = ({
  classes,
  selectedOverview,
  weekAdds,
  roster,
  team,
  overviewLoading,
  teamLoading,
}) => {
  const [options, setOptions] = useState("Played");
  return (
    <Grid container>
      <Grid container className={classes.rosterContainer}>
        <Grid className={`${classes.addsThisWeek} ${classes.shadowContainer}`}>
          <ValueCheckContainer value={weekAdds}>
            <Typography className={classes.amaStatsTitle}>
              Adds this Week
            </Typography>
            {weekAdds && (
              <Grid container>
                <Grid item xs={4} className={classes.amaStatsTopContainer}>
                  <Typography className={classes.amaStatsTitleTopB}>
                    {weekAdds.allowed}
                  </Typography>
                  <Typography className={classes.amaStatsbot}>
                    Allowed
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.amaStatsTopContainer}>
                  <Typography className={classes.amaStatsTitleTopG}>
                    {weekAdds.made}
                  </Typography>
                  <Typography className={classes.amaStatsbot}>Made</Typography>
                </Grid>
                <Grid item xs={4} className={classes.amaStatsTopContainer}>
                  <Typography className={classes.amaStatsTitleTopY}>
                    {weekAdds.available}
                  </Typography>
                  <Typography className={classes.amaStatsbot}>
                    Available
                  </Typography>
                </Grid>
              </Grid>
            )}
          </ValueCheckContainer>
        </Grid>
        <Grid className={`${classes.gamesPlayed} ${classes.shadowContainer}`}>
          <ValueCheckContainer value={selectedOverview.matchup}>
            <Typography>Games Played</Typography>
            <div className={classes.gpContainer}>
              <Typography className={classes.gpSummary}>
                {selectedOverview.matchup.homeTeamCompletedGames} of{" "}
                {selectedOverview.matchup.homeTeamTotalGames}
              </Typography>
            </div>
          </ValueCheckContainer>
        </Grid>
        <Grid className={`${classes.teamData} ${classes.shadowContainer}`}>
          <ValueCheckContainer value={team}>
            <img src={team.logo} alt="Team logo" />
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Typography className={classes.tdTitle}>{team.name}</Typography>
                <Typography>{team.summary}</Typography>
              </div>
            </div>
          </ValueCheckContainer>
        </Grid>
        <Grid
          className={`${classes.healthContainer} ${classes.shadowContainer}`}
        >
          <ValueCheckContainer value={team}>
            <div className={classes.hcTitle}>
              <LocalHospitalIcon className={classes.hoverIcon} />
              <Typography>Players Health</Typography>
            </div>
            <div className={classes.teamBodyContainer}>
              <div>
                <HealingIcon className={classes.iconHeal} />
                <Typography>{team.injuredPlayers}</Typography>
              </div>
              <div>
                <HelpOutlineIcon className={classes.iconQuestion} />
                <Typography>{team.questionablePlayers}</Typography>
              </div>
              <div>
                <CheckCircleIcon className={classes.iconCheck} />
                <Typography>{team.healthyPlayers}</Typography>
              </div>
            </div>
          </ValueCheckContainer>
        </Grid>
        <Grid className={`${classes.playingToday} ${classes.shadowContainer}`}>
          <ValueCheckContainer value={team.playingToday}>
            <div className={classes.playingTodayTitle}>
              <DirectionsRunIcon className={classes.hoverIcon} />
              <Typography>Playing Today</Typography>
            </div>
            <div className={classes.playingTodayBody}>
              <PeopleIcon className={classes.iconPeople} />
              <Typography>{team.playingToday}</Typography>
            </div>
          </ValueCheckContainer>
        </Grid>
      </Grid>
      <StatOptionsBar options={options} setOptions={setOptions} />
      <Grid container className={classes.playersTable}>
        {!teamLoading && !overviewLoading ? (
          selectedOverview.matchup ? (
            <PlayersTable
              players={roster}
              selectedOverview={selectedOverview}
              tableWidth={840}
              options={options}
              startDay={new Date(selectedOverview.matchup.weekStart)}
              endDay={new Date(selectedOverview.matchup.weekEnd)}
              swappable
            />
          ) : (
            <Typography>Can't display data at the moment</Typography>
          )
        ) : (
          <LoadingCircle />
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(RosterStyles)(Roster);
