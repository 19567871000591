import { createStyles } from "@material-ui/core";
import adminTheme from "../../../../themes/admin/adminTheme";

const UserStandingsTableStyles = (theme) =>
  createStyles({
    tableLogo: {
      "& img": {
        maxWidth: 35,
        float: "left",
        width: 28,
        height: 28,
        borderRadius: "50%",
        margin: "3px 10px 0px",
      },
      "& span": {
        float: "left",
        fontWeight: 700,
        marginTop: 6,
      },
      "& p": {
        float: "left",
        fontWeight: 700,
        marginTop: 6,
        marginBottom: 0,
      },
    },
    tableHead: {
      "& th": {
        fontSize: 12,
        color: "#49495C",
        padding: "6px 15px 6px 16px",
      },
    },

    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      tableLogo: {
        "& img": {
          width: 24,
          height: 24,
        },
        "& p": {
          fontSize: 12,
        },
      },
      xsTablePadding: {
        "& th": {
          padding: "3px 5px 3px 5px",
          fontSize: 12,
        },
        "& td": {
          padding: "3px 5px 3px 5px",
          fontSize: 12,
        },
      },
    },
  });

export default UserStandingsTableStyles;
