import { TeamScheduleActions } from "../actions/teamScheduleActions/teamScheduleActionInterface";
import * as types from "../actions/action-types/actionTypes";
import { TypeTeamSchedule } from "../../components/general/data-types/DataTypes";

export default function teamScheduleReducer(
  state = [] as TypeTeamSchedule[],
  action: TeamScheduleActions
) {
  switch (action.type) {
    case types.FETCH_TEAM_SCHEDULE_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
