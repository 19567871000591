import { createStyles } from "@material-ui/core";

const CarouselStyles = (theme) =>
  createStyles({
    carousel: {
      "& .active": {
        cursor: "grabbing",
      },
      height: 300,
      direction: "rtl",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      overflow: "hidden",
      zIndex: 1,
      cursor: "grab",
      userSelect: "none",
      position: "relative",
    },
    cWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    sliderBtnLeft: {
      "& svg": {
        fontSize: 70,
        color: "#f49719",
      },
      position: "absolute",
      top: 110,
      left: 10,
      zIndex: 99,
      padding: 0,
      borderRadius: 4,
      height: 100,
      width: 45,
    },
    sliderBtnRight: {
      "& svg": {
        fontSize: 70,
        color: "#f49719",
      },
      position: "absolute",
      top: 110,
      right: 10,
      zIndex: 99,
      padding: 0,
      borderRadius: 4,
      height: 100,
      width: 45,
    },
  });
export default CarouselStyles;
