import * as types from "../actions/action-types/actionTypes";
import produce from "immer";
import { PlayerAction } from "../actions/playerActions/playerActionsInterface";
import { TypeLeaguePlayer } from "../../components/general/data-types/DataTypes";

export default function playersReducer(
  state = {
    content: [] as TypeLeaguePlayer[],
    pageSize: 0,
    pageNumber: 0,
    totalPages: 0,
    totalElements: 0,
    playerNames: [] as string[],
    loading: true,
    currentLeague: "",
  },
  action: PlayerAction
) {
  switch (action.type) {
    case types.FETCH_ALL_PLAYERS_SUCCESS:
      action.data.content.forEach((item) => (item.type = "league")); //Populate with type for typescript
      if (action.data.pageNumber > 0) {
        const patchedContent = produce(state, (draft:any) => {
          return draft.content.concat(action.data.content);
        });
        return {
          ...action.data,
          content: patchedContent,
          playerNames: state.playerNames,
          loading: false,
          currentLeague: action.leagueId,
        };
      } else {
        return {
          ...action.data,
          playerNames: state.playerNames,
          loading: false,
          currentLeague: action.leagueId,
        };
      }
    case types.FETCH_ALL_PLAYERS_FAILED:
      return {
        ...state,
        content: action.data,
        loading: false,
        currentLeague: action.leagueId,
      };
    case types.FETCH_ALL_PLAYER_NAMES_SUCCESS:
      return { ...state, playerNames: action.data, loading: false };
    case types.BEGIN_API_CALL_PLAYERS:
      return { ...state, loading: true };
    default:
      return state;
  }
}
