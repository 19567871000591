import { createStyles } from "@material-ui/core";
import adminTheme from "../../themes/admin/adminTheme";

const ScoreStatsStyles = (theme) =>
  createStyles({
    gamesPlayed: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
      maxWidth: 300,
      width: "100%",
    },
    gamesPlayedData: {
      width: "100%",
      paddingRight: 20,
      paddingLeft: 20,
    },
    gamesPlayedText: {
      display: "flex",
      justifyContent: "space-between",
    },
    gpSecondText: {
      fontSize: 12,
      fontWeight: 600,
    },
    gpText: {
      fontSize: 10,
    },
    progBarContainer: {
      order: 2,
    },
    gpdBadgesMobile: {
      width: "100%",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      display: "none",
      marginTop: 4,
      marginBottom: 5,
    },
    badgeItem: {
      border: "2px solid #49495C",
      borderRadius: 4,
      margin: 5,
      fontSize: 12,
      fontWeight: 700,
      padding: "3px 5px",
    },
    gamesPlayedTextMobile: {
      justifyContent: "space-between",
      display: "none",
    },
    gamesPlayedDataUnder: {
      "& img": {
        borderRadius: "50%",
        maxHeight: 80,
        marginLeft: 8,
        marginTop: 2,
        marginRight: 5,
      },
      display: "flex",
      justifyContent: "space-between",
      marginTop: 3,
    },
    gamesPlayedUnder: {
      "& >div": {
        display: "flex",
        flexDirection: "column",
        textAlign: "right",
      },
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    gpuText: {
      fontFamily: "Poppins",
      fontSize: 20,
      color: "#191847",
    },
    gpuRankText: {
      "& $iconHeal": {
        fontSize: 12,
        marginLeft: 5,
        marginRight: 5,
      },
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iconHeal: {
      color: "#FF4133",
      marginRight: 10,
    },
    gpuNumberMobile: {
      display: "none",
    },
    gpdBadges: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      marginTop: 15,
      flexWrap: "wrap",
    },
    midleScore: {
      display: "flex",
      padding: "35px 10px 0",
      flexDirection: "column",
    },

    mscoreTop: {
      display: "flex",
      justifyContent: "space-between",
    },
    gpuNumber: {
      fontSize: 24,
      fontWeight: 600,
    },
    vsCircle: {
      fontSize: 16,
      fontWeight: 600,
      fontFamily: "Poppins",
      backgroundColor: "#F5F6F7",
      width: 36,
      height: 36,
      textAlign: "center",
      borderRadius: "50%",
      lineHeight: "36px",
      marginTop: 3,
    },
    mscoreBot: {
      textAlign: "center",
      marginTop: 15,
      fontSize: 12,
      fontFamily: "'Montserrat'",
    },
    gpdBadgesSecondMobile: {
      width: "100%",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      display: "none",
      marginTop: 4,
      marginBottom: 5,
    },
    gamesPlayedDataUnderSecond: {
      "& img": {
        borderRadius: "50%",
        maxHeight: 80,
        marginRight: 8,
        marginTop: 2,
        marginLeft: 5,
      },
      borderRadius: 10,
      display: "flex",
      justifyContent: "space-between",
      marginTop: 3,
    },
    gamesPlayedUnderSecond: {
      "& >div": {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
      },
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    gpdBadgesSecond: {
      display: "flex",
      width: "100%",
      marginTop: 15,
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },

    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      gamesPlayed: {
        justifyContent: "flex-start",
      },
      gamesPlayedData: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "0 5px",
      },
      gamesPlayedText: {
        display: "none",
      },
      gpdBadgesMobile: {
        display: "flex",
        justifyContent: "flex-start",
        minHeight: 40,
      },
      badgeItem: {
        border: "1px solid #49495C",
        margin: 2,
        padding: "1px 2px",
        fontSize: 8,
        maxHeight: 16,
        boxSizing: "border-box",
      },
      gamesPlayedTextMobile: {
        marginTop: 5,
        display: "flex",
      },
      gamesPlayedDataUnder: {
        "& img": {
          height: 52,
          width: 52,
          marginBottom: 12,
          border: "2px solid transparent",
        },
        "&.active": {
          "& img": {
            borderColor: "#f5a500",
            boxShadow:
              "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          },
        },
        borderRadius: 10,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        order: 1,
      },
      gamesPlayedUnder: {
        "& >div": {
          textAlign: "center",
        },
        order: 2,
        justifyContent: "center",
      },
      gpuText: {
        fontSize: 16,
        minHeight: 48,
      },
      gpuRankText: {
        fontSize: 14,
      },
      gpuNumberMobile: {
        display: "block",
        color: "#191847",
        fontSize: 32,
        margin: "10px 0 0",
        fontWeight: 600,
      },
      gpdBadges: {
        display: "none",
        marginTop: 5,
      },
      midleScore: {
        padding: "9px 5px 0",
      },
      mscoreTop: {
        justifyContent: "center",
      },
      gpuNumber: {
        display: "none",
      },
      vsCircle: {
        "&.active": {
          borderColor: "#f49719",
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        },
        border: "2px solid transparent",
        width: 40,
        height: 40,
        lineHeight: "40px",
        marginTop: 52,
      },
      mscoreBot: {
        display: "none",
      },
      gpdBadgesSecondMobile: {
        display: "flex",
        justifyContent: "flex-start",
        minHeight: 40,
      },
      gamesPlayedDataUnderSecond: {
        "& img": {
          height: 52,
          width: 52,
          marginBottom: 12,
          border: "2px solid transparent",
        },
        "&.active": {
          "& img": {
            borderColor: "#f5a500",
            boxShadow:
              "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          },
        },
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        order: 1,
      },
      gamesPlayedUnderSecond: {
        "& >div": {
          textAlign: "center",
        },
        order: 2,
        justifyContent: "center",
      },

      gpdBadgesSecond: {
        display: "none",
        marginTop: 5,
      },
    },
  });

export default ScoreStatsStyles;
