import { Grid, useMediaQuery, WithStyles, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Forecast from "./forecast/Forecast";
import Score from "./score/Score";
import { RootState } from "../../redux/reducers";
import {
  TypeScore,
  TypeTeamPlayer,
} from "../../components/general/data-types/DataTypes";
import {
  loadTeamRoster,
  changeSelectedTeam,
} from "../../redux/actions/teamActions/teamActions";
import { useParams, useLocation } from "react-router-dom";
import {
  fetchMatchupScore,
  fetchTeamRooster,
} from "../../components/api/general/api";
import AdminTheme from "../../themes/admin/adminTheme";
import LoadingCircle from "../../components/general/LoadingCircle/LoadingCircle";
import SubHeader from "../../components/main/sub-header/SubHeader";
import { useSelector, useDispatch } from "react-redux";

import MatchupStyles from "./Matchup.styles";

interface Props extends WithStyles<typeof MatchupStyles> {}

const Matchup: React.FC<Props> = ({ classes }) => {
  const [subPage, setSubPage] = useState("score");
  const [matchupsScore, setMatchupsScore] = useState<TypeScore>();
  const [scoreLoading, setScoreLoading] = useState(true);
  const [teamRoster, setTeamRoster] = useState<TypeTeamPlayer[]>([]);
  const { id } = useParams<{ id: string }>();
  const isMobile = useMediaQuery(AdminTheme.breakpoints.down("xs"));
  const location = useLocation();
  const partial = location.pathname.substring(1);
  const url = partial.substring(0, partial.indexOf("/"));
  const isMyMatchup = url === "my-matchup" ? true : false;

  const subHeaderData = [
    {
      name: "Score",
      subName: "Totals from games played",
      id: "score",
    },
    {
      name: "Forecast",
      subName: "Projections for upcoming games",
      id: "forecast",
      hideOnMobile: true,
    },
  ];

  const {
    overviews: { selectedOverview, loading: overviewLoading },
    team: { roster },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      setScoreLoading(true);
      fetchMatchupScore(id)
        .then((res) => {
          const statusCode = res.status;
          const data = res.json();
          return Promise.all([statusCode, data]);
        })
        .then(([statusCode, data]) => {
          setScoreLoading(false);
          if (statusCode === 200) {
            setMatchupsScore(data);
          }
        });
    }
  }, [id]);

  useEffect(() => {
    if (selectedOverview && !roster.length && isMyMatchup) {
      dispatch(loadTeamRoster(selectedOverview.team.id));
    } else if (matchupsScore && !isMyMatchup) {
      fetchTeamRooster(matchupsScore.matchup.homeTeam.id)
        .then((res) => {
          const statusCode = res.status;
          const data = res.json();
          return Promise.all([statusCode, data]);
        })
        .then(([statusCode, data]) => {
          if (statusCode === 200) {
            setTeamRoster(data);
          }
        });
    }
  }, [
    dispatch,
    matchupsScore,
    selectedOverview,
    roster,
    setTeamRoster,
    isMyMatchup,
  ]);

  useEffect(() => {
    if (isMyMatchup && selectedOverview) {
      dispatch(changeSelectedTeam(selectedOverview.team.name));
    } else if (matchupsScore) {
      dispatch(changeSelectedTeam(matchupsScore.matchup.homeTeam.name));
    }
  }, [dispatch, isMyMatchup, matchupsScore, selectedOverview]);

  const showSubpage = () => {
    switch (subPage) {
      case "score":
        return scoreLoading ? (
          <LoadingCircle />
        ) : (
          matchupsScore && selectedOverview && (
            <Score
              selectedOverview={selectedOverview}
              matchupsScore={matchupsScore}
              setTeamRoster={setTeamRoster}
              teamRoster={teamRoster}
            />
          )
        );
      case "forecast":
        return scoreLoading ? (
          <LoadingCircle />
        ) : (
          matchupsScore && selectedOverview && !isMobile && (
            <Forecast
              selectedOverview={selectedOverview}
              matchupsScore={matchupsScore}
              isMyMatchup={isMyMatchup}
              setTeamRoster={setTeamRoster}
              teamRoster={teamRoster}
            />
          )
        );
      default:
        return "";
    }
  };

  return (
    <>
      {!overviewLoading && selectedOverview ? (
        <Grid className={classes.content}>
          <SubHeader
            selectedOverview={selectedOverview}
            subPage={subPage}
            setSubPage={setSubPage}
            isMobile={isMobile}
            subHeaderData={subHeaderData}
          />
          {showSubpage()}
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default withStyles(MatchupStyles)(Matchup);
