import React from "react";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import { Grid, Typography } from "@material-ui/core";
import UserStandingsTable from "../../tables/user-standings-table/UserStandingsTable";
import { TypeTeam } from "../../../../components/general/data-types/DataTypes";
import TableSkeleton from "../table-skeleton/TableSkeleton";

import styles from "./Standings.module.scss";

interface Props {
  leagues: TypeTeam[];
  standingsRef: (node: HTMLElement | null) => void;
}

const Standings: React.FC<Props> = ({ leagues, standingsRef }) => {
  const renderTable = () => {
    if (leagues.length > 0) {
      return (
        <UserStandingsTable leagues={leagues} standingsRef={standingsRef} />
      );
    }
    return <TableSkeleton />;
  };

  return (
    <Grid
      item
      className={`${styles.topStatFirst} ${styles.standingTContainer}`}
    >
      <Grid container className={styles.mainComp}>
        <Grid container className={styles.header}>
          <Typography>Standings</Typography>
          <FormatListNumberedIcon style={{ color: "#DB2721" }} />
        </Grid>
        <Grid container className={styles.body} id="testid">
          {renderTable()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Standings;
