import {
  TypeTeamPlayer,
  TypeTeamStat,
} from "../../components/general/data-types/DataTypes";
import { TeamAction } from "../actions/teamActions/teamActionsInterface";
import * as types from "../actions/action-types/actionTypes";

export default function teamReducer(
  state = {
    roster: [] as TypeTeamPlayer[],
    avStats: [] as TypeTeamStat[][],
    teamTotals: [] as TypeTeamStat[][],
    teamWins: [] as TypeTeamStat[][],
    selectedTeam: "" as string,
    loading: true,
  },
  action: TeamAction
) {
  switch (action.type) {
    case types.FETCH_TEAM_ROSTER_SUCCESS:
      action.data.forEach((item) => (item.type = "team")); //Populate with type for typescript
      return {
        ...state,
        roster: action.data,
        loading: false,
      };
    case types.BEGIN_API_CALL_LOAD_ROOSTER:
      return { ...state, loading: true };
    case types.FETCH_TEAM_AV_STATS_SUCCESS:
      return { ...state, avStats: action.data };
    case types.FETCH_TEAM_TOTALS_SUCCESS:
      return { ...state, teamTotals: action.data };
    case types.FETCH_TEAM_WINS_SUCCESS:
      return { ...state, teamWins: action.data };
    case types.CHANGE_SELECTED_TEAM_SUCCESS:
      return { ...state, selectedTeam: action.team };
    case types.FETCH_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
}
