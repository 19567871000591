import { createStyles } from "@material-ui/core";

const LinearProgressBarStyles = (theme) =>
  createStyles({
    container: {
      borderRadius: 20,
      overflow: "hidden",
      position: "relative",
      width: "100%",
    },
    item: {
      borderRadius: 20,
      transition: "transform .4s linear",
      top: 0,
      left: 0,
      width: "100%",
      bottom: 0,
      position: "absolute",
    },
  });

export default LinearProgressBarStyles;
