import React from "react";
import { Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { TypeTransaction } from "../../../../../components/general/data-types/DataTypes";
import moment from "moment";

import SliderCardStyles from "./SliderCard.styles";

interface Props extends WithStyles<typeof SliderCardStyles> {
  item: TypeTransaction;
}

const SliderCard: React.FC<Props> = ({ classes, item }) => {
  const getTransacColor = (value: string) => {
    switch (value) {
      case "add":
        return "#17612E";
      case "drop":
        return "#db2721";
      case "add/drop":
        return "#f49719";
      case "trade":
        return "#2241f2";
      default:
        return "#f49719";
    }
  };
  return (
    <>
      <Grid className={classes.top}>
        <div style={{ display: "flex", direction: "initial" }}>
          <img src={item.teamLogo} alt="" />
          <div className={classes.topText}>
            <Typography style={{ fontWeight: 600 }}>{item.teamName}</Typography>
            <Typography>
              {moment(item.timestamp)
                .format("ddd MMM DD, hh:mm")
                .replace(" ", "\n")}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid className={classes.profileContent}>
        {item.datas.map((player, i) => (
          <Grid
            key={i}
            className={classes.playerContent}
            style={{ width: item.datas.length === 1 ? "160px" : "110px" }}
          >
            <span
              style={{ borderColor: getTransacColor(item.type) }}
              className={classes.line}
            ></span>
            <img
              className={classes.playerImg}
              src={player.playerImage}
              alt=""
            />
            <Typography
              style={{ marginBottom: 0, fontWeight: 500 }}
              className={classes.pName}
            >
              {player.playerFirstName}
            </Typography>
            <Typography className={classes.pName}>
              {player.playerLastName}
            </Typography>
            <Typography style={{ fontSize: 10, fontWeight: 700 }}>
              {player.playerPositions}
            </Typography>
            <Typography className={classes.subtitle}>
              {player.playerFranchise &&
                player.playerFranchise.substr(
                  0,
                  player.playerFranchise.indexOf(" ")
                )}
              {"\n"}
              {player.playerFranchise &&
                player.playerFranchise.substr(
                  player.playerFranchise.indexOf(" "),
                  player.playerFranchise.length
                )}
            </Typography>
            <Grid className={classes.iconContainer}>
              <div style={{ display: "flex", direction: "initial" }}>
                {player.type === "add" ? (
                  <>
                    <ControlPointIcon
                      className={classes.icon}
                      style={{ color: "#17612E" }}
                    />
                    <Typography className={classes.iconText}>
                      {player.sourceType}
                    </Typography>
                  </>
                ) : (
                  <>
                    <RemoveCircleOutlineIcon
                      className={classes.icon}
                      style={{ color: "#DB2721" }}
                    />
                    <Typography className={classes.iconText}>
                      {player.destinationType}
                    </Typography>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default withStyles(SliderCardStyles)(SliderCard);
