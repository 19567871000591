import React from "react";
import { Typography } from "@material-ui/core";
import { TypeLeaguePlayer, TypeTeamPlayer } from "../data-types/DataTypes";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HealingIcon from "@material-ui/icons/Healing";

const iconContainer = {
  display: "flex",
  justifyContent: "center",
  marginTop: 4,
};
const iconSmallContainer = {
  display: "flex",
  justifyContent: "center",
  marginLeft: 5,
};

export const getHealth = (
  player: TypeLeaguePlayer | TypeTeamPlayer,
  small?: boolean
) => {
  switch (player.health) {
    case "Questionable":
      return (
        <div style={small ? { ...iconSmallContainer } : { ...iconContainer }}>
          <HelpOutlineIcon
            style={{
              float: "left",
              fontSize: small ? 12 : 18,
              marginTop: small ? 1 : "",
              marginRight: 3,
              color: "#FF9718",
            }}
          />
          <Typography style={{ fontSize: small ? 10 : 12, float: "left" }}>
            {player.healthCode}
          </Typography>
        </div>
      );
    case "Injured":
      return (
        <div style={small ? { ...iconSmallContainer } : { ...iconContainer }}>
          <HealingIcon
            style={{
              float: "left",
              fontSize: small ? 12 : 18,
              marginTop: small ? 1 : "",
              marginRight: 3,
              color: "#FF4133",
            }}
          />
          <Typography style={{ fontSize: small ? 10 : 12, float: "left" }}>
            {player.healthCode}
          </Typography>
        </div>
      );
    default:
      return "";
  }
};

export const getPageNumber = (pageUrl: string) => {
  const page = pageUrl
    .substring(1)
    .slice(0, pageUrl.lastIndexOf("/"))
    .slice(0, -1);

  switch (page) {
    case "/overview":
      return 0;
    case "/league":
      return 1;
    case "/my-matchup":
      return 2;
    case "/matchup":
      return 2;
    case "/players":
      return 3;
    default:
      return 0;
  }
};

export const status = [
  {
    label: "Available",
    value: "status:EQ:Available",
  },
  {
    label: "Waivers",
    value: "statusType:EQ:waivers",
  },
  {
    label: "Free Agents",
    value: "statusType:EQ:freeagents",
  },
  {
    label: "Rostered",
    value: "status:EQ:Rostered",
  },
];
export const health = [
  {
    label: "Healthy",
    value: "health:EQ:Healthy",
  },
  {
    label: "Questionable",
    value: "health:EQ:Questionable",
  },
  {
    label: "Injured",
    value: "health:EQ:Injured",
  },
];

export const mapToArray = (myMap: any, key: string, val: string) => {
  const keys = Object.keys(myMap);
  return Object.values(myMap).map((item, index) => ({
    [key]: keys[index],
    [val]: item,
  }));
};

export const trimText = (item: string, limit: number) => {
  if (item.length >= limit) {
    return item.substring(0, limit) + "...";
  }
  return item;
};
