import * as types from "../actions/action-types/actionTypes";
import { OverviewActions } from "../actions/overviewsActions/overviewsActionsInterface";
import { TypeOverview } from "../../components/general/data-types/DataTypes";

export default function leaguesReducer(
  state = {
    data: [] as TypeOverview[],
    selectedOverview: undefined as TypeOverview | undefined,
    loading: false,
  },
  action: OverviewActions
) {
  switch (action.type) {
    case types.FETCH_OVERVIEWS_SUCCESS:
      const selectedOverview = getSelectedOverview(action.data);
      return {
        data: action.data,
        loading: false,
        selectedOverview: selectedOverview,
      };
    case types.FETCH_OVERVIEWS_FAILED:
      localStorage.removeItem("selectedOverview");
      return { data: action.data, loading: false, selectedOverview: undefined };
    case types.BEGIN_API_CALL_OVERVIEW:
      return { ...state, loading: true };
    case types.CHANGE_SELECTED_OVERVIEW_SUCCESS:
      const changedOverview = state.data.find(
        (item) => item.league.id === action.id
      );
      changedOverview && localStorage.setItem("selectedOverview", action.id);
      return {
        ...state,
        selectedOverview: changedOverview,
      };
    default:
      return state;
  }
}

const getSelectedOverview = (data: TypeOverview[]) => {
  const lsLeageId = localStorage.getItem("selectedOverview");
  const leagueWithLSId = data.find((item) => item.league.id === lsLeageId);

  if (leagueWithLSId) {
    return leagueWithLSId;
  } else {
    localStorage.setItem("selectedOverview", data[0].league.id);
    return data[0];
  }
};
