import {
  FormControl,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TuneIcon from "@material-ui/icons/Tune";
import SearchIcon from "@material-ui/icons/Search";
import {
  TypeFilterPosition,
  TypeFilters,
  TypeFrachise,
  TypeLeaguePlayers,
  TypeOverview,
} from "../general/data-types/DataTypes";
import { motion, useAnimation } from "framer-motion";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MobileFiltersPage from "./mobile-filters-page/MobileFiltersPage";

import MobileFilterStyles from "./MobileFilters.styles";

interface Props extends WithStyles<typeof MobileFilterStyles> {
  selectedOverview: TypeOverview | undefined;
  players: TypeLeaguePlayers;
  getPlayers: (
    pageNmber: number,
    filters: any,
    withFilter?: boolean | undefined
  ) => void;
  teams: any[];
  filters: TypeFilters;
  setFilters: React.Dispatch<React.SetStateAction<TypeFilters>>;
  onFilterChange: (filter: string, value: any) => void | "";
  startDay: Date | undefined;
  endDay: Date | undefined;
  franchises: TypeFrachise[];
  positions: TypeFilterPosition[];
}

const MobileFilters: React.FC<Props> = ({
  classes,
  selectedOverview,
  players,
  getPlayers,
  teams,
  filters,
  setFilters,
  onFilterChange,
  startDay,
  endDay,
  franchises,
  positions,
}) => {
  const animation = useAnimation();
  const [mobileFilterOpenPage, setMobileFilterOpenPage] = useState("");

  useEffect(() => {
    animation.set({
      y: 800,
    });
  }, [animation]);

  const showFilters = () => {
    animation.start({
      y: 0,
    });
  };

  const hideFilters = () => {
    animation.start({
      y: 800,
    });
  };

  const modifyTarget = (target: any) => {
    if (target > 320) {
      return 800;
    } else {
      return 0;
    }
  };

  return (
    <>
      <motion.div
        drag={mobileFilterOpenPage === "" ? "y" : undefined}
        dragElastic={0.1}
        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 800 }}
        dragTransition={{
          modifyTarget,
          timeConstant: 70,
        }}
        animate={animation}
        transition={{ ease: "easeOut", duration: 0.3 }}
        className={classes.dragableFilters}
      >
        <MobileFiltersPage
          selectedOverview={selectedOverview}
          getPlayers={getPlayers}
          teams={teams}
          hideFilters={hideFilters}
          filters={filters}
          setFilters={setFilters}
          startDay={startDay}
          endDay={endDay}
          onFilterChange={onFilterChange}
          mobileFilterOpenPage={mobileFilterOpenPage}
          setMobileFilterOpenPage={setMobileFilterOpenPage}
          franchises={franchises}
          positions={positions}
        />
      </motion.div>

      <div className={classes.mainContainer}>
        <FormControl variant="outlined" className={classes.inputContainer}>
          <SearchIcon className={classes.searchIcon} />
          <Autocomplete
            id="combo-box-demo"
            options={players.playerNames}
            freeSolo
            getOptionLabel={(option: any) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Search player" />
            )}
            className={classes.playersSearchField}
            onChange={(_, newValue) => {
              onFilterChange("name", newValue);
            }}
          />
          <TuneIcon
            className={classes.filterIcon}
            onClick={() => showFilters()}
          />
        </FormControl>
      </div>
    </>
  );
};

export default withStyles(MobileFilterStyles)(MobileFilters);
