import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import GoogleLogin from "../login/GoogleLogin";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthContext";
import * as yup from "yup";
import { useFormik } from "formik";
import { Alert } from "@material-ui/lab";
import { toast } from "react-toastify";
import YahooIcon from "../../static/logo/YahooIcon.png";

import LoginPopupStyles from "./LoginPopup.styles";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

interface Props extends WithStyles<typeof LoginPopupStyles> {
  setPageType: React.Dispatch<React.SetStateAction<string>>;
}

const LoginPopup: React.FC<Props> = React.forwardRef(
  ({ classes, setPageType }, _) => {
    const openSignup = () => {
      setPageType("signUp");
    };
    const openForgotPass = () => {
      setPageType("forgotPass");
    };

    const [error, setError] = useState("");
    const { login, loginWithGoogle, loginWithYahoo } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (values: any) => {
      try {
        setError("");
        await login(values.email, values.password).catch((error: any) => {
          toast.error(error.message);
        });
      } catch {
        setError("Failed to log in");
      }
    };

    const handleGoogleLogin = async () => {
      try {
        setError("");
        await loginWithGoogle()
          .then(() => {
            navigate("/overview");
          })
          .catch((error: any) => {
            toast.error(error.message);
          });
      } catch {
        setError("Failed to log in");
      }
    };

    const handleYahooLogin = async () => {
      try {
        setError("");
        await loginWithYahoo()
          .then(() => {
            navigate("/overview");
          })
          .catch((error: any) => {
            toast.error(error.message);
          });
      } catch {
        setError("Failed to log in");
      }
    };

    const formik = useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmit(values);
      },
    });

    return (
      <Grid container className={classes.popupContainer}>
        <Grid container className={classes.popupDiv}>
          <Grid item xs={12}>
            <Typography className={classes.logoText}>
              Log in to Fantasy Insights
            </Typography>
            <Typography className={classes.underLogoText}>
              Don't have an account yet?{" "}
              <span onClick={openSignup}>Sign up for free</span>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <GoogleLogin handleGoogleLogin={handleGoogleLogin} />
            <Button
              variant="outlined"
              size="large"
              disableElevation
              className={classes.yahooLogin}
              onClick={handleYahooLogin}
              startIcon={
                <img
                  style={{ maxWidth: 22, borderRadius: "50%" }}
                  src={YahooIcon}
                  alt="Yahoo Icon"
                />
              }
            >
              Continue with Yahoo
            </Button>
            <Typography
              className={classes.orSeparator}
              style={{ margin: "32px 0" }}
            >
              Or
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                value={formik.values.email}
                label="Enter your username or Email"
                variant="outlined"
                className={classes.input}
                size="small"
                id="email"
                name="email"
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                value={formik.values.password}
                label="Enter your password"
                variant="outlined"
                className={classes.input}
                size="small"
                id="password"
                name="password"
                type="password"
                style={{ marginBottom: 11 }}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              {error && <Alert severity="error">{error}</Alert>}
              <Typography
                className={classes.forgotPass}
                onClick={openForgotPass}
              >
                Forgot password?
              </Typography>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.loginBtn}
              >
                Login
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default withStyles(LoginPopupStyles)(LoginPopup);
