import { GameSchedule } from "../../components/general/data-types/DataTypes";
import * as types from "../actions/action-types/actionTypes";
import { GamesAction } from "../actions/gameActions/gameActionsInterface";

export default function gameReducer(
  state = {
    schedule: [] as GameSchedule[],
  },
  action: GamesAction
) {
  switch (action.type) {
    case types.FETCH_NBA_SCHEDULE:
      return { ...state, schedule: action.data };
    default:
      return state;
  }
}
