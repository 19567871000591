import React, { useEffect, useState } from "react";
import { Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import HealingIcon from "@material-ui/icons/Healing";
import { TypeOverview, TypeScore } from "../general/data-types/DataTypes";
import LinearProgressBar from "../general/linear-progress-bar/LinearProgressBar";

import ScoreStatsStyles from "./ScoreStats.styles";
import moment from "moment";

interface Props extends WithStyles<typeof ScoreStatsStyles> {
  selectedOverview: TypeOverview;
  matchupsScore: TypeScore;
  isMobile: boolean;
  options?: string;
  setMobileShownStat?: React.Dispatch<React.SetStateAction<string>>;
  mobileShownStat?: string;
}

const ScoreStats: React.FC<Props> = ({
  classes,
  selectedOverview,
  matchupsScore,
  isMobile,
  options,
  setMobileShownStat,
  mobileShownStat,
}) => {
  const [homeTeam, setHomeTeam] = useState(0);
  const [visitingTeam, setVisitingTeam] = useState(0);

  useEffect(() => {
    switch (options) {
      case "Played":
        setHomeTeam(matchupsScore.stats.played.homeTeamWins);
        setVisitingTeam(matchupsScore.stats.played.visitingTeamWins);
        return;
      case "Averages":
        setHomeTeam(matchupsScore.stats.averages.homeTeamWins);
        setVisitingTeam(matchupsScore.stats.averages.visitingTeamWins);
        return;
      case "Projected":
        setHomeTeam(matchupsScore.stats.projected.homeTeamWins);
        setVisitingTeam(matchupsScore.stats.projected.visitingTeamWins);
        return;
      case "Forecast":
        setHomeTeam(matchupsScore.stats.forecast.homeTeamWins);
        setVisitingTeam(matchupsScore.stats.forecast.visitingTeamWins);
        return;
    }
  }, [matchupsScore.stats, options]);

  const gameColor = (from: string) => {
    if (homeTeam === visitingTeam) {
      return "#49495C";
    }
    const homeColor = homeTeam > visitingTeam ? "#17612E" : "#DB2721";
    const visitingColor = homeTeam < visitingTeam ? "#17612E" : "#DB2721";
    return from === "home" ? homeColor : visitingColor;
  };

  return (
    <Grid container style={{ marginBottom: 26 }}>
      <Grid item xs={5}>
        <Grid className={classes.gamesPlayed}>
          <div className={classes.gamesPlayedData}>
            <div className={classes.gamesPlayedText}>
              <Typography className={classes.gpText}>Games Played</Typography>
              <Typography className={classes.gpSecondText}>
                {matchupsScore.matchup.homeTeamCompletedGames}/
                {matchupsScore.matchup.homeTeamCompletedGames +
                  matchupsScore.matchup.homeTeamRemainingGames}
              </Typography>
            </div>
            <div className={classes.progBarContainer}>
              <div className={classes.gpdBadgesMobile}>
                {matchupsScore.matchup.homeTeamBadges.map(
                  (badge: string, index: number) => (
                    <Typography key={index} className={classes.badgeItem}>
                      {badge}
                    </Typography>
                  )
                )}
              </div>
              <LinearProgressBar
                progress={
                  (matchupsScore.matchup.homeTeamCompletedGames /
                    (matchupsScore.matchup.homeTeamCompletedGames +
                      matchupsScore.matchup.homeTeamRemainingGames)) *
                  100
                }
                bgColor="#D2D2D2"
                color="#558B95"
                height={3}
              />
              <div className={classes.gamesPlayedTextMobile}>
                <Typography className={classes.gpText}>Games Played</Typography>
                <Typography className={classes.gpSecondText}>
                  {matchupsScore.matchup.homeTeamCompletedGames}/
                  {matchupsScore.matchup.homeTeamCompletedGames +
                    matchupsScore.matchup.homeTeamRemainingGames}
                </Typography>
              </div>
            </div>
            <div
              className={`${classes.gamesPlayedDataUnder} ${
                mobileShownStat === "firstTeam" && "active"
              }`}
              onClick={() =>
                isMobile &&
                setMobileShownStat &&
                setMobileShownStat("firstTeam")
              }
            >
              <div className={classes.gamesPlayedUnder}>
                <div>
                  <Typography
                    className={classes.gpuText}
                    style={{
                      fontWeight:
                        selectedOverview.team.id ===
                        matchupsScore.matchup.homeTeam.id
                          ? 600
                          : 400,
                    }}
                  >
                    {matchupsScore.matchup.homeTeam.name}
                  </Typography>
                  <Typography className={classes.gpuRankText}>
                    {matchupsScore.matchup.homeTeam.summary}
                    {isMobile && (
                      <>
                        <HealingIcon className={classes.iconHeal} />
                        {matchupsScore.matchup.homeTeam.injuredPlayers}
                      </>
                    )}
                  </Typography>
                  <Typography
                    className={classes.gpuNumberMobile}
                    style={{ color: gameColor("home") }}
                  >
                    {homeTeam}
                  </Typography>
                </div>
              </div>
              <img
                src={matchupsScore.matchup.homeTeam.logo}
                alt="Home team logo"
              />
            </div>
          </div>
          <div className={classes.gpdBadges}>
            {matchupsScore.matchup.homeTeamBadges.map(
              (badge: string, index: number) => (
                <Typography key={index} className={classes.badgeItem}>
                  {badge}
                </Typography>
              )
            )}
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={2}
        className={classes.midleScore}
        onClick={() =>
          isMobile && setMobileShownStat && setMobileShownStat("stats")
        }
      >
        <div className={classes.mscoreTop}>
          <Typography
            className={classes.gpuNumber}
            style={{ color: gameColor("home") }}
          >
            {matchupsScore.matchup.homeTeamWins}
          </Typography>
          <div
            className={`${classes.vsCircle} ${
              mobileShownStat === "stats" && "active"
            }`}
          >
            VS
          </div>
          <Typography
            className={classes.gpuNumber}
            style={{ color: gameColor("visiting") }}
          >
            {matchupsScore.matchup.visitingTeamWins}
          </Typography>
        </div>
        <div className={classes.mscoreBot}>
          {moment(selectedOverview.league.currentDate).format("ddd MMM DD")}
        </div>
      </Grid>
      <Grid item xs={5} style={{ justifyContent: "flex-end", display: "flex" }}>
        <Grid className={classes.gamesPlayed}>
          <div className={classes.gamesPlayedData}>
            <div className={classes.gamesPlayedText}>
              <Typography className={classes.gpSecondText}>
                {matchupsScore.matchup.visitingTeamCompletedGames}/
                {matchupsScore.matchup.visitingTeamCompletedGames +
                  matchupsScore.matchup.visitingTeamRemainingGames}
              </Typography>
              <Typography className={classes.gpText}>Games Played</Typography>
            </div>
            <div className={classes.progBarContainer}>
              <div className={classes.gpdBadgesSecondMobile}>
                {matchupsScore.matchup.visitingTeamBadges.map(
                  (badge: string, index: number) => (
                    <Typography key={index} className={classes.badgeItem}>
                      {badge}
                    </Typography>
                  )
                )}
              </div>
              <LinearProgressBar
                progress={
                  (matchupsScore.matchup.visitingTeamCompletedGames /
                    (matchupsScore.matchup.visitingTeamCompletedGames +
                      matchupsScore.matchup.visitingTeamRemainingGames)) *
                  100
                }
                reverse={true}
                bgColor="#D2D2D2"
                color="#558B95"
                height={3}
              />
              <div className={classes.gamesPlayedTextMobile}>
                <Typography className={classes.gpSecondText}>
                  {matchupsScore.matchup.visitingTeamCompletedGames}/
                  {matchupsScore.matchup.visitingTeamCompletedGames +
                    matchupsScore.matchup.visitingTeamRemainingGames}
                </Typography>
                <Typography className={classes.gpText}>Games Played</Typography>
              </div>
            </div>
            <div
              className={`${classes.gamesPlayedDataUnderSecond} ${
                mobileShownStat === "secondTeam" && "active"
              }`}
              onClick={() =>
                isMobile &&
                setMobileShownStat &&
                setMobileShownStat("secondTeam")
              }
            >
              <img
                src={matchupsScore.matchup.visitingTeam.logo}
                alt="Visiting team logo"
              />
              <div className={`${classes.gamesPlayedUnderSecond} `}>
                <div>
                  <Typography
                    className={classes.gpuText}
                    style={{
                      fontWeight:
                        selectedOverview.team.id ===
                        matchupsScore.matchup.visitingTeam.id
                          ? 600
                          : 400,
                    }}
                  >
                    {matchupsScore.matchup.visitingTeam.name}
                  </Typography>
                  <Typography className={classes.gpuRankText}>
                    {matchupsScore.matchup.visitingTeam.summary}
                    {isMobile && (
                      <>
                        <HealingIcon className={classes.iconHeal} />
                        {matchupsScore.matchup.visitingTeam.injuredPlayers}
                      </>
                    )}
                  </Typography>
                  <Typography
                    className={classes.gpuNumberMobile}
                    style={{ color: gameColor("visiting") }}
                  >
                    {visitingTeam}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.gpdBadgesSecond}>
            {matchupsScore.matchup.visitingTeamBadges.map(
              (badge: string, index: number) => (
                <Typography key={index} className={classes.badgeItem}>
                  {badge}
                </Typography>
              )
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(ScoreStatsStyles)(ScoreStats);
