import React from "react";
import { CircularProgress } from "@material-ui/core";

interface Props {}

const LoadingCircle: React.FC<Props> = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress style={{ color: "#FF4133" }} />
    </div>
  );
};

export default LoadingCircle;
