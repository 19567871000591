import React, { useEffect, useState } from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { RootState } from "../../redux/reducers";
import Dashboard from "./dashboard/Dashboard";
import Schedule from "./schedule/Schedule";
import TeamStats from "./team-stats/TeamStats";
import {
  loadTeamAvStats,
  loadTeamTotals,
  loadTeamWins,
} from "../../redux/actions/teamActions/teamActions";
import { loadTeamsData } from "../../redux/actions/teamsActions/teamsActions";
import { loadNBASchedule } from "../../redux/actions/gameActions/gameActions";
import SubHeader from "../../components/main/sub-header/SubHeader";
import { useSelector, useDispatch } from "react-redux";

import LeagueStyles from "./League.styles";

interface Props extends WithStyles<typeof LeagueStyles> {}

const League: React.FC<Props> = ({ classes }) => {
  const [subPage, setSubPage] = useState("overview");
  const subHeaderData = [
    {
      name: "Dashboard",
      subName: "League Insights and Stats",
      id: "overview",
    },
    {
      name: "Team Stats",
      subName: "Win-Loss, Totals and Averages",
      id: "teamStats",
    },
    {
      name: "NBA Schedule",
      subName: "Number of games played each week",
      id: "schedule",
    },
  ];
  const {
    teams,
    overviews: { selectedOverview, loading: overviewLoading },
    team: { avStats, teamTotals, teamWins },
    game: { schedule },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const currentLeageIdFromLS = localStorage.getItem("selectedOverview") || "";

  useEffect(() => {
    if (selectedOverview && !avStats.length) {
      dispatch(loadTeamAvStats(currentLeageIdFromLS));
    }
  }, [dispatch, selectedOverview, avStats, currentLeageIdFromLS]);

  useEffect(() => {
    if (selectedOverview && !teamTotals.length) {
      dispatch(loadTeamTotals(currentLeageIdFromLS));
    }
  }, [dispatch, selectedOverview, teamTotals, currentLeageIdFromLS]);

  useEffect(() => {
    if (selectedOverview && !teamWins.length) {
      dispatch(loadTeamWins(currentLeageIdFromLS));
    }
  }, [dispatch, selectedOverview, teamWins, currentLeageIdFromLS]);

  useEffect(() => {
    if (!schedule.length) {
      dispatch(loadNBASchedule());
    }
  }, [dispatch, selectedOverview, schedule]);

  useEffect(() => {
    if (selectedOverview && !teams.length) {
      dispatch(loadTeamsData(currentLeageIdFromLS));
    }
  }, [dispatch, selectedOverview, teams, currentLeageIdFromLS]);

  const showSubpage = () => {
    switch (subPage) {
      case "overview":
        return (
          selectedOverview && (
            <Dashboard selectedOverview={selectedOverview} teamsData={teams} />
          )
        );
      case "teamStats":
        return (
          selectedOverview && (
            <TeamStats
              selectedOverview={selectedOverview}
              teamAverages={avStats}
              teamTotals={teamTotals}
              teamWins={teamWins}
              teamsData={teams}
            />
          )
        );
      case "schedule":
        return (
          selectedOverview && (
            <Schedule schedule={schedule} selectedOverview={selectedOverview} />
          )
        );
      default:
        return "";
    }
  };

  return (
    <>
      {!overviewLoading && selectedOverview && (
        <Grid className={classes.content}>
          <SubHeader
            selectedOverview={selectedOverview}
            subPage={subPage}
            setSubPage={setSubPage}
            subHeaderData={subHeaderData}
          />
          {showSubpage()}
        </Grid>
      )}
    </>
  );
};

export default withStyles(LeagueStyles)(League);
