import { Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";

import StatOptionsBarStyles from "./StatOptionsBar.styles";

interface IStatOptionsBarProps extends WithStyles<typeof StatOptionsBarStyles> {
  options: string;
  setOptions: React.Dispatch<React.SetStateAction<string>>;
}

const StatOptionsBar: React.FunctionComponent<IStatOptionsBarProps> = ({
  classes,
  options,
  setOptions,
}) => {
  return (
    <Grid container style={{ justifyContent: "center" }}>
      <div className={classes.optionSelect}>
        <Typography
          className={options === "Played" ? classes.opActive : ""}
          onClick={() => setOptions("Played")}
        >
          Played
        </Typography>
        <Typography
          className={options === "Averages" ? classes.opActive : ""}
          onClick={() => setOptions("Averages")}
        >
          Averages
        </Typography>
        <Typography
          className={options === "Projected" ? classes.opActive : ""}
          onClick={() => setOptions("Projected")}
        >
          Projected
        </Typography>
        <Typography
          className={options === "Forecast" ? classes.opActive : ""}
          onClick={() => setOptions("Forecast")}
        >
          Forecast
        </Typography>
      </div>
    </Grid>
  );
};

export default withStyles(StatOptionsBarStyles)(StatOptionsBar);
