import { useEffect } from "react";

export default function useRefresh(navigate: any, path: string) {
  let handler: any;

  const refresh = () => {
    navigate(path);
    handler = setTimeout(() => navigate(path), 10);
  };

  useEffect(() => {
    return () => handler && clearTimeout(handler);
  }, [handler]);

  return refresh;
}
