import React, { useCallback, useEffect, useState } from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import {
  TypeOverview,
  TypeTeam,
} from "../../../components/general/data-types/DataTypes";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import HeightIcon from "@material-ui/icons/Height";
import {
  loadMatchupsData,
  loadMatchupWins,
  loadWeeklyAdditions,
  loadNextMatchup,
} from "../../../redux/actions/matchupsActions/matchupsActions";
import { loadTeamScheduleData } from "../../../redux/actions/teamScheduleActions/teamScheduleActions";
import Matchups from "./matchups/Matchups";
import Standings from "./standings/Standings";
import Schedule from "./schedule/Schedule";
import { RootState } from "../../../redux/reducers";
import { isEmpty } from "lodash";
import Transactions from "./transactions/Transactions";
import AdminTheme from "../../../themes/admin/adminTheme";
import MobileTransactions from "./mobile-transactions/MobileTransactions";
import AddsThisWeek from "./general-components/AddsThisWeek";
import Week from "./general-components/Week";
import MobileTopCards from "./general-components/MobileTopCards";
import { useSelector, useDispatch } from "react-redux";
import RankBox from "./components/rank-box/RankBox";
import ChartBox from "./components/chart-box/ChartBox";
import AddsThisWeekBox from "./components/adds-this-week-box/AddsThisWeekBox";
import WeekBox from "./components/week-box/WeekBox";
import GamesPlayedBox from "./components/games-played-box/GamesPlayedBox";
import MatchupsWonBox from "./components/matchups-won-box/MatchupsWonBox";
import NextMatchupBox from "./components/next-matchup-box/NextMatchupBox";

import DashboardStyles from "./Dashboard.styles";

interface Props extends WithStyles<typeof DashboardStyles> {
  selectedOverview: TypeOverview;
  teamsData: TypeTeam[];
}

const Dashboard: React.FC<Props> = ({
  classes,
  selectedOverview,
  teamsData,
}) => {
  const [elemHeight, setElemHeight] = useState<number>(0);
  const isMobile = useMediaQuery(AdminTheme.breakpoints.down("xs"));
  const chartOptions = {
    series: [
      +(
        (selectedOverview.team.wins /
          (selectedOverview.team.wins +
            selectedOverview.team.losses +
            selectedOverview.team.ties)) *
        100
      ).toFixed(2),
      +(
        (selectedOverview.team.losses /
          (selectedOverview.team.wins +
            selectedOverview.team.losses +
            selectedOverview.team.ties)) *
        100
      ).toFixed(2),
      +(
        (selectedOverview.team.ties /
          (selectedOverview.team.wins +
            selectedOverview.team.losses +
            selectedOverview.team.ties)) *
        100
      ).toFixed(2),
    ],
    options: {
      chart: {
        type: "donut",
        width: 380,
      },
      colors: ["#7EBFBF", "#DB2721", "#FF9718"],
      labels: ["Wins", "Losses", "Ties"],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          customScale: 1.2,
          startAngle: -90,
          endAngle: 90,
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                offsetY: -23,
                fontSize: 14,
              },
              value: {
                show: true,
                offsetY: -18,
                fontSize: 14,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        offsetY: 1,
      },
    },
  };

  const {
    matchups: { wins: matchupWins, data: matchupsData, weekAdds, nextMatchup },
    teamSchedules,
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const currentLeageIdFromLS = localStorage.getItem("selectedOverview") || "";

  const standingsRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setElemHeight(node.clientHeight + 30);
    }
  }, []);

  useEffect(() => {
    if (matchupsData && !matchupsData.length) {
      dispatch(loadMatchupsData(currentLeageIdFromLS));
    }
  }, [dispatch, matchupsData, currentLeageIdFromLS]);

  useEffect(() => {
    if (!teamSchedules.length) {
      dispatch(loadTeamScheduleData(currentLeageIdFromLS));
    }
  }, [dispatch, teamSchedules, currentLeageIdFromLS]);

  useEffect(() => {
    if (isEmpty(matchupWins)) {
      dispatch(loadMatchupWins(currentLeageIdFromLS));
    }
  }, [dispatch, matchupWins, currentLeageIdFromLS]);

  useEffect(() => {
    if (isEmpty(weekAdds)) {
      dispatch(loadWeeklyAdditions(currentLeageIdFromLS));
    }
  }, [dispatch, weekAdds, currentLeageIdFromLS]);

  useEffect(() => {
    if (isEmpty(nextMatchup)) {
      dispatch(loadNextMatchup(currentLeageIdFromLS));
    }
  }, [dispatch, nextMatchup, currentLeageIdFromLS]);

  const showRankArrow = () => {
    const rank =
      selectedOverview.team.lastWeekRank - selectedOverview.team.rank;
    if (rank > 0) {
      return <ArrowUpwardIcon style={{ color: "#17612E" }} />;
    } else if (rank < 0) {
      return <ArrowDownwardIcon style={{ color: "#DB2721" }} />;
    }
    return <HeightIcon style={{ color: "#49495C" }} />;
  };

  return (
    <>
      <Grid container className={classes.topStatsContainer}>
        <Grid item container className={classes.topStatFirst}>
          <RankBox />
          <ChartBox chartOptions={chartOptions} />
          <AddsThisWeekBox />
        </Grid>
        <Grid item container className={classes.topStatSecond}>
          <WeekBox overviewLeague={selectedOverview.league} />
          <GamesPlayedBox overviewMatchup={selectedOverview.matchup} />
        </Grid>
        <Grid item container className={classes.topStatThird}>
          <MatchupsWonBox />
          <NextMatchupBox />
        </Grid>
      </Grid>
      <Grid container className={classes.mobileTopCards}>
        <MobileTopCards
          showRankArrow={showRankArrow}
          team={selectedOverview.team}
        />
      </Grid>
      <Grid container className={classes.tablesContainer}>
        <Standings standingsRef={standingsRef} leagues={teamsData} />
        <Matchups elemHeight={elemHeight} leagues={matchupsData} />
        <Grid container className={classes.weekData}>
          <Grid item xs={6} style={{ paddingRight: 8 }}>
            <Grid className={classes.shadowContainer}>
              <AddsThisWeek weekAdds={weekAdds} />
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 8 }}>
            <Grid className={classes.shadowContainer}>
              <Week league={selectedOverview.league} />
            </Grid>
          </Grid>
        </Grid>
        <Schedule elemHeight={elemHeight} leagues={teamSchedules} />
      </Grid>
      <Grid container>
        {isMobile ? <MobileTransactions /> : <Transactions />}
      </Grid>
    </>
  );
};

export default withStyles(DashboardStyles)(Dashboard);
