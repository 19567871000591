import { MatchupsAction } from "../actions/matchupsActions/matchupActionsInterface";
import * as types from "../actions/action-types/actionTypes";
import {
  TypeMatchup,
  TypeMatchupWins,
  TypeWeekAdds,
} from "../../components/general/data-types/DataTypes";

export default function matchupsReducer(
  state = {
    data: [] as TypeMatchup[] | undefined,
    loading: true,
    wins: {} as TypeMatchupWins,
    weekAdds: {} as TypeWeekAdds,
    nextMatchup: undefined as TypeMatchup | undefined,
    forecastLoading: true,
    weekAddsLoading: true,
  },
  action: MatchupsAction
) {
  switch (action.type) {
    case types.FETCH_MATCHUPS_SUCCESS:
      const matchup = action.data.length > 0 ? action.data : undefined;
      return { ...state, data: matchup, loading: false };
    case types.BEGIN_API_CALL_MATCHUPS:
      return { ...state, loading: true };
    case types.FETCH_MATCHUPS_WINS_SUCCESS:
      return { ...state, wins: action.data };
    case types.BEGIN_API_CALL_MATCHUPS_SCORE:
      return { ...state, scoreLoading: true };
    case types.BEGIN_API_CALL_WEEK_ADDS:
      return { ...state, weekAddsLoading: true };
    case types.FETCH_MATCHUPS_WEEKLY_ADDITIONS_SUCCESS:
      return { ...state, weekAdds: action.data, weekAddsLoading: false };
    case types.FETCH_NEXT_MATCHUP_SUCCESS:
      return { ...state, nextMatchup: action.data };
    case types.FETCH_NO_RESPONSE:
      return { ...state, nextMatchup: undefined };
    default:
      return state;
  }
}
