import {
  Button,
  Grid,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React from "react";
import AddLeagueHeader from "../add-leage-header/AddLeagueHeader";

import DashboardStyles from "../Dashboard.styles";

interface Props extends WithStyles<typeof DashboardStyles> {
  connectToNewLeague: () => void;
}

const AddLeaguePage: React.FC<Props> = ({ classes, connectToNewLeague }) => {
  return (
    <>
      <AddLeagueHeader connectToNewLeague={connectToNewLeague} />
      <Grid container style={{ paddingTop: 70, flexDirection: "column" }}>
        <Typography variant="h1" className={classes.addLeagueTitle}>
          Ready to take control of your league?
        </Typography>
        <Typography className={classes.noLeagueSubtext}>
          Pick any yahoo H2H league and start getting insights about what to do
          next
        </Typography>
        <Button
          variant="contained"
          size="large"
          color="primary"
          disableElevation
          onClick={() => connectToNewLeague()}
          style={{
            margin: "0 auto",
          }}
          className={classes.linkBtn}
        >
          Add League
        </Button>
      </Grid>
    </>
  );
};

export default withStyles(DashboardStyles)(AddLeaguePage);
