import React, { useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import YahooCallbackComponent from "./components/yahoo/yahoo-popup/YahooCallbackComponent";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Dashboard from "./containers/dashboard/Dashboard";
import League from "./containers/league/League";
import Team from "./containers/team/Team";
import Matchup from "./containers/matchup/Matchup";
import Players from "./containers/players/Players";
import { connect } from "react-redux";
import { TypeLogedUser } from "./components/general/data-types/DataTypes";
import Donate from "./containers/donate/Donate";
import { SignInPage } from "./containers/sign-in-page/SignInPage";

import ProtectedLayout from "./containers/protected-layout/ProtectedLayout ";
import { ThemeProvider } from "@material-ui/core";
import adminTheme from "./themes/admin/adminTheme";

//https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/

interface Props {}

const App: React.FC<Props> = () => {
  const [userData, setUserData] = useState<TypeLogedUser>();

  return (
    <ThemeProvider theme={adminTheme}>
      <Routes>
        <Route>
          <Route path="/" element={<SignInPage />} />
          <Route path="/yahoo/callback" element={<YahooCallbackComponent />} />
        </Route>
        <Route element={<ProtectedLayout setUserData={setUserData} />}>
          <Route path="/overview" element={<Dashboard userData={userData} />} />
          <Route path="/league/:id" element={<League />} />
          <Route path="/my-team/:id" element={<Team />} />
          <Route path="/team/:id" element={<Team />} />
          <Route path="/my-matchup/:id" element={<Matchup />} />
          <Route path="/matchup/:id" element={<Matchup />} />
          <Route path="/players/:id" element={<Players />} />
          {/* <Route
            path="/my-account"
            element={
              <MyAccount currentUser={currentUser} userData={userData} />
            }
          /> */}
          <Route path="/donate" element={<Donate />} />
        </Route>
      </Routes>

      <ToastContainer autoClose={3000} hideProgressBar />
    </ThemeProvider>
  );
};

export default connect()(App);
