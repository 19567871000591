import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MuiTableCell from "@material-ui/core/TableCell";
import { withStyles, WithStyles } from "@material-ui/core";
import { TypeTeamSchedule } from "../../../../components/general/data-types/DataTypes";

import UserLeaguesScheduleStyles from "./UserLeaguesSchedule.styles";

interface Props extends WithStyles<typeof UserLeaguesScheduleStyles> {
  leagues: TypeTeamSchedule[];
  elemHeight: number;
}

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const UserLeaguesSchedule: React.FC<Props> = ({
  classes,
  leagues,
  elemHeight,
}) => {
  const getColor = (result: string) => {
    if (result === "Win") {
      return "#17612E";
    } else if (result === "Loss") {
      return "#DB2721";
    } else if (result === "In Progress") {
      return "#FF9718";
    } else {
      return "#49495C";
    }
  };
  return (
    <>
      <TableContainer style={{ maxHeight: elemHeight }}>
        <Table stickyHeader size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Week</TableCell>
              <TableCell align="left">Opponent</TableCell>
              <TableCell align="center">Result</TableCell>
              <TableCell align="center">Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leagues.map((league, index) => (
              <TableRow key={index} hover>
                <TableCell align="center">{league.week}</TableCell>
                <TableCell align="left" style={{ fontWeight: 600 }}>
                  {league.opponent}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: getColor(league.result), fontWeight: 600 }}
                >
                  {league.result}
                </TableCell>
                <TableCell align="center">{league.score}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStyles(UserLeaguesScheduleStyles)(UserLeaguesSchedule);
