import { Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { TypeTeam } from "../../../../components/general/data-types/DataTypes";

import DashboardStyles from "../Dashboard.styles";

interface Props extends WithStyles<typeof DashboardStyles> {
  showRankArrow: () => JSX.Element;
  team: TypeTeam;
}

const MobileTopCards: React.FC<Props> = ({ classes, showRankArrow, team }) => {
  return (
    <>
      <Grid item xs={4} style={{ paddingRight: 8 }}>
        <Grid className={classes.shadowContainer}>
          <Typography className={classes.hrRank}>Rank</Typography>
          <div className={classes.hrBox}>
            <div>
              {showRankArrow()}
              <Typography>{team.ordinal}</Typography>
            </div>
            {team.rank === team.lastWeekRank ? (
              <Typography className={classes.sameWeekTxt}>
                Same as last week
              </Typography>
            ) : (
              <>
                <Typography style={{ fontSize: 8, marginTop: 6 }}>
                  {team.lastWeekRank - team.rank > 0 ? "+" : ""}
                  {team.lastWeekRank - team.rank} from last week
                </Typography>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={4} style={{ padding: "0 8px" }}>
        <Grid className={classes.shadowContainer}>
          <Typography className={classes.hrRank}>Games Behind</Typography>
          <div className={classes.hrBox}>
            <div>
              {showRankArrow()}
              <Typography>{team.gamesBack}</Typography>
            </div>
            {team.rank === team.lastWeekRank ? (
              <Typography className={classes.sameWeekTxt}>
                Same as last week
              </Typography>
            ) : (
              <>
                <Typography style={{ fontSize: 8, marginTop: 6 }}>
                  {team.lastWeekRank - team.rank > 0 ? "+" : ""}
                  {team.lastWeekRank - team.rank} from last week
                </Typography>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={4} style={{ paddingLeft: 8 }}>
        <Grid className={classes.shadowContainer}>
          <Typography className={classes.hrRank}>Games Behind</Typography>
          <div className={classes.hrBox}>
            <div>
              {showRankArrow()}
              <Typography>{team.waiverPriority}</Typography>
            </div>
            {team.rank === team.lastWeekRank ? (
              <Typography className={classes.sameWeekTxt}>
                Same as last week
              </Typography>
            ) : (
              <>
                <Typography style={{ fontSize: 8, marginTop: 6 }}>
                  {team.lastWeekRank - team.rank > 0 ? "+" : ""}
                  {team.lastWeekRank - team.rank} from last week
                </Typography>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(DashboardStyles)(MobileTopCards);
