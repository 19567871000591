import { Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { TypeWeekAdds } from "../../../../components/general/data-types/DataTypes";

import DashboardStyles from "../Dashboard.styles";

interface Props extends WithStyles<typeof DashboardStyles> {
  weekAdds: TypeWeekAdds;
}

const AddsThisWeek: React.FC<Props> = ({ classes, weekAdds }) => {
  return (
    <>
      <Typography className={classes.amaStatsTitle}>Adds this Week</Typography>
      <Grid container>
        <Grid item xs={4} className={classes.amaStatsTopContainer}>
          <Typography className={classes.amaStatsTitleTopB}>
            {weekAdds.allowed}
          </Typography>
          <Typography className={classes.amaStatsbot}>Allowed</Typography>
        </Grid>
        <Grid item xs={4} className={classes.amaStatsTopContainer}>
          <Typography className={classes.amaStatsTitleTopG}>
            {weekAdds.made}
          </Typography>
          <Typography className={classes.amaStatsbot}>Made</Typography>
        </Grid>
        <Grid item xs={4} className={classes.amaStatsTopContainer}>
          <Typography className={classes.amaStatsTitleTopY}>
            {weekAdds.available}
          </Typography>
          <Typography className={classes.amaStatsbot}>Available</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(DashboardStyles)(AddsThisWeek);
