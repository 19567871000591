import { combineReducers } from "redux";
import overviews from "./overviewReducer";
import teams from "./teamsReducer";
import matchups from "./matchupsReducer";
import teamSchedules from "./teamScheduleReducer";
import players from "./playersReducer";
import team from "./teamReducer";
import game from "./gameReducer";
import transactions from "./transactionsReducer";
import {
  TypeMatchupWins,
  TypeWeekAdds,
} from "../../components/general/data-types/DataTypes";

const appReducer = combineReducers({
  overviews,
  teams,
  team,
  matchups,
  teamSchedules,
  players,
  game,
  transactions,
});

const getNewState = (item: any) => {
  return {
    overviews: item.overviews,
    teams: [],
    team: {
      roster: [],
      avStats: [],
      teamTotals: [],
      teamWins: [],
      selectedTeam: "",
      loading: true,
    },
    matchups: {
      data: [],
      loading: true,
      wins: {} as TypeMatchupWins,
      weekAdds: {} as TypeWeekAdds,
      nextMatchup: undefined,
      forecastLoading: true,
      weekAddsLoading: true,
      scoreLoading: true,
    },
    teamSchedules: [],
    players: {
      content: [],
      pageSize: 0,
      pageNumber: 0,
      totalPages: 0,
      totalElements: 0,
      playerNames: [],
      loading: true,
      currentLeague: "",
    },
    game: {
      schedule: [],
    },
    transactions: [],
  };
};

const rootReducer = (state: any, action: any) => {
  if (action.type === "NEW_LEAGUE") {
    return appReducer(getNewState(state), action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
