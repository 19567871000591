import {
  TableCell,
  TableRow,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import {
  TypeLeaguePlayer,
  TypeOverview,
  TypePlayerStat,
  TypePlayerStats,
  TypeTeamPlayer,
} from "../general/data-types/DataTypes";

import PlayersTableStyles from "./PlayersTable.styles";

interface Props extends WithStyles<typeof PlayersTableStyles> {
  player: TypeLeaguePlayer | TypeTeamPlayer;
  selectedOverview: TypeOverview;
  withNoSelection?: boolean;
  options?: string;
  selectedDay: string;
}

const StatsRow: React.FC<Props> = ({
  classes,
  player,
  selectedOverview,
  withNoSelection,
  options,
  selectedDay,
}) => {
  const completeStats = (statsList: TypePlayerStat[]) => {
    const newStat: TypePlayerStat = {
      category: "",
      date: "",
      percentage: false,
      value: "-",
      composite: false,
    };
    let alteredList: TypePlayerStat[] = statsList;
    while (alteredList.length < selectedOverview.league.categoryList.length) {
      alteredList = [...alteredList, newStat];
    }
    return alteredList;
  };

  const categories = (stats: TypePlayerStats) => {
    if (stats) {
      switch (options) {
        case "Forecast":
          return completeStats(stats.forecast);
        case "Projected":
          return completeStats(stats.projected);
        case "Score":
          return completeStats(stats.played);
        case "Played":
          return completeStats(stats.played);
        case "Averages":
          return completeStats(stats.averages);
        default:
          return [];
      }
    } else {
      return [];
    }
  };

  const showCategories = () => {
    if (withNoSelection) {
      return categories(player.stats).filter(
        (item) => item.date === selectedDay || item.date === null
      );
    } else {
      return categories(player.stats);
    }
  };

  // const arr = [1, 2, 3, 4, 5, 6];

  return (
    <TableRow hover>
      {showCategories().length > 0
        ? showCategories().map((item, ix) => (
            <TableCell
              align="center"
              key={`str-${ix}`}
              className={classes.fixedCategory}
            >
              <Typography className={classes.tdText}>
                {item.value}
                {item.percentage && item.value !== "-" ? "%" : ""}
              </Typography>
            </TableCell>
          ))
        : selectedOverview.league.categoryList.map((_, i) => (
            <TableCell
              align="center"
              key={`stre-${i}`}
              className={classes.fixedCategory}
            >
              {"-"}
            </TableCell>
          ))}
      {/* {arr.map((_, idx) => (
        <TableCell
          align="center"
          key={`sarr-${idx}`}
          className={classes.fixedCategory}
        >
          {"-"}
        </TableCell>
      ))} */}
    </TableRow>
  );
};

export default withStyles(PlayersTableStyles)(StatsRow);
