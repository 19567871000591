import { createStyles } from "@material-ui/core";
import adminTheme from "../../../../themes/admin/adminTheme";

const SelectLeagueTableStyles = (theme) =>
  createStyles({
    tableLogo: {
      "& img": {
        maxWidth: 35,
        float: "left",
        marginRight: 15,
        padding: "6px 0",
      },
    },
    itemsContainer: {
      height: 360,
      width: 610,
      overflow: "hidden",
      overflowY: "auto",
      margin: "30px auto",
      padding: "0px 46px",
    },
    title: {
      fontSize: 24,
      color: "#191847",
      fontWeight: "bold",
      textAlign: "center",
      margin: "35px 0 40px",
    },
    closeIcon: {
      fontSize: 27,
      position: "absolute",
      top: 10,
      right: 10,
      cursor: "pointer",
      color: "#868686",
    },
    popBtnAdd: {
      // background: "#FF4133",
      color: "#fff",
      fontSize: 14,
      fontWeight: "bold",
      margin: "40px auto 0",
      display: "block",
      minWidth: 250,
      padding: "7px 0px",
      borderRadius: 30,
      textTransform: "capitalize",
    },

    /////////////// - Media Queries Down - ///////////////

    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      itemsContainer: {
        width: "100%",
        padding: 0,
      },
      cardLeft: {
        "& p": {
          fontSize: 14,
        },
      },
    },
  });

export default SelectLeagueTableStyles;
