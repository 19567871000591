import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { isEmpty } from "lodash";
import { PieChart } from "react-minimal-pie-chart";
import CircleAndLineSkeleton from "../../skeletons/CircleAndLineSkeleton/CircleAndLineSkeleton";

import styles from "./MatchupsWonBox.module.scss";

interface IMatchupsWonBoxProps {}

const MatchupsWonBox: React.FunctionComponent<IMatchupsWonBoxProps> = () => {
  const {
    matchups: { wins: matchupWins },
  } = useSelector((state: RootState) => state);

  const doughnutChartMWData = () => {
    if (matchupWins) {
      const data = [
        {
          title: "Wins",
          value: matchupWins.wins,
          color: "#17612E",
        },
        {
          title: "losses",
          value: matchupWins.total - matchupWins.wins,
          color: "#e1e5eb",
        },
      ];
      return data;
    }
  };

  if (isEmpty(matchupWins)) {
    return (
      <Grid
        item
        xs={4}
        className={styles.shadowContainer}
        style={{ padding: "15px 20px" }}
      >
        <CircleAndLineSkeleton />
      </Grid>
    );
  }

  return (
    <Grid item xs={4} className={styles.shadowContainer}>
      <div className={styles.secondChartContainer}>
        <Typography>{matchupWins.percentage}</Typography>
        <PieChart
          startAngle={-90}
          data={doughnutChartMWData()}
          lineWidth={15}
        />
      </div>
      <Typography className={styles.csText}>
        Won {matchupWins.wins} of {matchupWins.total} matchups
      </Typography>
    </Grid>
  );
};

export default MatchupsWonBox;
