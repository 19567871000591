import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import MatchupCard from "./matchup-cards/MatchupCard";
import { ReactComponent as TrophyIcon } from "../../../../static/images/trophy-icon.svg";
import { TypeMatchup } from "../../../../components/general/data-types/DataTypes";
import TableSkeleton from "../table-skeleton/TableSkeleton";
import AdminTheme from "../../../../themes/admin/adminTheme";
import MobileMatchupCard from "./mobile-matchup-card/MobileMatchupCard";

import styles from "./Matchups.module.scss";

interface Props {
  leagues: TypeMatchup[] | undefined;
  elemHeight: number;
}

const Matchups: React.FC<Props> = ({ leagues, elemHeight }) => {
  const isMobile = useMediaQuery(AdminTheme.breakpoints.down("xs"));

  const renderTable = () => {
    if (leagues && leagues.length > 0) {
      return leagues.map((item, i: number) =>
        isMobile ? (
          <MobileMatchupCard matchup={item} key={`matchup-${i}`} />
        ) : (
          <MatchupCard matchup={item} key={`matchup-${i}`} />
        )
      );
    }
    return <TableSkeleton />;
  };

  return (
    <Grid
      item
      className={`${styles.topStatSecond} ${styles.matchupTContainer}`}
    >
      <Grid container className={styles.mainComp}>
        <Grid container className={styles.header}>
          <Typography>Matchups</Typography>
          <TrophyIcon />
        </Grid>
        <Grid
          container
          className={styles.body}
          style={{ height: isMobile ? "" : elemHeight }}
        >
          {renderTable()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Matchups;
