import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  withStyles,
  WithStyles,
  useMediaQuery,
} from "@material-ui/core";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PeopleIcon from "@material-ui/icons/People";
import HealingIcon from "@material-ui/icons/Healing";
import BotStats from "./bot-stats/BotStats";
import {
  TypeOverview,
  TypeScore,
  TypeTeamPlayer,
} from "../../../components/general/data-types/DataTypes";
import LoadingCircle from "../../../components/general/LoadingCircle/LoadingCircle";
import { isEmpty } from "lodash";
import AdminTheme from "../../../themes/admin/adminTheme";
import ScoreStats from "../../../components/score-stats/ScoreStats";
import MobileTable from "../../../components/mobile-table/MobileTable";
import StatOptionsBar from "../../../components/stat-options-bar/StatOptionsBar";
import { useDispatch } from "react-redux";
import { fetchTeamRooster } from "../../../components/api/general/api";

import ScoreStyles from "./Score.styles";

interface Props extends WithStyles<typeof ScoreStyles> {
  selectedOverview: TypeOverview;
  matchupsScore: TypeScore;
  setTeamRoster: React.Dispatch<React.SetStateAction<TypeTeamPlayer[]>>;
  teamRoster: TypeTeamPlayer[];
}

const Score: React.FC<Props> = ({
  classes,
  selectedOverview,
  matchupsScore,
  setTeamRoster,
  teamRoster,
}) => {
  const isMobile = useMediaQuery(AdminTheme.breakpoints.down("xs"));
  const [options, setOptions] = useState("Forecast");
  const [mobileShownStat, setMobileShownStat] = useState("stats");
  const [localLoading, setLocalLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (mobileShownStat === "firstTeam" && isMobile) {
      setLocalLoading(true);
      fetchTeamRooster(matchupsScore.matchup.homeTeam.id)
        .then((res) => {
          const statusCode = res.status;
          const data = res.json();
          return Promise.all([statusCode, data]);
        })
        .then(([statusCode, data]) => {
          if (statusCode === 200) {
            setTeamRoster(data);
          }
          setLocalLoading(false);
        });
    } else if (isMobile) {
      setLocalLoading(true);
      fetchTeamRooster(matchupsScore.matchup.visitingTeam.id)
        .then((res) => {
          const statusCode = res.status;
          const data = res.json();
          return Promise.all([statusCode, data]);
        })
        .then(([statusCode, data]) => {
          if (statusCode === 200) {
            setTeamRoster(data);
          }
          setLocalLoading(false);
        });
    }
  }, [
    dispatch,
    mobileShownStat,
    matchupsScore.matchup,
    isMobile,
    setTeamRoster,
  ]);

  const renderMobileComponent = () => {
    switch (mobileShownStat) {
      case "firstTeam":
      case "secondTeam":
        return (
          <>
            {localLoading ? (
              <div style={{ width: "100%", height: 200 }}>
                <LoadingCircle />
              </div>
            ) : (
              <MobileTable
                players={teamRoster}
                selectedOverview={selectedOverview}
                startDay={new Date(selectedOverview.matchup.weekStart)}
                endDay={new Date(selectedOverview.matchup.weekEnd)}
                options={options}
                weeksPosFromTop={369}
              />
            )}
          </>
        );
      case "stats":
        return (
          <Grid style={{ width: "100vw" }}>
            {!isEmpty(matchupsScore) ? (
              <BotStats matchupsScore={matchupsScore} isMobile={isMobile} />
            ) : (
              <LoadingCircle />
            )}
          </Grid>
        );
    }
  };

  return (
    <>
      <Grid container className={classes.matchupTableContainer}>
        <Grid className={classes.mTopContainer}>
          <Grid item style={{ paddingRight: 34 }} container xs={6}>
            <Grid item xs={7} className={`${classes.healthContainer}`}>
              <div
                style={{ padding: "23px 10px" }}
                className={classes.shadowContainer}
              >
                <div style={{ marginBottom: 21 }}>
                  <div>
                    <LocalHospitalIcon className={classes.hoverIcon} />
                    <Typography>Players Health</Typography>
                  </div>
                </div>
                <div>
                  <div>
                    <HealingIcon className={classes.iconHeal} />
                    <Typography>
                      {matchupsScore.matchup.homeTeam.injuredPlayers}
                    </Typography>
                  </div>
                  <div>
                    <HelpOutlineIcon className={classes.iconQuestion} />
                    <Typography>
                      {matchupsScore.matchup.homeTeam.questionablePlayers}
                    </Typography>
                  </div>
                  <div>
                    <CheckCircleIcon className={classes.iconCheck} />
                    <Typography>
                      {matchupsScore.matchup.homeTeam.healthyPlayers}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={5} className={`${classes.playingToday}`}>
              <div
                style={{ padding: "23px 10px" }}
                className={classes.shadowContainer}
              >
                <div style={{ marginBottom: 21 }}>
                  <div>
                    <DirectionsRunIcon className={classes.hoverIcon} />
                    <Typography>Playing Today</Typography>
                  </div>
                </div>
                <div>
                  <PeopleIcon className={classes.iconPeople} />
                  <Typography>
                    {matchupsScore.matchup.homeTeam.playingToday}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item style={{ paddingLeft: 34 }} container xs={6}>
            <Grid item xs={5} className={`${classes.playingToday}`}>
              <div
                style={{ padding: "23px 10px" }}
                className={classes.shadowContainer}
              >
                <div style={{ marginBottom: 21 }}>
                  <div>
                    <DirectionsRunIcon className={classes.hoverIcon} />
                    <Typography>Playing Today</Typography>
                  </div>
                </div>
                <div>
                  <PeopleIcon className={classes.iconPeople} />
                  <Typography>
                    {matchupsScore.matchup.visitingTeam.playingToday}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={7}
              style={{ padding: "0 0 0 17px" }}
              className={`${classes.healthContainer}`}
            >
              <div
                style={{ padding: "23px 10px" }}
                className={classes.shadowContainer}
              >
                <div style={{ marginBottom: 21 }}>
                  <div>
                    <LocalHospitalIcon className={classes.hoverIcon} />
                    <Typography>Players Health</Typography>
                  </div>
                </div>
                <div>
                  <div>
                    <HealingIcon className={classes.iconHeal} />
                    <Typography>
                      {matchupsScore.matchup.visitingTeam.injuredPlayers}
                    </Typography>
                  </div>
                  <div>
                    <HelpOutlineIcon className={classes.iconQuestion} />
                    <Typography>
                      {matchupsScore.matchup.visitingTeam.questionablePlayers}
                    </Typography>
                  </div>
                  <div>
                    <CheckCircleIcon className={classes.iconCheck} />
                    <Typography>
                      {matchupsScore.matchup.visitingTeam.healthyPlayers}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.mBotContainer}>
          <ScoreStats
            selectedOverview={selectedOverview}
            matchupsScore={matchupsScore}
            isMobile={isMobile}
            options={options}
            setMobileShownStat={setMobileShownStat}
            mobileShownStat={mobileShownStat}
          />
          {isMobile && (
            <div className={classes.statsContainer}>
              <StatOptionsBar options={options} setOptions={setOptions} />
            </div>
          )}
          {!isMobile && (
            <Grid>
              {!isEmpty(matchupsScore) ? (
                <BotStats matchupsScore={matchupsScore} isMobile={isMobile} />
              ) : (
                <LoadingCircle />
              )}
            </Grid>
          )}
          {isMobile && renderMobileComponent()}
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(ScoreStyles)(Score);
