import { createStyles } from "@material-ui/core";

const LoginStyles = (theme) =>
  createStyles({
    loginBtn: {
      borderRadius: 50,
      padding: "10px 34px",
      margin: "15px 0",
    },
    logoutBtn: {
      margin: "0 auto",
      textTransform: "none",
    },
    logoutBtnAdmin: {
      "& svg": {
        marginRight: 10,
        fontSize: 22,
      },
      display: "flex",
      marginBottom: 16,
      marginTop: 16,
      padding: "0 16px",
    },
    googleLogin: {
      borderColor: "#DADCE0",
      borderWidth: 1,
      color: "#49495C",
      width: 364,
      borderRadius: 30,
      textTransform: "capitalize",
    },
  });

export default LoginStyles;
