import { Grid, withStyles, WithStyles, Typography } from "@material-ui/core";
import React from "react";
import { TypeOverview } from "../../general/data-types/DataTypes";

import SubHeaderStyles from "./SubHeader.styles";

interface Props extends WithStyles<typeof SubHeaderStyles> {
  selectedOverview: TypeOverview;
  subPage: string;
  setSubPage: React.Dispatch<React.SetStateAction<string>>;
  subHeaderData: any[];
  isMobile?: boolean;
}

const SubHeader: React.FC<Props> = ({
  classes,
  selectedOverview,
  subPage,
  setSubPage,
  subHeaderData,
  isMobile = false,
}) => {
  return (
    <Grid container className={classes.topMenuContainer}>
      <Grid item className={classes.headLogoText}>
        <img
          className={classes.topLogo}
          src={selectedOverview.league.logoUrl}
          alt="Team logo"
        />
        <div>
          <Typography className={classes.topTitle}>
            {selectedOverview.league.name}
          </Typography>
          <Typography className={classes.topSummary}>
            {selectedOverview.league.summary}
          </Typography>
        </div>
      </Grid>
      <Grid className={classes.headMenu}>
        <div className={classes.hMenuContainer}>
          {subHeaderData.map((item, index) =>
            item.hideOnMobile && isMobile ? (
              ""
            ) : (
              <div
                key={index}
                className={subPage === item.id ? classes.activeMenu : ""}
                onClick={() => setSubPage(item.id)}
              >
                <Typography className={classes.hMenuTitle}>
                  {subHeaderData[index].name}
                </Typography>
                <Typography className={classes.hMenuSTitle}>
                  {subHeaderData[index].subName}
                </Typography>
              </div>
            )
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(SubHeaderStyles)(SubHeader);
