import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Avatar,
  Button,
  Collapse,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { ArrowDropDown } from "@material-ui/icons";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Logout from "../../login/Logout";
import { ReactComponent as LogoSvg } from "../../../static/logo/Logo.svg";
import adminTheme from "../../../themes/admin/adminTheme";
import { RootState } from "../../../redux/reducers";
import { toast } from "react-toastify";
import InstallPWAComponent from "../../general/install-pwa-button/InstallPWAButton";
import FavoriteIcon from "@material-ui/icons/Favorite";

import { useSelector } from "react-redux";

import HeaderStyles from "./Header.styles";

interface Props extends WithStyles<typeof HeaderStyles> {
  userData: any;
}

const Header: React.FC<Props> = ({ userData, classes }) => {
  const [showHeadrDropdown, setShowHeaderDropdown] = useState(false);
  const isMobile = useMediaQuery(adminTheme.breakpoints.down("xs"));
  const headPopupRef = useRef<HTMLDivElement>(null);
  const [hrStyles, setHrStyles] = useState({
    height: ".25rem",
    width: "80px",
    margin: 0,
    background: "#FF4133",
    border: "none",
    transition: ".3s ease-in-out",
    marginLeft: "2.5%",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const {
    overviews: { selectedOverview },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        headPopupRef.current &&
        !headPopupRef.current.contains(event.target)
      ) {
        setShowHeaderDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [headPopupRef]);

  useEffect(() => {
    const page = location.pathname.substring(1, location.pathname.length);
    switch (true) {
      case page.includes("overview"):
        return setHrStyles((current) => ({
          ...current,
          marginLeft: "2.2%",
          width: "80px",
        }));
      case page.includes("league"):
        return setHrStyles((current) => ({
          ...current,
          marginLeft: "18.5%",
          width: "80px",
        }));
      case page.includes("team"):
        return setHrStyles((current) => ({
          ...current,
          marginLeft: "35.6%",
          width: "80px",
        }));
      case page.includes("matchup"):
        return setHrStyles((current) => ({
          ...current,
          marginLeft: "52.1%",
          width: "80px",
        }));
      case page.includes("players"):
        return setHrStyles((current) => ({
          ...current,
          marginLeft: "69.1%",
          width: "80px",
        }));
      case page.includes("donate"):
        return setHrStyles((current) => ({
          ...current,
          marginLeft: "85.6%",
          width: "80px",
        }));
      default:
        return setHrStyles((current) => ({ ...current, width: "0" }));
    }
  }, [location.pathname]);

  const checkIdStatus = () => {
    if (!selectedOverview) {
      toast.error("Add a league first!");
    }
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Grid container className={classes.headerContainer}>
        <Grid item className={classes.logoContainer}>
          <LogoSvg />
        </Grid>
        <Grid item className={classes.menuContainer}>
          <Toolbar className={classes.headToolbar}>
            <ul className={classes.topNav}>
              <li>
                <NavLink to="/overview">
                  <Typography
                    variant="subtitle1"
                    className={classes.headerFont}
                  >
                    Overview
                  </Typography>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${
                    selectedOverview &&
                    selectedOverview.league &&
                    selectedOverview.league.id
                      ? `/league/${selectedOverview.league.id}`
                      : `/overview`
                  }`}
                  onClick={() => checkIdStatus()}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.headerFont}
                  >
                    League
                  </Typography>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${
                    selectedOverview &&
                    selectedOverview.team &&
                    selectedOverview.team.id
                      ? `/my-team/${selectedOverview.team.id}`
                      : `/overview`
                  }`}
                  onClick={() => checkIdStatus()}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.headerFont}
                  >
                    Team
                  </Typography>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${
                    selectedOverview &&
                    selectedOverview.matchup &&
                    selectedOverview.matchup.id
                      ? `/my-matchup/${selectedOverview.matchup.id}`
                      : `/overview`
                  }`}
                  onClick={() => checkIdStatus()}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.headerFont}
                  >
                    Matchup
                  </Typography>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${
                    selectedOverview &&
                    selectedOverview.league &&
                    selectedOverview.league.id
                      ? `/players/${selectedOverview.league.id}`
                      : `/overview`
                  }`}
                  onClick={() => checkIdStatus()}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.headerFont}
                  >
                    Players
                  </Typography>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/donate`}>
                  <Typography
                    variant="subtitle1"
                    className={classes.headerFont}
                  >
                    Support us
                  </Typography>
                </NavLink>
              </li>
              <hr style={{ ...hrStyles }} />
            </ul>
          </Toolbar>
          <Grid item className={classes.headOptions}>
            {isMobile ? (
              <div
                className={classes.dropdownTriggerContainer}
                onClick={() => setShowHeaderDropdown(true)}
              >
                <Avatar
                  alt={userData.displayName}
                  src={userData.photoURL}
                  className={classes.avImage}
                />
              </div>
            ) : (
              <>
                <div
                  className={classes.dropdownTriggerContainer}
                  onClick={() => setShowHeaderDropdown(true)}
                >
                  <Avatar
                    alt={userData.displayName}
                    src={userData.photoURL}
                    className={classes.avImage}
                  />
                  <div className={classes.headPersonalData}>
                    <Typography className={classes.headName}>
                      {userData.displayName}
                    </Typography>
                    <Typography className={classes.headEmail}>
                      {userData.email}
                    </Typography>
                  </div>
                  <ArrowDropDown
                    fontSize="small"
                    style={{ cursor: "pointer", marginRight: 12 }}
                  />
                </div>
              </>
            )}
            <Collapse in={showHeadrDropdown} className={classes.userDropdown}>
              <div ref={headPopupRef}>
                <div className={classes.headImgAndText}>
                  <Avatar
                    alt={userData.displayName}
                    src={userData.photoURL}
                    className={classes.avImagePop}
                  />
                  <div className={classes.popupProfileContainer}>
                    <div className={classes.popupProfile}>
                      <Typography className={classes.popupUserName}>
                        {userData.displayName}
                      </Typography>
                      <Typography className={classes.popupEmail}>
                        {userData.email}
                      </Typography>
                    </div>
                    <NavLink
                      to="/my-account"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        disableElevation
                        className={classes.manageAccBtn}
                        onClick={() => setShowHeaderDropdown(false)}
                      >
                        Manage your account
                      </Button>
                    </NavLink>
                  </div>
                </div>
                <div className={classes.headOptionLinks}>
                  <div className={classes.optionLink}>
                    <Typography onClick={() => navigate(`/donate`)}>
                      <FavoriteIcon style={{ color: "#ee4233" }} />
                      Support us
                    </Typography>
                  </div>
                  <div className={classes.optionLink}>
                    <InstallPWAComponent type="text" />
                  </div>
                </div>
                <div className={classes.headOptionsAndImg}>
                  <div style={{ width: "100%" }}>
                    <div className={classes.headOption}>
                      <Logout />
                    </div>
                  </div>
                </div>
                <div className={classes.policyTerms}>
                  <a href="/" target="_blank">
                    Privacy Policy
                  </a>
                  <span aria-hidden="true">•</span>
                  <a href="/" target="_blank">
                    Terms of Service
                  </a>
                </div>
              </div>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default withStyles(HeaderStyles)(Header);
