export interface TypeLogedUser {
  id: string;
  email: string;
  name: string;
  yahooRefreshToken: string;
  hasYahooAccount: boolean;
}

export interface TypeLeague {
  id: string;
  leagueId: string;
  leagueKey: string;
  leagueType: string;
  gameCode: string;
  name: string;
  season: number;
  scoringType: string;
  numberOfTeams: number;
  logoUrl: string;
  startWeek: number;
  currentWeek: number;
  endWeek: number;
  startDate: Date;
  currentDate: string;
  endDate: Date;
  url: string;
  renew: string;
  weeklyDeadline: string;
  editKey: string;
  draftStatus: string;
  shortInvitationUrl: string;
  provider: string;
  allowAddToDlExtraPos: boolean;
  cashLeague: boolean;
  proLeague: boolean;
  finished: boolean;
  summary: string;
  updatedTimeSpan: string;
  categoryList: string[];
}

export interface TypeTeam {
  id: string;
  client: string;
  league: string;
  teamId: string;
  teamKey: string;
  lastWeekRank: number;
  name: string;
  url: string;
  logo: string;
  rank: number;
  wins: number;
  losses: number;
  ties: number;
  ordinal: string;
  percentage: string;
  gamesBack: string;
  nrMoves: string;
  nrTrades: string;
  waiverPriority: number;
  playingToday: number;
  healthyPlayers: number;
  questionablePlayers: number;
  injuredPlayers: number;
  summary: string;
  winningPercentage: string;
}

export interface TypeMatchup {
  id: string;
  league: string;
  homeTeam: TypeTeam;
  homeTeamWins: number;
  homeTeamRemainingGames: number;
  homeTeamCompletedGames: number;
  homeTeamStarPlayers: TypeLeaguePlayer[];
  visitingTeam: TypeTeam;
  visitingTeamWins: number;
  visitingTeamRemainingGames: number;
  visitingTeamCompletedGames: number;
  status: string;
  week: number;
  weekStart: string;
  weekEnd: string;
  isConsolation: boolean;
  isPlayoffs: boolean;
  isTied: boolean;
  homeTeamTotalGames: number;
  homeTeamBadges: [string];
  homeTeamAddsMade: number;
  homeTeamAddsAvailable: number;
  visitingTeamTotalGames: number;
  visitingTeamBadges: [string];
  visitingTeamAddsMade: number;
  visitingTeamAddsAvailable: number;
  visitingTeamStarPlayers: TypeLeaguePlayer[];
}

export interface TypeTeamSchedule {
  week: number;
  opponent: string;
  score: string;
  result: string;
}

export const noResponse: any[] = [];

export interface TypeOverview {
  league: TypeLeague;
  team: TypeTeam;
  matchup: TypeMatchup;
}

export interface TypeWeekAdds {
  allowed: number;
  made: number;
  available: number;
}

export interface TypeMatchupWins {
  wins: number;
  total: number;
  percentage: string;
}

export interface TypeScore {
  matchup: TypeMatchup;
  stats: TypeScoreStats;
  players: TypeTeamPlayer[];
}

export interface TypeScoreStats {
  played: TypeMatchupStats;
  averages: TypeMatchupStats;
  projected: TypeMatchupStats;
  forecast: TypeMatchupStats;
}

export interface TypeOpponent {
  eventId: string;
  date: string;
  name: string;
  code: string;
  logoUrl: string;
}

export interface TypePlayerStats {
  played: TypePlayerStat[];
  averages: TypePlayerStat[];
  projected: TypePlayerStat[];
  forecast: TypePlayerStat[];
}

export interface TypePlayerStat {
  value: string;
  date: string;
  category: string;
  percentage: boolean;
  composite: boolean;
}

export interface TypeMatchupStats {
  categories: TypeCategoryElement[];
  isTied: boolean;
  homeTeamWin: boolean;
  homeTeamWins: number;
  visitingTeamWin: boolean;
  visitingTeamWins: number;
}

export interface TypeCategoryElement {
  category: string;
  isTied: boolean;
  isPercentage: boolean;
  isPositive: boolean;
  homeTeamWin: boolean;
  homeTeamValue: number;
  visitingTeamWin: boolean;
  visitingTeamValue: number;
  homeTeamPercentage: number;
  visitingTeamPercentage: number;
}

export interface TypeTeamStat {
  teamName: string;
  teamLogo: string;
  category: string;
  isPercentage: boolean;
  positive: boolean;
  composite: boolean;
  value: number;
  details: string;
  percentage: string;
  hidden?: boolean;
}

export interface GameSchedule {
  schedule: Schedule[];
  teamLogo: string;
  teamName: string;
}

export interface Schedule {
  homeGames: number;
  totalGames: number;
  visitingGames: number;
  week: number;
}

export interface TypeTransaction {
  id: string;
  teamName: string;
  teamLogo: string;
  timestamp: string;
  type: string;
  status: string;
  datas: DataS[];
}

export interface DataS {
  teamName: string;
  teamLogo: string;
  playerName: string;
  playerImage: string;
  playerPositions: string;
  playerFranchise: string;
  playerFirstName: string;
  playerLastName: string;
  type: string;
  sourceType: string;
  destinationType: string;
}

export interface TypePlayer {
  type: string;
  name: string;
  details: string;
  headShotUrl: string;
  status: string;
  statusType: string;
  health: string;
  healthCode: string;
  gamesRemaining: number;
  opponents: TypeOpponent[];
  stats: TypePlayerStats;
  silosUrl: string;
  leaguePlayerId: string;
}
export interface TypeLeaguePlayer extends TypePlayer {
  type: "league";
  rank: number;
  isSwappable: true;
}
export interface TypeTeamPlayer extends TypePlayer {
  type: "team";
  date: string;
  position: string;
  positionOrder: number;
  swappable: boolean;
}

export interface TypeLeaguePlayers {
  content: TypeLeaguePlayer[];
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  totalElements: number;
  playerNames: any[];
  currentLeague: string;
}

export interface TypeFrachise {
  fullName: string;
  tricode: string;
}

export interface TypeFilterPosition {
  count: number;
  gamePosition: TypeGamePosition;
  id: string;
  league: string;
  name: string;
  positionType: string;
}

export interface TypeGamePosition {
  abbreviation: string;
  displayName: string;
  game: string;
  id: string;
  name: string;
  order: number;
  positionType: string;
}

export interface TypeFilters {
  name: string | null;
  status: string;
  fantasyTeam: string;
  health: string;
  franchise: string;
  position: string;
  gamesRemaining: string;
  date: string | null;
}
