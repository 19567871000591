import { createStyles } from "@material-ui/core";

const MobileTableStyles = (theme) =>
  createStyles({
    mobileHeader: {
      display: "flex",
      padding: "0 5px",
      boxSizing: "border-box",
      margin: "10px 0 0",
    },
    categoryItem: {
      "& p": {
        fontSize: 11,
        fontWeight: 600,
      },
      display: "flex",
      maxWidth: 45,
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    tableRow: {
      display: "flex",
      flexDirection: "column",
      borderBottom: "solid 2px #e5e5e5",
      padding: "0 5px",
    },
    rank: {
      "& p": {
        fontSize: 14,
        fontWeight: 600,
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
      width: 30,
    },
    summaryContainer: {
      display: "flex",
      flexDirection: "column",
      paddingTop: 3,
    },
    playerName: {
      "& p": {
        fontSize: 12,
        fontWeight: 600,
      },
      marginBottom: 2,
    },
    playerdetail: {
      "& span": {
        "& p": {
          fontSize: 10,
        },
      },
      display: "flex",
      alignItems: "center",
    },
    tableRowStats: {
      display: "flex",
    },
    categoryVal: {
      display: "flex",
      flexGrow: 1,
      maxWidth: 45,
      alignItems: "center",
      justifyContent: "center",
    },
    sticky: {
      position: "fixed",
      top: 60,
      left: 0,
      width: "100%",
      zIndex: 99,
      background: "#fff",
      padding: "10px 0 10px",
    },
    body: {
      borderTop: "solid 2px #e5e5e5",
    },
    avImage: {
      float: "left",
      marginRight: 8,
    },
    tdText: {
      fontSize: 10,
      fontWeight: 600,
    },
    weekContainer: {
      display: "flex",
      width: "100%",
      padding: "0 5px",
      boxSizing: "border-box",
    },
    weekDay: {
      background: "#F5F6F7",
      minWidth: 45,
      height: 30,
      fontSize: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      marginRight: 4,
      flexGrow: 1,
      borderRadius: 4,
      fontFamily: "Montserrat, Roboto, sans-serif",
      whiteSpace: "break-spaces",
    },
    selectedDay: {
      background: "#FF9718",
      color: "#fff",
    },
  });

export default MobileTableStyles;
