import { Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import React from "react";
import { TypeMatchup } from "../../../../../components/general/data-types/DataTypes";
import LinearProgressBar from "../../../../../components/general/linear-progress-bar/LinearProgressBar";
import { useNavigate } from "react-router-dom";

import MobileMatchupCardStyles from "./MobileMatchupCard.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";

interface Props extends WithStyles<typeof MobileMatchupCardStyles> {
  matchup: TypeMatchup;
}

const MobileMatchupCard: React.FC<Props> = ({ classes, matchup }) => {
  const {
    overviews: { selectedOverview },
  } = useSelector((state: RootState) => state);

  const gameColor = (from?: string) => {
    if (matchup.homeTeamWins === matchup.visitingTeamWins) {
      return { color: "#49495C" };
    }
    const homeColor =
      matchup.homeTeamWins > matchup.visitingTeamWins ? "#17612E" : "#DB2721";
    const visitingColor =
      matchup.homeTeamWins < matchup.visitingTeamWins ? "#17612E" : "#DB2721";
    return { color: from === "home" ? homeColor : visitingColor };
  };
  const navigate = useNavigate();

  const onRowClick = () => {
    if (selectedOverview?.matchup.id === matchup.id) {
      navigate(`/my-matchup/${matchup.id}`);
    } else {
      navigate(`/matchup/${matchup.id}`);
    }
  };

  const getTeamName = (name: string) => {
    return <Typography className={classes.gpuText}>{name}</Typography>;
  };

  return (
    <Grid
      container
      className={classes.mainContainer}
      onClick={() => onRowClick()}
    >
      <Grid item className={classes.card} style={{ marginBottom: 20 }}>
        <div className={classes.matchupContent}>
          <div className={classes.gamesPlayedText}>
            <Typography className={classes.smallText}>Games Played</Typography>
            <Typography className={classes.smallText}>
              {matchup.homeTeamCompletedGames}/
              {matchup.homeTeamCompletedGames + matchup.homeTeamRemainingGames}
            </Typography>
          </div>
          <LinearProgressBar
            progress={
              (matchup.homeTeamCompletedGames /
                (matchup.homeTeamCompletedGames +
                  matchup.homeTeamRemainingGames)) *
              100
            }
            bgColor="#D2D2D2"
            color="#558B95"
            height={3}
          />
          <div className={classes.gamesPlayedDataUnder}>
            <img src={matchup.homeTeam.logo} alt="Home team logo" />
            <div className={classes.gamesPlayedUnder}>
              {getTeamName(matchup.homeTeam.name)}
              <Typography className={classes.gpuRankText}>
                {matchup.homeTeam.summary}
              </Typography>
            </div>
          </div>
        </div>
        <Typography
          className={`${classes.gpuNumber}`}
          style={gameColor("home")}
        >
          {matchup.homeTeamWins}
        </Typography>
      </Grid>
      <Grid item className={classes.card}>
        <div className={classes.matchupContent}>
          <div className={classes.gamesPlayedText}>
            <Typography className={classes.smallText}>Games Played</Typography>
            <Typography className={classes.smallText}>
              {matchup.visitingTeamCompletedGames}/
              {matchup.visitingTeamCompletedGames +
                matchup.visitingTeamRemainingGames}
            </Typography>
          </div>
          <LinearProgressBar
            progress={
              (matchup.visitingTeamCompletedGames /
                (matchup.visitingTeamCompletedGames +
                  matchup.visitingTeamRemainingGames)) *
              100
            }
            bgColor="#D2D2D2"
            color="#558B95"
            height={3}
          />
          <div className={classes.gamesPlayedDataUnder}>
            <img src={matchup.visitingTeam.logo} alt="Visiting team logo" />
            <div className={classes.gamesPlayedUnder}>
              <div>
                {getTeamName(matchup.visitingTeam.name)}
                <Typography className={classes.gpuRankText}>
                  {matchup.visitingTeam.summary}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <Typography className={`${classes.gpuNumber}`} style={gameColor()}>
          {matchup.visitingTeamWins}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(MobileMatchupCardStyles)(MobileMatchupCard);
