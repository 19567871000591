import { createStyles } from "@material-ui/core";
import adminTheme from "../../themes/admin/adminTheme";

const StatOptionsBarStyles = (theme) =>
  createStyles({
    optionSelect: {
      "& p": {
        padding: "6px 16px",
        fontSize: 12,
        cursor: "pointer",
      },
      marginBottom: 16,
      display: "flex",
      boxShadow: "0px 1px 3px #00000029",
      borderRadius: 12,
      overflow: "hidden",
    },
    opActive: {
      backgroundColor: "#FF9718",
      color: "#fff",
    },

    [adminTheme.breakpoints.down("xs")]: {
      optionSelect: {
        marginBottom: 0,
      },
    },
  });

export default StatOptionsBarStyles;
