import { IconButton, withStyles, WithStyles } from "@material-ui/core";
import React, { useRef, useState } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import CarouselStyles from "./Carousel.styles";

interface Props extends WithStyles<typeof CarouselStyles> {
  dragSpeed: number;
  data: any;
  itemWidth: number;
  itemSideOffsets: number;
  itemHeight: number;
}

const Carousel: React.FC<Props> = ({
  children,
  classes,
  dragSpeed,
  data,
  itemWidth,
  itemHeight,
  itemSideOffsets,
}) => {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState<number>(0);
  const [transLeftOffset, setTransLeftOffset] = useState<number>(0);
  const cRef = useRef<HTMLDivElement>(null);
  const { innerWidth } = window;

  // helper Function
  const giveMeIntValOf = (el: any) => {
    // extracting 20 from translateX(20px) and converting it to integer with parsInt
    return parseInt(el.replace("translateX(", "").replace("px)", ""), 10);
  };

  // mouse Down
  const handleMouseDown = (e: any) => {
    if (cRef.current) {
      const carousel = cRef.current;

      // this is due to the error that is been recived in console
      // this will make sure that 'e' is gonna passed to the callback function in setState
      // MORE INFO: https://reactjs.org/docs/events.html#event-pooling
      e.persist();

      carousel.classList.add("active");

      const _startX = e.pageX - carousel.offsetLeft;
      const _transLeftOffset = giveMeIntValOf(
        (carousel.firstChild as HTMLElement).style.transform
      );
      setIsDown(true);
      setStartX(_startX);
      setTransLeftOffset(_transLeftOffset);

      // handeling reset the transition
      if (carousel !== null && startX && transLeftOffset) {
        // const x = e.pageX - carousel.offsetLeft;
        // const walk = (x - startX) * dragSpeed;
        (carousel.firstChild as HTMLElement).style.cssText = `
                transform: translateX(${_transLeftOffset}px);
                transition: transform 0.0s ease-in-out;
              `;
      }
    }
  };

  // handle Snap To Sides
  const handleSnap = () => {
    if (cRef.current) {
      const carousel = cRef.current;

      // Resetting
      setIsDown(false);
      carousel.classList.remove("active");

      // handeling Threshold
      // (1) getting transValue
      const tempThresholdOffset = giveMeIntValOf(
        (carousel.firstChild as HTMLElement).style.transform
      );
      // (2) items width - 30(first & last item removed margins) - containerWidth(b/c of ending part)
      const end =
        data.length * (itemWidth + 2 * itemSideOffsets) -
        30 -
        carousel.offsetWidth;

      // (3) check if we passing from threshold ( handeling Snap To Sides )
      if (tempThresholdOffset < 0 || tempThresholdOffset > end) {
        setTransLeftOffset(tempThresholdOffset);
        setIsDown(false);
        (carousel.firstChild as HTMLElement).style.cssText = `
          transform: translateX(${tempThresholdOffset < 0 ? 0 : end}px);
          transition: transform 0.5s cubic-bezier(.25,.72,.51,.96);
        `;
      }
    }
  };

  // mouse Move
  const handleMouseMove = (e: any) => {
    if (cRef.current) {
      const carousel = cRef.current;

      if (!isDown) return;
      e.preventDefault();

      const x = e.pageX - carousel.offsetLeft;
      const walk = (x - startX) * dragSpeed;

      (carousel.firstChild as HTMLElement).style.transform = `translateX(${
        transLeftOffset + walk
      }px)`;
    }
  };

  // mouse Leave
  const handleMouseLeave = () => {
    handleSnap();
  };

  // mouse Up
  const handleMouseUp = () => {
    handleSnap();
  };

  // move left on click
  const moveLeft = () => {
    if (cRef.current) {
      const carousel = cRef.current;
      const _transLeftOffset = giveMeIntValOf(
        (carousel.firstChild as HTMLElement).style.transform
      );
      // setTransLeftOffset(transLeftOffset + 550);
      (carousel.firstChild as HTMLElement).style.cssText = `
                transform: translateX(${_transLeftOffset + 550}px);
                transition: 0.5s;
              `;
      handleSnap();
    }
  };

  // move right on click
  const moveRight = () => {
    if (cRef.current) {
      const carousel = cRef.current;
      const _transLeftOffset = giveMeIntValOf(
        (carousel.firstChild as HTMLElement).style.transform
      );
      // setTransLeftOffset(transLeftOffset - 550);
      (carousel.firstChild as HTMLElement).style.cssText = `
                  transform: translateX(${_transLeftOffset - 550}px);
                  transition: 0.5s;
                `;
      handleSnap();
    }
  };

  const cWrapperStyle = {
    width: `${data.length * (itemWidth + 2 * itemSideOffsets)}px`,
    height: `${itemHeight}px`,
  };

  return (
    <>
      <IconButton className={classes.sliderBtnLeft} onClick={moveLeft}>
        <ChevronLeftIcon />
      </IconButton>
      <div
        className={classes.carousel}
        style={{ width: `${innerWidth - 17}px` }}
        ref={cRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div
          className={classes.cWrapper}
          style={{
            ...cWrapperStyle,
            transform: "translateX(0px)",
          }}
        >
          {children}
        </div>
      </div>
      <IconButton className={classes.sliderBtnRight} onClick={moveRight}>
        <ChevronRightIcon />
      </IconButton>
    </>
  );
};

export default withStyles(CarouselStyles)(Carousel);
