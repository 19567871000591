import { Dispatch } from "redux";
import { fetchLeageStandings } from "../../../components/api/general/api";
import * as types from "../action-types/actionTypes";
import { TeamActions } from "./teamActionsInterface";
import { TypeTeam } from "../../../components/general/data-types/DataTypes";

export function fetchTeamsSuccess(data: TypeTeam[]): TeamActions {
  return { type: types.FETCH_TEAMS_SUCCESS, data };
}

export function loadTeamsData(id: string) {
  return function (dispatch: Dispatch) {
    return fetchLeageStandings(id)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          dispatch(fetchTeamsSuccess(data));
        }
      })
      .catch((error) => {
        console.log("fetch Teams error: ", error);
      });
  };
}
