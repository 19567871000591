import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  withStyles,
  WithStyles,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { connect } from "react-redux";
import * as playerActions from "../../redux/actions/playerActions/playerActions";
import PlayersTable from "../../components/players-table/PlayersTable";
import {
  noResponse,
  TypeFilterPosition,
  TypeFilters,
  TypeFrachise,
  TypeLeaguePlayer,
} from "../../components/general/data-types/DataTypes";
import LoadingCircle from "../../components/general/LoadingCircle/LoadingCircle";
import {
  fetchFranchises,
  fetchLeagueTeams,
  positionFilters,
} from "../../components/api/general/api";
import { isEmpty } from "lodash";
import { RootState } from "../../redux/reducers";
import AdminTheme from "../../themes/admin/adminTheme";
import MobileTable from "../../components/mobile-table/MobileTable";
import MainFiltersPage from "./main-filters-page/MainFiltersPage";
import Options from "./options/Options";
import { useSelector } from "react-redux";

import PlayerStyles from "./Players.styles";

interface Props extends WithStyles<typeof PlayerStyles> {
  fetchPlayers: (
    pageNumber: number,
    leagueId: string,
    filters?: any
  ) => Promise<void>;
  fetchPlayerNames: (id: string) => Promise<void>;
}

const Players: React.FC<Props> = ({
  classes,
  fetchPlayers,
  fetchPlayerNames,
}) => {
  const isMobile = useMediaQuery(AdminTheme.breakpoints.down("xs"));

  const [options, setOptions] = useState("Averages");
  const [filters, setFilters] = useState<TypeFilters>({
    name: null,
    status: "status:EQ:Available",
    fantasyTeam: "",
    health: "",
    franchise: "",
    position: "",
    gamesRemaining: "",
    date: null,
  });
  const [playersList, setPlayersList] = useState<TypeLeaguePlayer[]>([]);
  const [franchises, setFranchises] = useState<TypeFrachise[]>([]);
  const [teams, setTeams] = useState<any[]>([]);
  const [positions, setPositions] = useState<TypeFilterPosition[]>([]);
  const [startDay, setStartDay] = useState<Date>();
  const [endDay, setEndDay] = useState<Date>();
  const [playersFilterLoading, setPlayersFilterLoading] = useState(false);
  const currentLeageIdFromLS = localStorage.getItem("selectedOverview") || "";

  const {
    players,
    players: { loading: playersLoading },
    overviews: { selectedOverview },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (selectedOverview) {
      const earlyFilter = {
        status: "status:EQ:Available",
      };
      if (players.content !== noResponse) {
        if (
          players.content.length === 0 ||
          players.currentLeague !== currentLeageIdFromLS
        ) {
          setPlayersFilterLoading(true);
          fetchPlayers(0, currentLeageIdFromLS, earlyFilter)
            .then(() => setPlayersFilterLoading(false))
            .catch((error) => console.log("Loading data failed " + error));
        } else {
          setPlayersList(players.content);
        }
      } else {
        setPlayersList(players.content);
      }
    }
  }, [
    fetchPlayers,
    players.content,
    selectedOverview,
    players.currentLeague,
    currentLeageIdFromLS,
  ]);

  useEffect(() => {
    if (selectedOverview && !isEmpty(players)) {
      if (players.playerNames.length === 0) {
        fetchPlayerNames(currentLeageIdFromLS).catch((error) =>
          console.log("Loading data failed " + error)
        );
      }
    }
  }, [fetchPlayerNames, selectedOverview, players, currentLeageIdFromLS]);

  useEffect(() => {
    if (positions.length === 0 && selectedOverview) {
      positionFilters(currentLeageIdFromLS)
        .then((res) => res.json())
        .then((val) => setPositions(val));
    }
  }, [positions.length, selectedOverview, currentLeageIdFromLS]);

  useEffect(() => {
    if (teams.length === 0 && selectedOverview) {
      fetchLeagueTeams(currentLeageIdFromLS)
        .then((res) => res.json())
        .then((val) => setTeams(val));
    }
  }, [teams.length, selectedOverview, currentLeageIdFromLS]);

  useEffect(() => {
    if (selectedOverview && selectedOverview.matchup) {
      setStartDay(new Date(selectedOverview.matchup.weekStart));
      setEndDay(new Date(selectedOverview.matchup.weekEnd));
    }
  }, [selectedOverview]);

  useEffect(() => {
    if (franchises.length === 0) {
      fetchFranchises()
        .then((res) => res.json())
        .then((val) => setFranchises(val));
    }
  }, [franchises.length]);

  const getPlayers = (
    pageNmber: number,
    filters: any,
    withFilter?: boolean
  ) => {
    withFilter && setPlayersFilterLoading(true);
    currentLeageIdFromLS &&
      fetchPlayers(pageNmber, currentLeageIdFromLS, filters)
        .then(() => withFilter && setPlayersFilterLoading(false))
        .catch((error) => {
          console.log("Loading Players failed: ", error);
        });
  };

  const loadMorePlayers = () => {
    const requestFilters = {
      status: filters.status,
      fantasyTeam: filters.fantasyTeam,
      health: filters.health,
      franchise: filters.franchise,
      position: filters.position,
      gamesRemaining: filters.gamesRemaining,
      date: filters.date,
    };
    if (players.pageNumber <= players.totalPages) {
      getPlayers(players.pageNumber + 1, requestFilters);
    }
  };

  return (
    <Grid container style={{ flexDirection: "column" }}>
      <Options
        isMobile={isMobile}
        options={options}
        setOptions={setOptions}
        className={classes.options}
      />
      <MainFiltersPage
        isMobile={isMobile}
        setFilters={setFilters}
        getPlayers={getPlayers}
        startDay={startDay}
        setStartDay={setStartDay}
        selectedOverview={selectedOverview}
        players={players}
        teams={teams}
        filters={filters}
        franchises={franchises}
        positions={positions}
        endDay={endDay}
      />
      <Grid
        container
        id="scrollable-parent"
        className={classes.playersTableContainer}
      >
        {playersFilterLoading ? (
          <LoadingCircle />
        ) : selectedOverview && selectedOverview.matchup ? (
          isMobile ? (
            <MobileTable
              players={playersList}
              selectedOverview={selectedOverview}
              options={options}
              withNoSelection
              startDay={startDay}
              endDay={endDay}
              weeksPosFromTop={127}
            />
          ) : (
            <PlayersTable
              players={playersList}
              selectedOverview={selectedOverview}
              tableWidth={1000}
              options={options}
              withNoSelection
              startDay={startDay}
              endDay={endDay}
            />
          )
        ) : (
          <Typography>Can't display data at the moment</Typography>
        )}
        <div style={{ width: "100%", margin: "20px 0 0" }}>
          {playersLoading && !playersFilterLoading && <LoadingCircle />}
        </div>
        {playersList.length >= 20 && (
          <Button
            variant="contained"
            size="large"
            disableElevation
            color="primary"
            className={classes.loadMoreBtn}
            onClick={loadMorePlayers}
          >
            Load more
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  fetchPlayers: playerActions.loadPlayers,
  fetchPlayerNames: playerActions.loadPlayerNames,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(PlayerStyles)(Players));
