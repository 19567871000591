import React, { useState } from "react";
import { Grid, IconButton, useMediaQuery, Slide } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import {
  addNewLeague,
  fetchYahooUserLeagues,
} from "../../components/api/general/api";
import SelectLeagueTable from "../../components/general/tables/select-league-table/SelectLeagueTable";
import DashboardLeagueCard from "./cards/DashboardLeagueCard";
import {
  TypeLogedUser,
  TypeOverview,
} from "../../components/general/data-types/DataTypes";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RootState } from "../../redux/reducers";
import AdminTheme from "../../themes/admin/adminTheme";
import AddLeaguePage from "./add-league-page/AddLeaguePage";
import AddLeagueHeader from "./add-leage-header/AddLeagueHeader";
import { getAnalytics, logEvent } from "firebase/analytics";
import ConnectToYahooPage from "./connect-to-yahoo/ConnectToYahooPage";
import { useSelector } from "react-redux";

import DashboardStyles from "./Dashboard.styles";

interface Props extends WithStyles<typeof DashboardStyles> {
  userData: TypeLogedUser | undefined;
}

const Dashboard: React.FC<Props> = ({ classes, userData }) => {
  const analytics = getAnalytics();

  const [yahooOverviews, setYahooOverviews] = useState<TypeOverview[]>([]);
  const [checkedLeague, setCheckedLeagues] = useState("");
  const [openAddLeaguePopup, setOpenAddLeaguePopup] = useState(false);
  const [stopLoading, setStopLoading] = useState(true);
  const isMobile = useMediaQuery(AdminTheme.breakpoints.down("xs"));

  const {
    overviews: { loading: overviewLoading, data: overviews },
  } = useSelector((state: RootState) => state);

  console.log({ overviewLoading });

  const connectToNewLeague = () => {
    setOpenAddLeaguePopup(true);
    fetchYahooUserLeagues()
      .then((ovs) => {
        ovs.length === 0 && setStopLoading(false);
        setYahooOverviews(ovs);
      })
      .catch((err) => {
        console.log("Fetching yahoo overviews failed: ", err);
        setStopLoading(false);
      });
  };

  const addLeague = () => {
    addNewLeague(checkedLeague);
    setOpenAddLeaguePopup(false);
    logEvent(analytics, "add_new_yahoo_league");
  };

  const getLeagueCards = () => {
    if (overviews && overviews.length > 0) {
      return (
        <>
          <AddLeagueHeader connectToNewLeague={connectToNewLeague} />
          {overviews.map((item, index) => (
            <DashboardLeagueCard overview={item} key={index} />
          ))}
        </>
      );
    } else {
      if (userData && !userData.hasYahooAccount) {
        return <ConnectToYahooPage />;
      } else {
        return <AddLeaguePage connectToNewLeague={connectToNewLeague} />;
      }
    }
  };

  return (
    <>
      <Slide direction="up" in={openAddLeaguePopup} mountOnEnter unmountOnExit>
        <div className={classes.selectLeaguesContainer}>
          <SelectLeagueTable
            yahooOverviews={yahooOverviews}
            onLeagueSelect={setCheckedLeagues}
            checkedLeague={checkedLeague}
            stopLoading={stopLoading}
            closePopup={() => setOpenAddLeaguePopup(false)}
            addLeague={addLeague}
          />
        </div>
      </Slide>

      {overviewLoading && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 999,
            background: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <CircularProgress style={{ color: "#FF4133" }} />
        </div>
      )}
      <main className={classes.content}>
        <Grid container className={classes.conatiner}>
          {getLeagueCards()}
        </Grid>
      </main>
      {isMobile && (
        <IconButton
          onClick={connectToNewLeague}
          className={classes.mobileAddIcon}
        >
          <AddIcon />
        </IconButton>
      )}
    </>
  );
};

export default withStyles(DashboardStyles)(Dashboard);
