import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useMediaQuery, withStyles, WithStyles } from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";
import { TypeTeam } from "../../../../components/general/data-types/DataTypes";
import { useNavigate } from "react-router-dom";
import AdminTheme from "../../../../themes/admin/adminTheme";
import { RootState } from "../../../../redux/reducers";
import { useSelector } from "react-redux";
import { trimText } from "../../../../components/general/utils/Utils";

import UserStandingsTableStyles from "./UserStandingsTable.styles";

interface Props extends WithStyles<typeof UserStandingsTableStyles> {
  leagues: TypeTeam[];
  standingsRef: (node: HTMLElement | null) => void;
}

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const UserStandingsTable: React.FC<Props> = ({
  classes,
  leagues,
  standingsRef,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(AdminTheme.breakpoints.down("xs"));

  const {
    overviews: { selectedOverview },
  } = useSelector((state: RootState) => state);

  const onTableRowClick = (id: string) => {
    if (selectedOverview?.team.id === id) {
      navigate(`/my-team/${id}`);
    } else {
      navigate(`/team/${id}`);
    }
  };

  return (
    <>
      <TableContainer ref={standingsRef}>
        <Table
          size="small"
          aria-label="simple table"
          className={classes.xsTablePadding}
        >
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell>Rank</TableCell>
              <TableCell align="center">W-L-T</TableCell>
              <TableCell align="center">Percentage</TableCell>
              {!isMobile && <TableCell align="center">Games Behind</TableCell>}
              {!isMobile && <TableCell align="center">Waiver</TableCell>}
              {!isMobile && <TableCell align="center">Moves</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {leagues.map((league, index) => (
              <TableRow
                onClick={() => onTableRowClick(league.id)}
                key={`${league.name}-${index}`}
                hover
                style={{ cursor: "pointer" }}
              >
                <TableCell
                  className={classes.tableLogo}
                  component="th"
                  scope="row"
                >
                  <span>{league.rank}</span>
                  <img src={league.logo} alt="logo" />
                  <p>{trimText(league.name, 17)}</p>
                </TableCell>
                <TableCell align="center">
                  {league.wins} - {league.losses} - {league.ties}
                </TableCell>
                <TableCell align="center">{league.percentage}%</TableCell>
                {!isMobile && (
                  <TableCell align="center">{league.gamesBack}</TableCell>
                )}
                {!isMobile && (
                  <TableCell align="center">{league.waiverPriority}</TableCell>
                )}
                {!isMobile && (
                  <TableCell align="center">{league.nrMoves}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStyles(UserStandingsTableStyles)(UserStandingsTable);
