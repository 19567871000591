import React from "react";
import { Grid } from "@material-ui/core";
import { TypeLeague } from "../../../../../components/general/data-types/DataTypes";
import ThreeLineSkeleton from "../../skeletons/ThreeLineSkeleton/ThreeLineSkeleton";
import Week from "../../general-components/Week";

import styles from "./WeekBox.module.scss";

interface IWeekBoxProps {
  overviewLeague: TypeLeague;
}

const WeekBox: React.FunctionComponent<IWeekBoxProps> = ({
  overviewLeague,
}) => {
  if (overviewLeague == null) {
    return (
      <Grid
        item
        xs={12}
        sm={4}
        className={styles.shadowContainer}
        style={{ padding: 15 }}
      >
        <ThreeLineSkeleton />
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={12}
      sm={4}
      className={styles.shadowContainer}
      style={{ padding: 15 }}
    >
      <Week league={overviewLeague} />
    </Grid>
  );
};

export default WeekBox;
