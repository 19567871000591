import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { trimText } from "../../../../../components/general/utils/Utils";
import NextMatchupSkeleton from "../../skeletons/NextMatchupSkeleton/NextMatchupSkeleton";

import styles from "./NextMatchupBox.module.scss";

interface INextMatchupBoxProps {}

const NextMatchupBox: React.FunctionComponent<INextMatchupBoxProps> = () => {
  const {
    matchups: { nextMatchup },
  } = useSelector((state: RootState) => state);

  if (nextMatchup == null) {
    return (
      <Grid item style={{ paddingLeft: 16 }} xs={8}>
        <Grid className={styles.shadowContainer} style={{ height: "100%" }}>
          <NextMatchupSkeleton />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item style={{ paddingLeft: 16 }} xs={8}>
      <Grid className={styles.shadowContainer} style={{ height: "100%" }}>
        <Typography className={styles.nmTitle}>Next Matchup</Typography>
        <Grid container>
          <Grid item xs={6} className={`${styles.nmBox} ${styles.mnBoxFirst}`}>
            <img src={nextMatchup!.homeTeam.logo} alt="Home team logo" />
            <Typography>{trimText(nextMatchup!.homeTeam.name, 20)}</Typography>
          </Grid>
          <Grid item xs={6} className={styles.nmBox}>
            <img src={nextMatchup!.visitingTeam.logo} alt="" />
            <Typography>
              {trimText(nextMatchup!.visitingTeam.name, 20)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NextMatchupBox;
