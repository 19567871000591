import { createStyles } from "@material-ui/core";
import adminTheme from "../../../themes/admin/adminTheme";

const ScoreStyles = (theme) =>
  createStyles({
    matchupTableContainer: {
      flexDirection: "column",
      overflow: "hidden",
      paddingBottom: 20,
    },
    mTopContainer: {
      maxWidth: "946px",
      width: "100%",
      display: "flex",
      margin: "0 auto 50px",
    },
    healthContainer: {
      "& >div": {
        "& >div": {
          "& >div": {
            display: "flex",
          },
          display: "flex",
          justifyContent: "space-around",
        },
        "& >p": {
          marginBottom: 21,
        },
        display: "flex",
        flexDirection: "column",
        width: "100%",
        textAlign: "center",
        padding: 10,
      },
      paddingRight: 17,
      display: "flex",
      alignItems: "center",
    },
    shadowContainer: {
      boxShadow: "0px 1px 3px #00000029",
      borderRadius: 12,
      padding: 17,
      background: "#fff",
    },
    hoverIcon: {
      marginRight: 5,
      color: "#558B95",
    },
    iconQuestion: {
      color: "#FF9718",
      marginRight: 10,
    },
    iconCheck: {
      color: "#188038",
      marginRight: 10,
    },
    playingToday: {
      "& >div": {
        "& >div": {
          "& >div": {
            display: "flex",
          },
          display: "flex",
          justifyContent: "space-around",
        },
        "& >p": {
          marginBottom: 21,
        },
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      },
      display: "flex",
      alignItems: "center",
    },
    iconPeople: {
      color: "#49495C",
      marginRight: 10,
    },
    mBotContainer: {
      maxWidth: "946px",
      width: "100%",
      display: "flex",
      margin: "0 auto",
      flexDirection: "column",
      padding: "34px 64px",
      boxSizing: "border-box",
      boxShadow: "0px 1px 3px #00000029",
      borderRadius: 12,
      position: "relative",
    },
    imagesHome: {
      "& img": {
        maxWidth: 300,
        width: "100%",
        height: "100%",
      },
      display: "flex",
      position: "absolute",
      top: 0,
      left: "-52%",
    },
    imagesOpponent: {
      "& img": {
        maxWidth: 300,
        width: "100%",
        height: "100%",
      },
      display: "flex",
      position: "absolute",
      top: 0,
      right: "-52%",
    },
    motionSliderContainer: {
      width: "100vw",
      height: "100vh",
      position: "relative",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      marginTop: 25,
      paddingBottom: 130,
    },
    statsContainer: {
      marginBottom: 30,
    },
    iconHeal: {
      color: "#FF4133",
    },

    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      mTopContainer: {
        display: "none",
        // order: 2,
      },
      mBotContainer: {
        marginBottom: 40,
        padding: 0,
        boxShadow: "none",
      },
    },
  });

export default ScoreStyles;
