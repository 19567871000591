import React from "react";
import { Button, Typography, withStyles, WithStyles } from "@material-ui/core";
import { TypeOverview } from "../../data-types/DataTypes";
import LeagueCard from "./LeagueCard/LeagueCard";
import CloseIcon from "@material-ui/icons/Close";
import LoadingCircle from "../../LoadingCircle/LoadingCircle";

import SelectLeagueTableStyles from "./SelectLeagueTable.styles";

interface Props extends WithStyles<typeof SelectLeagueTableStyles> {
  yahooOverviews: TypeOverview[];
  onLeagueSelect: React.Dispatch<React.SetStateAction<string>>;
  checkedLeague: string;
  stopLoading: boolean;
  closePopup: () => void;
  addLeague: () => void;
}

const SelectLeagueTable: React.FC<Props> = React.forwardRef(
  (
    {
      classes,
      yahooOverviews,
      onLeagueSelect,
      checkedLeague,
      stopLoading,
      closePopup,
      addLeague,
    },
    _
  ) => {
    return (
      <>
        <Typography className={classes.title}>
          My H2H Leagues and Teams
        </Typography>
        <CloseIcon className={classes.closeIcon} onClick={closePopup} />
        {yahooOverviews.length > 0 ? (
          <>
            <div className={classes.itemsContainer}>
              {yahooOverviews.map((yahooOverview, index) => (
                <LeagueCard
                  yahooOverview={yahooOverview}
                  key={index}
                  onLeagueSelect={onLeagueSelect}
                  checkedLeague={checkedLeague}
                />
              ))}
            </div>
            <Button
              onClick={addLeague}
              color="primary"
              variant="contained"
              className={classes.popBtnAdd}
            >
              Add League
            </Button>
          </>
        ) : (
          <>
            {stopLoading ? (
              <LoadingCircle />
            ) : (
              <Typography style={{ textAlign: "center" }}>
                You have no leagues
              </Typography>
            )}
          </>
        )}
      </>
    );
  }
);

export default withStyles(SelectLeagueTableStyles)(SelectLeagueTable);
