import React from "react";
import { Button, withStyles, WithStyles } from "@material-ui/core";
import { ReactComponent as GoolgeIcon } from "../../static/logo/GoogleIcon.svg";

import LoginStyles from "./Login.styles";

interface Props extends WithStyles<typeof LoginStyles> {
  handleGoogleLogin: () => Promise<void>;
}

const GoogleLogin: React.FC<Props> = ({ handleGoogleLogin, classes }) => {
  return (
    <Button
      variant="outlined"
      size="large"
      disableElevation
      className={classes.googleLogin}
      onClick={handleGoogleLogin}
      startIcon={<GoolgeIcon />}
    >
      Continue with Google
    </Button>
  );
};

export default withStyles(LoginStyles)(GoogleLogin);
