import {
  // IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  TypeOverview,
  TypeTeamPlayer,
  TypeLeaguePlayer,
} from "../general/data-types/DataTypes";
import Row from "./Row";
// import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
// import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import PlayersTableStyles from "./PlayersTable.styles";
import StatsRow from "./StatsRow";

interface Props extends WithStyles<typeof PlayersTableStyles> {
  players: TypeLeaguePlayer[] | TypeTeamPlayer[];
  selectedOverview: TypeOverview;
  tableWidth: number;
  options?: string;
  withNoSelection?: boolean;
  startDay?: Date | undefined;
  endDay?: Date | undefined;
  swappable?: boolean;
}

const PlayersTable: React.FC<Props> = React.memo(
  ({
    classes,
    players,
    selectedOverview,
    tableWidth,
    options,
    withNoSelection,
    startDay,
    endDay,
    swappable,
  }) => {
    const [weekDays, setWeekDays] = useState<string[]>([]);
    const [selectedPlayers, setSelectedPlayers] = useState<
      TypeLeaguePlayer[] | TypeTeamPlayer[]
    >([]);
    console.log("players talbe rendered");

    const statsTitle = "League categories";
    let statTableRef: any = useRef(null);

    const getSelectedDay = () => {
      if (
        moment(selectedOverview.league.currentDate).diff(
          selectedOverview.matchup.weekEnd,
          "days"
        ) > 0
      ) {
        return selectedOverview.matchup.weekEnd;
      } else {
        return selectedOverview.league.currentDate;
      }
    };
    const [selectedDay, setSelectedDay] = useState(getSelectedDay());

    useEffect(() => {
      let week: string[] = [];
      if (startDay) {
        const nrOfDays = moment(endDay).diff(moment(startDay), "days");
        for (let i = 0; i <= nrOfDays; i++) {
          let first = moment(startDay).add(i, "days");
          let day = first.toDate().toISOString().slice(0, 10);
          week = [...week, day];
        }
        setWeekDays(week);
      }
    }, [startDay, endDay]);

    useEffect(() => {
      if (players.length > 0 && players[0].type === "team") {
        setSelectedPlayers(
          (players as TypeTeamPlayer[]).filter(
            (item) => item.date === selectedDay
          )
        );
      } else {
        setSelectedPlayers(players);
      }
    }, [players, selectedDay, withNoSelection]);

    const selectWeekDay = (item: string) => {
      setSelectedDay(item);
    };

    // const moveTable = (dirrection: string) => {
    //   if (dirrection === "left") {
    //     statTableRef.current.scrollLeft = -1500;
    //     setBtnColor(false);
    //     setStatsTitle("League categories");
    //   } else {
    //     setBtnColor(true);
    //     setStatsTitle("Research categories");
    //     statTableRef.current.scrollLeft = 1500;
    //   }
    // };

    // const arr = [1, 2, 3, 4, 5, 6];

    return (
      <>
        {players.length > 0 ? (
          <div className={classes.tablesContainer}>
            <TableContainer
              className={classes.generalTable}
              style={{ width: tableWidth }}
            >
              <Table size="small" stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="left"
                      style={{ paddingLeft: 0, width: 220 }}
                    >
                      Player
                    </TableCell>
                    {players[0].type === "league" && (
                      <TableCell align="center">Status</TableCell>
                    )}
                    {weekDays.map((item, index) => (
                      <TableCell
                        align="center"
                        className={`${classes.lessPadding} ${
                          classes.weekDays
                        } ${selectedDay === item ? classes.selectedDay : ""}`}
                        key={`thr-${index}`}
                        onClick={() => selectWeekDay(item)}
                      >
                        {moment(item).format("ddd MMM DD").replace(" ", "\n")}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Should I put map here or inside Row?? */}
                  {(
                    selectedPlayers as Array<TypeLeaguePlayer | TypeTeamPlayer>
                  ).map((player, index) => (
                    <Row
                      key={index}
                      player={player}
                      index={index}
                      weekDays={weekDays}
                      selectedDay={selectedDay}
                      selectedPlayersLength={selectedPlayers.length}
                      selectedOverview={selectedOverview}
                      swappable={swappable}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={classes.statsTableHeader}>
              {/* <IconButton onClick={() => moveTable("left")}>
                <KeyboardArrowLeftIcon
                  className={
                    btnColor
                      ? `${classes.withColor} ${classes.scrollLeft}`
                      : classes.scrollLeft
                  }
                />
              </IconButton> */}
              <Typography style={{ margin: "0 auto" }}>{statsTitle}</Typography>
              {/* <IconButton onClick={() => moveTable("right")}>
                <KeyboardArrowRightIcon
                  className={
                    btnColor
                      ? classes.scrollLeft
                      : `${classes.withColor} ${classes.scrollLeft}`
                  }
                />
              </IconButton> */}
            </div>
            <TableContainer ref={statTableRef} className={classes.statsTable}>
              <Table size="small" stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    {selectedOverview.league.categoryList.map((item, index) => (
                      <TableCell
                        align="center"
                        className={`${classes.lessPadding} ${classes.fixedCategory}`}
                        key={`thrr-${index}`}
                      >
                        {item}
                      </TableCell>
                    ))}
                    {/* {arr.map((_, index) => (
                      <TableCell
                        align="center"
                        className={`${classes.lessPadding} ${classes.fixedCategory}`}
                        key={`thrr-${index}`}
                      >
                        {"plus"}
                      </TableCell>
                    ))} */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(
                    selectedPlayers as Array<TypeLeaguePlayer | TypeTeamPlayer>
                  ).map((player, index) => (
                    <StatsRow
                      key={`tstr-${index}`}
                      player={player}
                      selectedOverview={selectedOverview}
                      withNoSelection={withNoSelection}
                      options={options}
                      selectedDay={selectedDay}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <Typography>No records found.</Typography>
        )}
      </>
    );
  }
);

export default withStyles(PlayersTableStyles)(PlayersTable);
