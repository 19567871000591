import { Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { loadTransactions } from "../../../../redux/actions/transactionActions/transactionActions";
import Carousel from "./carousel/Carousel";
import SliderCard from "./slider-card/SliderCard";
import { RootState } from "../../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";

import TransactionStyles from "./Transactions.styles";

interface Props extends WithStyles<typeof TransactionStyles> {}

const Transactions: React.FC<Props> = ({ classes }) => {
  const { transactions } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const setting = {
    dragSpeed: 1.25,
    itemWidth: 250,
    itemHeight: 200,
    itemSideOffsets: 15,
  };
  const itemStyle = {
    width: `${setting.itemWidth}px`,
    height: `${setting.itemHeight}px`,
    margin: `0px ${setting.itemSideOffsets}px`,
  };

  const currentLeageIdFromLS = localStorage.getItem("selectedOverview") || "";

  useEffect(() => {
    if (!transactions.length) {
      dispatch(loadTransactions(currentLeageIdFromLS));
    }
  }, [dispatch, transactions, currentLeageIdFromLS]);

  return (
    <>
      {transactions.length && (
        <Grid style={{ width: "100%" }}>
          <Typography className={classes.transacTitle}>Transactions</Typography>
          <div className={classes.container}>
            <Carousel
              data={transactions}
              dragSpeed={1.25}
              itemWidth={250}
              itemHeight={200}
              itemSideOffsets={15}
            >
              {transactions.map((value, i) => (
                <div key={i} className={classes.item} style={{ ...itemStyle }}>
                  <SliderCard item={value} />
                </div>
              ))}
            </Carousel>
          </div>
        </Grid>
      )}
    </>
  );
};

export default withStyles(TransactionStyles)(Transactions);
