import { createStyles } from "@material-ui/core";
import adminTheme from "../../../themes/admin/adminTheme";

const DashboardLeagueCardStyles = () =>
  createStyles({
    logoAndName: {
      "& svg": {
        marginRight: 10,
      },
      "& img": {
        maxHeight: 45,
        marginRight: 10,
      },
      display: "flex",
      alignItems: "center",
      marginRight: -27,
      cursor: "pointer",
    },
    logoName: {
      fontSize: 22,
      fontWeight: 600,
    },
    logoSummary: {
      fontSize: 14,
    },
    leagueCardContainer: {
      boxShadow: "0px 3px 6px #00000029",
      marginBottom: 70,
      borderRadius: 15,
      color: "#49495C",
    },
    topData: {
      "&::after": {
        content: "close-quote",
        background: "#cecece",
        width: 1,
        position: "absolute",
        top: 7,
        right: 0,
        height: 22,
      },
      "&:last-child": {
        "&::after": {
          content: "none",
        },
      },
      padding: "0 10px",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      position: "relative",
      minWidth: 80,
    },
    topDataContainer: {
      display: "flex",
      alignItems: "center",
    },
    tpDataLabel: {
      fontSize: 10,
    },
    tpDataVal: {
      fontSize: 18,
      fontWeight: 600,
    },
    leagueCardHead: {
      borderBottom: "1px solid #70707040",
      padding: "12px 0 12px 25px",
    },
    gpuText: {
      fontFamily: "Poppins",
      fontSize: 18,
      color: "#191847",
      fontWeight: 500,
    },
    gpuRankText: {
      fontSize: 14,
    },
    updateTime: {
      "& svg": {
        maxWidth: 25,
      },
      "& p": {
        fontFamily: "'Poppins'",
        fontSize: 12,
        lineHeight: "30px",
        marginLeft: 5,
      },
      display: "flex",
      justifyContent: "center",
      padding: 10,
    },
    gpuNumber: {
      fontSize: 24,
      fontWeight: 600,
    },
    firstCardContainer: {
      "&::after": {
        top: 55,
        right: -16,
        width: 30,
        height: 30,
        content: "'vs'",
        position: "absolute",
        background: "#F5F6F7",
        textAlign: "center",
        borderRadius: "50%",
        fontSize: 14,
        fontWeight: 600,
        lineHeight: "28px",
        fontFamily: "Poppins",
      },
      position: "relative",
      padding: "0 30px 0 20px",
      minHeight: 140,
    },

    gpText: {
      fontSize: 10,
    },
    gpSecondText: {
      fontSize: 12,
      fontWeight: 600,
    },
    topDataOtions: {
      "& svg": {
        cursor: "pointer",
      },
      alignItems: "center",
      justifyContent: "flex-end",
      display: "flex",
      marginLeft: 27,
    },
    iconHeal: {
      color: "#FF4133",
      opacity: "0.5",
      marginRight: 10,
    },
    iconQuestion: {
      color: "#FF9718",
      opacity: "0.5",
      marginRight: 10,
    },
    iconCheck: {
      color: "#188038",
      opacity: "0.5",
      marginRight: 10,
    },
    gamesPlayed: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
    },
    secondGamesPlayed: {
      "& $gpuNumber": {
        paddingRight: 20,
      },
      "& $gamesPlayedData": {
        paddingRight: 20,
        paddingLeft: 0,
      },
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
    },
    gamesPlayedData: {
      width: "100%",
      paddingRight: 20,
      paddingLeft: 20,
    },
    gamesPlayedText: {
      display: "flex",
      justifyContent: "space-between",
    },
    gamesPlayedDataUnder: {
      "& img": {
        borderRadius: "50%",
        maxHeight: 44,
        marginLeft: 8,
        marginTop: 2,
      },
      display: "flex",
      justifyContent: "space-between",
    },
    secondCardContainer: {
      "& $gamesPlayed": {
        flexDirection: "row-reverse",
      },
      "& $gamesPlayedDataUnder": {
        "& img": {
          marginRight: 8,
          marginLeft: 0,
        },
      },
      flexDirection: "row-reverse",
      padding: "0 20px 0 30px",
      position: "relative",
      minHeight: 140,
    },
    gamesPlayedUnder: {
      "& >div": {
        display: "flex",
        flexDirection: "column",
        textAlign: "right",
      },
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    gamesSecondPlayedUnder: {
      "& >div": {
        display: "flex",
        flexDirection: "column",
      },
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
    },
    hoverIcon: {
      opacity: 0,
      transition: "0.5s",
      marginRight: 5,
      marginLeft: -24,
      color: "#558B95",
    },
    healthContainer: {
      "&:hover": {
        "& >div": {
          backgroundColor: "#F5F6F7",
        },
        "& $hoverIcon": {
          marginLeft: 10,
          opacity: 1,
        },
      },
      "& >div": {
        "& >div": {
          "& >div": {
            display: "flex",
          },
          display: "flex",
          justifyContent: "space-around",
        },
        "& >p": {
          marginBottom: 21,
        },
        display: "flex",
        flexDirection: "column",
        width: "100%",
        textAlign: "center",
        padding: 10,
        height: 89,
        boxSizing: "border-box",
      },
      display: "flex",
      alignItems: "center",
    },
    // healthBox:{
    //   height: 89,
    //   boxSizing: 'border-box',
    // },
    playingToday: {
      "&:hover": {
        "& >div": {
          backgroundColor: "#F5F6F7",
        },
        "& $hoverIcon": {
          marginLeft: 10,
          opacity: 1,
        },
      },
      "& >div": {
        "& >div": {
          "& >div": {
            display: "flex",
          },
          display: "flex",
          justifyContent: "space-around",
        },
        "& >p": {
          marginBottom: 21,
        },
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        height: 89,
        boxSizing: "border-box",
        padding: 10,
      },
      display: "flex",
      alignItems: "center",
    },
    iconPeople: {
      opacity: "0.5",
      color: "#49495C",
      marginRight: 10,
      fontSize: 30,
    },
    delDialog: {
      "& h6": {
        fontWeight: 500,
        fontFamily: "'Roboto'",
      },
      "& button": {
        fontWeight: 500,
        fontFamily: "'Roboto'",
      },
      "& p": {
        fontSize: 14,
      },
    },
    optionsMenu: {
      "& svg": {
        marginRight: 10,
      },
    },
    smallHIcon: {
      "& svg": {
        fontSize: 12,
        marginLeft: 10,
        color: "#FF4133",
        opacity: 0.7,
        marginRight: 5,
        position: "absolute",
        top: 1,
        left: -5,
      },
      fontSize: 10,
      position: "relative",
      paddingLeft: 21,
      boxSizing: "border-box",
    },

    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      logoAndName: {
        "& img": {
          maxHeight: 35,
        },
      },
      topDataContainer: {
        display: "none",
      },
      logoName: {
        fontSize: 12,
        marginBottom: 5,
      },
      logoSummary: {
        fontSize: 8,
      },
      healthContainer: {
        display: "none",
      },
      playingToday: {
        display: "none",
      },
      gamesPlayedData: {
        paddingLeft: 0,
        maxWidth: 160,
      },
      gpuText: {
        fontSize: 12,
      },
      leagueCardHead: {
        paddingLeft: 15,
      },
      topData: {
        "&::after": {
          content: "none",
        },
        minWidth: 35,
      },
      tpDataLabel: {
        fontSize: 8,
      },
      tpDataVal: {
        fontSize: 16,
        fontWeight: "bold",
      },
      gpSecondText: {
        fontSize: 10,
        order: 2,
      },
      gamesPlayedUnder: {
        "& >div": {
          textAlign: "left",
        },
        order: 2,
        justifyContent: "flex-start",
        paddingTop: 5,
      },
      gamesPlayedDataUnder: {
        "& img": {
          marginLeft: 0,
          marginRight: 8,
          maxHeight: 30,
          marginTop: 5,
        },
      },
      gpuRankText: {
        fontSize: 8,
      },
      firstCardContainer: {
        "&::after": {
          content: "none",
        },
        minHeight: 80,
        padding: "0 16px 0 16px",
      },
      secondCardContainer: {
        padding: "0 16px 0 16px",
        minHeight: 80,
      },
      secondGamesPlayed: {
        "& $gpuNumber": {
          order: 2,
          paddingRight: 0,
        },
      },
      gamesSecondPlayedUnder: {
        paddingTop: 5,
      },
      updateTime: {
        "& svg": {
          maxWidth: 20,
        },
        "& p": {
          fontSize: 10,
        },
        padding: 0,
        borderTop: "1px solid #70707040",
      },
      leagueCardContainer: {
        marginBottom: 20,
      },
      gpText: {
        fontSize: 8,
      },
    },
  });

export default DashboardLeagueCardStyles;
