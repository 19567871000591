import { createStyles } from "@material-ui/core";
import adminTheme from "../../../../../themes/admin/adminTheme";

const LeagueCardStyles = (theme) =>
  createStyles({
    container: {
      "&.active": { background: "#f1f1f1", border: "1px solid #de8314" },
      "&:hover": {
        background: "#f1f1f1",
      },
      display: "flex",
      flexDirection: "column",
      marginBottom: 15,
      cursor: "pointer",
      padding: 10,
      borderRadius: 3,
    },
    top: {
      "& img": {
        width: 30,
        height: 30,
        marginRight: 15,
      },
      "& p": {
        color: "#49495C",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 15,
    },
    bot: {
      display: "flex",
      justifyContent: "space-between",
    },
    cardLeft: {
      "& img": {
        width: 40,
        height: 40,
        marginRight: 10,
      },
      "& p": {
        color: "#49495C",
      },
      display: "flex",
    },
    listTopTxt: {
      fontSize: 8,
    },
    summary: { fontSize: 10 },
    listItem: {
      "&::after": {
        content: "close-quote",
        background: "#49495C",
        width: 1,
        position: "absolute",
        top: 7,
        right: 0,
        height: 22,
      },
      "&:last-child": {
        "&::after": {
          content: "none",
        },
      },
      padding: "0 15px",
      textAlign: "center",
      position: "relative",
    },
    /////////////// - Media Queries Down - ///////////////

    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      cardLeft: {
        "& p": {
          fontSize: 12,
          fontWeight: 600,
        },
        "& img": {
          width: 30,
          height: 30,
          marginRight: 5,
        },
      },
      summary: {
        fontSize: "8px !important",
        fontWeight: "500 !important",
      },
      listBotTxt: {
        fontSize: 12,
        fontWeight: 600,
      },
      listItem: {
        "&::after": {
          background: "transparent",
        },
        padding: "0 10px",
      },
    },
  });

export default LeagueCardStyles;
