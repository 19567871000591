import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
  OAuthProvider,
  deleteUser,
} from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import app from "../firebase/firebase";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteLocalUser } from "../api/general/api";

const AuthContext = createContext({} as any);

export function useAuth() {
  return useContext(AuthContext);
}

interface Props {}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const analytics = getAnalytics();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const navigate = useNavigate();

  const firebaseSignUp = (email: string, password: string) => {
    const auth = getAuth(app);
    return createUserWithEmailAndPassword(auth, email, password).then(() =>
      sendEmailVerification(auth.currentUser!, {
        url: process.env.REACT_APP_EMAIL_REDIRECT_URL!,
      })
    );
  };

  const login = (email: string, password: string) => {
    const auth = getAuth(app);
    return signInWithEmailAndPassword(auth, email, password).then(
      (res: any) => {
        if (res.user.emailVerified) {
          navigate("/overview");
          logEvent(analytics, "login", { method: "Email/Password" });
        } else {
          toast.error("Please verify your email");
        }
      }
    );
  };

  const loginWithGoogle = (signUp?: (userObject: any) => Promise<Response>) => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    return signInWithPopup(auth, provider).then((response) => {
      if (signUp) {
        const payload = {
          email: response.user.email,
          name: response.user.displayName,
        };

        signUp(payload);
      }

      logEvent(analytics, "login", { method: "Google" });
    });
  };

  const loginWithYahoo = (signUp?: (userObject: any) => Promise<Response>) => {
    const auth = getAuth(app);
    const provider = new OAuthProvider("yahoo.com");

    return signInWithPopup(auth, provider).then((response) => {
      if (signUp) {
        const payload = {
          email: response.user.email,
          name: response.user.displayName,
        };

        signUp(payload);
      }

      logEvent(analytics, "login", { method: "Yahoo" });
    });
  };

  const logout = () => {
    const auth = getAuth(app);
    return signOut(auth);
  };

  const resetPassword = (email: string) => {
    const auth = getAuth(app);
    return sendPasswordResetEmail(auth, email);
  };

  const updateEmail = (email: string) => {
    return currentUser.updateEmail(email);
  };

  const deleteAccount = (id: string) => {
    const auth = getAuth(app);
    const user = auth.currentUser;
    return (
      user &&
      deleteUser(user)
        .then(() => {
          deleteLocalUser(id);
          Cookie.remove("tokenId", { path: "/" });
          navigate("/");
          logEvent(analytics, "delete_account");
        })
        .catch(() => {
          toast.error("Account could not be deleted. Please try again later");
        })
    );
  };

  const updatePassword = (password: string) => {
    return currentUser.updatePassword(password);
  };

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user: any) => {
      setCurrentUser(user);
      if (user && user.emailVerified) {
        Cookie.set("tokenId", user.accessToken, { path: "/" });
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    firebaseSignUp,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    loginWithGoogle,
    loginWithYahoo,
    deleteAccount,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
