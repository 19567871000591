import { createStyles } from "@material-ui/core";

const TeamStyles = (theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      color: "#49495C",
    },
    topMenuContainer: {
      borderBottom: "1px solid #ebebec",
      backgroundColor: "#f5f6f7",
      marginBottom: 50,
      position: "relative",
    },
    headLogoText: {
      display: "flex",
      padding: "16px 20px",
      position: "absolute",
      top: 0,
      left: 0,
    },
    topLogo: {
      maxWidth: 40,
      maxHeight: 40,
      marginRight: 10,
    },
    activeMenu: {
      borderBottom: "2px solid #2241F2",
    },
    hMenuTitle: {
      fontSize: 16,
      color: "#000000",
      fontWeight: 700,
    },
    hMenuSTitle: {
      fontSize: 12,
    },
    topTitle: {
      fontWeight: 700,
    },
    topSummary: {
      fontSize: 10,
      color: "#00000080",
    },
    headMenu: {
      "& >div": {
        textAlign: "center",
        margin: "0 15px",
      },
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
    },
    hMenuContainer: {
      "& >div": {
        padding: "15px 30px",
        cursor: "pointer",
      },
      display: "flex",
    },
  });

export default TeamStyles;
