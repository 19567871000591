import { createStyles } from "@material-ui/core";
import adminTheme from "../../themes/admin/adminTheme";

const DonateStyles = (theme) =>
  createStyles({
    container: {
      justifyContent: "center",
      paddingTop: 80,
    },
    donateTopContainer: {
      flexWrap: "unset",
      position: "relative",
    },
    profileTtle: {
      fontSize: 24,
      marginBottom: 20,
    },
    profileText: {
      fontWeight: 400,
      paddingRight: 80,
    },
    donateIllust: {
      maxWidth: 230,
      maxHeight: 230,
      position: "absolute",
      top: -32,
      right: 0,
    },
    lineTitle: {
      marginBottom: 20,
      color: "#212529",
      fontSize: 22,
    },
    itemBox: {
      background: "#fff",
      border: "1px solid #DADCE0",
      borderRadius: 15,
      padding: 20,
      marginBottom: 30,
    },
    priceBox: {
      "&.active": {
        borderColor: "#FF4133",
      },
      "& h3": {
        fontFamily: "Poppins",
        fontWeight: 500,
      },
      boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
      border: "2px solid transparent",
      cursor: "pointer",
      borderRadius: 3,
      width: 100,
      height: 60,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    priceBoxContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 40,
    },
    donateBtn: {
      fontWeight: "bold",
      width: 300,
      margin: "0 auto",
      display: "flex",
    },

    [adminTheme.breakpoints.down("xs")]: {
      container: {
        padding: "40px 10px 0",
      },
      profileText: {
        paddingRight: 130,
      },
      donateIllust: {
        maxWidth: 150,
        maxHeight: 150,
        top: 42,
      },
      priceBoxContainer: {
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-evenly",
      },
      priceBox: {
        "& h3": {
          fontSize: 18,
        },
        marginBottom: 20,
        width: 80,
        height: 40,
      },
    },
  });

export default DonateStyles;
