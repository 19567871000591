import { Dispatch } from "redux";
import {
  fetchTeamAverageStats,
  fetchTeamRooster,
  fetchTeamTotalStats,
  fetchTeamWinStats,
} from "../../../components/api/general/api";
import * as types from "../action-types/actionTypes";
import {
  TypeTeamPlayer,
  TypeTeamStat,
} from "../../../components/general/data-types/DataTypes";
import { TeamAction } from "./teamActionsInterface";
import { beginApiCall } from "../apiStatusAction/apiStatusAction";

export function fetchTeamRosterSuccess(data: TypeTeamPlayer[]): TeamAction {
  return { type: types.FETCH_TEAM_ROSTER_SUCCESS, data };
}

export function fetchTeamAvStatsSuccess(data: TypeTeamStat[][]): TeamAction {
  return { type: types.FETCH_TEAM_AV_STATS_SUCCESS, data };
}

export function fetchTeamTotalsSuccess(data: TypeTeamStat[][]): TeamAction {
  return { type: types.FETCH_TEAM_TOTALS_SUCCESS, data };
}

export function fetchTeamWinsSuccess(data: TypeTeamStat[][]): TeamAction {
  return { type: types.FETCH_TEAM_WINS_SUCCESS, data };
}

export function changeSelectedTeamSuccess(team: string): TeamAction {
  return { type: types.CHANGE_SELECTED_TEAM_SUCCESS, team };
}

export function fetchFailed(): TeamAction {
  return { type: types.FETCH_FAILED };
}

export function loadTeamRoster(id: string) {
  return function (dispatch: Dispatch) {
    dispatch(beginApiCall("BEGIN_API_CALL_LOAD_ROOSTER"));
    return fetchTeamRooster(id)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          dispatch(fetchTeamRosterSuccess(data));
        }
        dispatch(fetchFailed());
      })
      .catch(() => {
        dispatch(fetchFailed());
      });
  };
}

export function loadTeamAvStats(id: string) {
  return function (dispatch: Dispatch) {
    return fetchTeamAverageStats(id)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          dispatch(fetchTeamAvStatsSuccess(data));
        }
        dispatch(fetchFailed());
      })
      .catch(() => {
        dispatch(fetchFailed());
      });
  };
}

export function loadTeamTotals(id: string) {
  return function (dispatch: Dispatch) {
    return fetchTeamTotalStats(id)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          dispatch(fetchTeamTotalsSuccess(data));
        }
        dispatch(fetchFailed());
      })
      .catch(() => {
        dispatch(fetchFailed());
      });
  };
}

export function loadTeamWins(id: string) {
  return function (dispatch: Dispatch) {
    return fetchTeamWinStats(id)
      .then((res) => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, data]) => {
        if (statusCode === 200) {
          dispatch(fetchTeamWinsSuccess(data));
        }
        dispatch(fetchFailed());
      })
      .catch(() => {
        dispatch(fetchFailed());
      });
  };
}

export function changeSelectedTeam(team: string) {
  return function (dispatch: Dispatch) {
    return dispatch(changeSelectedTeamSuccess(team));
  };
}
