import { createStyles } from "@material-ui/core";
import adminTheme from "../../../themes/admin/adminTheme";

const DashboardStyles = (theme) =>
  createStyles({
    shadowContainer: {
      boxShadow: "0px 1px 3px #00000029",
      borderRadius: 12,
      background: "#fff",
      display: "flex",
      flexDirection: "column",
    },
    hrRank: {
      fontSize: 14,
      textAlign: "center",
    },
    hrBox: {
      "& >div": {
        display: "flex",
        "& svg": {
          fontSize: 25,
          margin: "13px 5px 0 0",
        },
        "& p": {
          fontSize: 34,
          fontWeight: 700,
        },
      },
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    wltChartContainer: {
      "& p": {
        color: "#7C828A",
        fontSize: 10,
        marginBottom: 4,
      },
      marginBottom: 5,
    },
    wltChart: {
      marginBottom: 13,
    },
    chartContainer: {
      height: 121,
      width: "100%",
    },
    secondChartContainer: {
      "& p": {
        position: "absolute",
        top: "41%",
        left: "calc(50% - 13px)",
        fontSize: 13,
        fontWeight: 600,
      },
      height: 70,
      padding: "15px 0px 15px 0px",
      position: "relative",
    },
    topStatsContainer: {
      marginBottom: 50,
      padding: "0 32px",
      height: 132,
      MAXheight: 132,
    },
    topStatFirst: {
      width: "40%",
    },
    topStatSecond: {
      width: "32%",
      padding: "0 50px",
    },
    topStatThird: {
      width: "28%",
    },
    amaStatsTitle: {
      textAlign: "center",
      fontSize: 14,
      marginBottom: 8,
    },
    amaStatsbot: {
      fontSize: 10,
    },
    amaStatsTitleTopB: {
      fontSize: 34,
      color: "#212529",
      fontWeight: 700,
    },
    amaStatsTitleTopG: {
      fontSize: 34,
      color: "#558B95",
      fontWeight: 700,
    },
    amaStatsTitleTopY: {
      fontSize: 34,
      color: "#FF9718",
      fontWeight: 700,
    },
    amaStatsTopContainer: {
      textAlign: "center",
    },
    cwStats: {
      borderTop: "1px solid #D6D9DC",
      marginTop: 15,
      paddingTop: 14,
      fontSize: 14,
      color: "#7C828A",
    },
    gpPercent: {
      fontSize: 22,
      fontWeight: 700,
      marginTop: -14,
      marginLeft: 18,
    },
    csText: {
      fontSize: 10,
      textAlign: "center",
    },
    nmTitle: {
      fontSize: 14,
      textAlign: "center",
      fontWeight: 500,
      margin: "17px 0 15px",
    },
    nmBox: {
      "& img": {
        width: 40,
        height: 40,
        borderRadius: "50%",
        margin: "0 auto",
        display: "block",
        marginBottom: 8,
      },
      "& p": {
        fontSize: 10,
        textAlign: "center",
      },
    },
    mnBoxFirst: {
      "&::after": {
        top: 16,
        right: -16,
        width: 24,
        height: 24,
        content: "'vs'",
        position: "absolute",
        background: "#F5F6F7",
        textAlign: "center",
        borderRadius: "50%",
        fontSize: 12,
        fontWeight: 600,
        lineHeight: "24px",
        fontFamily: "Poppins",
      },
      position: "relative",
    },
    tablesContainer: {
      padding: "0 32px",
      marginBottom: 50,
    },
    noMatchupText: {
      fontSize: 14,
      textAlign: "center",
      padding: "20px 0px 21px",
    },
    stFirstChart: {
      padding: "0 16px",
    },
    stSecondWeeks: {
      paddingLeft: 16,
    },
    stFirstRank: { padding: 17 },
    nothingToDisplay: {
      padding: "19px 0px 19px",
      fontSize: 14,
      textAlign: "center",
    },
    weekData: {
      display: "none",
    },
    mobileTopCards: {
      display: "none",
    },
    weekDataContainer: {
      padding: 17,
    },
    sameWeekTxt: {
      fontSize: 12,
      marginTop: 6,
    },
    weekTitleTxt: {
      fontSize: 18,
      marginBottom: 14,
    },

    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      stFirstRank: {
        display: "none",
      },
      stFirstChart: {
        display: "none",
      },
      stSecondWeeks: {
        display: "none",
      },
      topStatThird: {
        display: "none",
      },
      topStatsContainer: {
        display: "none",
      },
      sameWeekTxt: {
        fontSize: 8,
      },
      topStatFirst: {
        width: "50%",
        order: 2,
        paddingLeft: 8,
      },
      weekTitleTxt: {
        fontSize: 14,
        marginBottom: 10,
      },
      topStatSecond: {
        width: "50%",
        padding: "0 8px 0 0",
      },
      tablesContainer: {
        flexDirection: "column",
        padding: "0 16px",
        marginBottom: 35,
      },
      cwStats: {
        fontSize: 12,
      },
      standingTContainer: {
        width: "100%",
        padding: 0,
      },
      matchupTContainer: {
        width: "100%",
        padding: 0,
        marginBottom: 16,
      },
      weekDataContainer: {
        display: "none",
      },
      amaStatsTitleTopB: {
        fontSize: 29,
      },
      amaStatsTitleTopG: {
        fontSize: 29,
      },
      amaStatsTitleTopY: {
        fontSize: 29,
      },
      weekData: {
        display: "flex",
        marginBottom: 16,
      },
      mobileTopCards: {
        display: "flex",
        padding: "0 16px",
        marginBottom: 16,
      },
      hrRank: {
        fontSize: 10,
      },
      hrBox: {
        "& >div": {
          alignItems: "center",
          "& svg": {
            margin: "unset",
          },
          "& p": {
            fontSize: 20,
          },
        },
      },
      shadowContainer: {
        padding: "17px 5px",
      },
    },
  });

export default DashboardStyles;
