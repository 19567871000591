import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

import styles from "./NextMatchupSkeleton.module.scss";

interface INextMatchupSkeletonProps {}

const NextMatchupSkeleton: React.FunctionComponent<INextMatchupSkeletonProps> = () => {
  return (
    <div className={styles.skeletonContainer}>
      <Typography variant="h3">
        <Skeleton variant="text" />
      </Typography>
      <div className={styles.circleWithTextContainer}>
        <div>
          <Skeleton variant="circle" width={64} height={64} />
          <Skeleton variant="text" style={{ margin: "10px 0" }} />
        </div>
        <div>
          <Skeleton variant="circle" width={64} height={64} />
          <Skeleton variant="text" style={{ margin: "10px 0" }} />
        </div>
      </div>
    </div>
  );
};

export default NextMatchupSkeleton;
