import { Button, Grid, Typography, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import * as React from "react";
import DashboardStyles from "../Dashboard.styles";

interface IAddLeagueHeaderProps extends WithStyles<typeof DashboardStyles> {
  connectToNewLeague: () => void;
}

const AddLeagueHeader: React.FunctionComponent<IAddLeagueHeaderProps> = ({
  classes,
  connectToNewLeague,
}) => {
  return (
    <>
      <Grid container className={classes.topContainer}>
        <Grid container style={{ justifyContent: "space-between" }}>
          <Typography style={{ fontSize: 26, fontWeight: "bold" }}>
            My Leagues
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="primary"
            disableElevation
            onClick={() => connectToNewLeague()}
            className={classes.linkBtn}
          >
            Add League
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(DashboardStyles)(AddLeagueHeader);
