import { createStyles } from "@material-ui/core";

const MobileFilterStyles = (theme) =>
  createStyles({
    mainContainer: {
      padding: "0 10px",
      marginTop: 20,
      order: 1,
    },
    inputContainer: {
      "& input": {
        padding: 15,
        fontSize: 14,
        paddingLeft: 40,
      },
      "& fieldset": {
        border: "none",
      },
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "initial",
      width: "100%",
      position: "relative",
      borderRadius: 4,
      boxSizing: "border-box",
    },
    searchIcon: {
      fontSize: 26,
      color: "#7C828A",
    },
    filterIcon: {
      fontSize: 24,
      color: "#7C828A",
    },
    filterOverlay: {
      zIndex: 9999,
      position: "fixed",
      background: "#fff",
      width: "100%",
      height: "100%",
      top: 60,
      borderTop: "1px solid #e1e1e1",
      overflow: "auto",
    },
    dragableFilters: {
      position: "fixed",
      zIndex: 999,
      background: "#fff",
      width: "100%",
      height: "100%",
    },
    playersSearchField: {
      width: "100%",
      margin: "-10px 15px 10px 15px",
    },
  });

export default MobileFilterStyles;
