import { Skeleton } from "@material-ui/lab";
import React from "react";

import styles from "./CircleAndLineSkeleton.module.scss";

interface ICircleAndLineSkeletonProps {}

const CircleAndLineSkeleton: React.FunctionComponent<ICircleAndLineSkeletonProps> = () => {
  return (
    <div className={styles.skeletonContainer}>
      <Skeleton variant="circle" width={64} height={64} />
      <Skeleton variant="text" style={{ margin: "10px 0" }} />
    </div>
  );
};

export default CircleAndLineSkeleton;
