import { createStyles } from "@material-ui/core";
import adminTheme from "../../../themes/admin/adminTheme";

const ForecastStyles = (theme) =>
  createStyles({
    userData: {
      width: "100%",
      maxWidth: 286,
    },
    remainin: {
      width: "15%",
      display: "flex",
      justifyContent: "space-between",
      paddingRight: 16,
      boxSizing: "border-box",
      maxWidth: 172,
    },
    stats: {
      maxWidth: 728,
      width: "100%",
    },
    score: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      maxWidth: 93,
    },
    userDataImg: {
      width: 35,
      height: 35,
      borderRadius: "50%",
      marginRight: 5,
    },
    teamSummary: {
      fontSize: 10,
    },
    statWhiteTop: {
      "& p": {
        width: "80px",
        borderRight: "1px solid gray",
        textAlign: "center",
        fontSize: 16,
        fontWeight: 700,
      },
      "& p:last-child": {
        borderRight: "none",
      },
      display: "flex",
      borderRadius: 12,
      boxShadow: "0px 1px 3px #00000029",
      padding: "14px 0",
      width: "100%",
    },
    stateMidleRow: {
      "& p": {
        width: "100px",
        textAlign: "center",
        fontSize: 12,
      },
      display: "flex",
      height: 47,
      alignItems: "center",
      width: "100%",
    },
    optionSelect: {
      "& p": {
        padding: "6px 16px",
        fontSize: 12,
        cursor: "pointer",
      },
      marginBottom: 16,
      display: "flex",
      boxShadow: "0px 1px 3px #00000029",
      borderRadius: 12,
      overflow: "hidden",
    },
    opActive: {
      backgroundColor: "#FF9718",
      color: "#fff",
    },
    numberBox: {
      padding: "8px 0",
      boxShadow: "0px 1px 3px #00000029",
      borderRadius: 12,
      fontSize: 24,
      fontWeight: 700,
      fontFamily: "'Poppins'",
      textAlign: "center",
      width: 70,
    },
    midTextBox: {
      fontSize: 10,
      fontFamily: "'Montserrat'",
      textAlign: "center",
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    scoreTextBox: {
      fontWeight: 700,
      textTransform: "uppercase",
      marginRight: 8,
      fontSize: 14,
      fontFamily: "'Montserrat'",
      textAlign: "center",
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    teamTitle: {
      fontSize: 14,
      color: "#191847",
      fontWeight: 600,
      fontFamily: "'Poppins'",
    },
    forecastMainContent: {
      display: "flex",
      width: 1280,
      margin: "0 auto",
    },
    playersTable: {
      maxWidth: 1280,
      margin: "35px auto 60px",
    },
    withBorder: {
      border: "2px solid #f49719",
    },
    teamDesc: {
      display: "flex",
      width: "80%",
      padding: "6px 10px 5px",
      borderRadius: 12,
      cursor: "pointer",
    },

    [adminTheme.breakpoints.down("xs")]: {
      optionSelect: {
        marginBottom: 0,
      },
    },
  });

export default ForecastStyles;
