import { Typography } from "@material-ui/core";
import * as React from "react";

interface Props {
  value: any;
  message?: string;
}

const ValueCheckContainer: React.FC<Props> = ({
  children,
  value,
  message = "Nothing to show here",
}) => {
  const isValid = () => {
    switch (true) {
      case Number.isNaN(value):
        return false;
      case value === null:
        return false;
      case value === "NaN":
        return false;
      case value === undefined:
        return false;
      case value === "":
        return false;
      case value.length === 0:
        return false;
      default:
        return true;
    }
  };

  if (isValid()) {
    return <>{children}</>;
  } else {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <Typography style={{ fontSize: 13, textAlign: "center" }}>
          {message}
        </Typography>
      </div>
    );
  }
};

export default ValueCheckContainer;
