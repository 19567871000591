import React from "react";
import { Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import { TypeOverview } from "../../../data-types/DataTypes";

import LeagueCardStyles from "./LeagueCard.styles";

interface Props extends WithStyles<typeof LeagueCardStyles> {
  yahooOverview: TypeOverview;
  onLeagueSelect: React.Dispatch<React.SetStateAction<string>>;
  checkedLeague: string;
  key:number;
}

const LeagueCard: React.FC<Props> = ({
  classes,
  yahooOverview,
  onLeagueSelect,
  checkedLeague,
  key
}) => {
  return (
    <Grid
      className={`${classes.container} ${
        yahooOverview.league.leagueKey === checkedLeague ? "active" : ""
      }`}
      key={key}
      onClick={() => onLeagueSelect(yahooOverview.league.leagueKey)}
    >
      <div className={classes.top}>
        <img src={yahooOverview.team.logo} alt="League logo" />
        <Typography>{yahooOverview.team.name}</Typography>
      </div>
      <div className={classes.bot}>
        <div className={classes.cardLeft}>
          <img src={yahooOverview.league.logoUrl} alt="team-logo" />
          <div>
            <Typography>{yahooOverview.league.name}</Typography>
            <Typography className={classes.summary}>
              {yahooOverview.league.summary}
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className={classes.listItem}>
            <Typography className={classes.listTopTxt}>Season</Typography>
            <Typography className={classes.listBotTxt}>
              {yahooOverview.league.season}
            </Typography>
          </div>
          <div className={classes.listItem}>
            <Typography className={classes.listTopTxt}>Teams</Typography>
            <Typography className={classes.listBotTxt}>
              {yahooOverview.league.numberOfTeams}
            </Typography>
          </div>
          <div className={classes.listItem}>
            <Typography className={classes.listTopTxt}>Scoring Type</Typography>
            <Typography className={classes.listBotTxt}>
              {yahooOverview.league.scoringType}
            </Typography>
          </div>
          <div className={classes.listItem}>
            <Typography className={classes.listTopTxt}>Week</Typography>
            <Typography className={classes.listBotTxt}>
              {yahooOverview.league.currentWeek
                ? yahooOverview.league.currentWeek
                : "-"}
            </Typography>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default withStyles(LeagueCardStyles)(LeagueCard);
