import React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { TypeMatchupStats } from "../../../components/general/data-types/DataTypes";

import ForecastStyles from "./Forecast.styles";

interface Props extends WithStyles<typeof ForecastStyles> {
  stats: TypeMatchupStats;
  homeWins: number;
  visitingWins: number;
}

const MatchupScoreComponent: React.FC<Props> = ({
  classes,
  stats,
  homeWins,
  visitingWins,
}) => {
  const getClassColor = (item: TypeMatchupStats, team: string) => {
    if (item.isTied) {
      return "#49495C";
    }
    if (team === "home") {
      return item.homeTeamWin ? "#17612E" : "#DB2721";
    } else {
      return item.homeTeamWin ? "#DB2721" : "#17612E";
    }
  };

  return (
    <>
      <div
        className={classes.numberBox}
        style={{
          color: getClassColor(stats, "home"),
        }}
      >
        {homeWins}
      </div>
      <div className={classes.scoreTextBox}>Score</div>
      <div
        className={classes.numberBox}
        style={{
          color: getClassColor(stats, "visiting"),
        }}
      >
        {visitingWins}
      </div>
    </>
  );
};

export default withStyles(ForecastStyles)(MatchupScoreComponent);
