import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { useAuth } from "../login/AuthContext";
import * as yup from "yup";
import { useFormik } from "formik";
import { Alert } from "@material-ui/lab";
import { toast } from "react-toastify";

import ForgotPassPopupStyles from "./ForgotPassPopup.styles";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

interface Props extends WithStyles<typeof ForgotPassPopupStyles> {
  setPageType: React.Dispatch<React.SetStateAction<string>>;
}

const ForgotPassPopup: React.FC<Props> = React.forwardRef(
  ({ classes, setPageType }, _) => {
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const { resetPassword } = useAuth();

    const openLogin = () => {
      setPageType("signIn");
    };

    const handleSubmit = async (values: any) => {
      try {
        setError("");
        await resetPassword(values.email)
          .then(() => {
            setMessage("Check your inbox for further instructions");
          })
          .catch((error: any) => {
            toast.error(error.message);
          });
      } catch {
        setError("Failed to reset password");
      }
    };

    const formik = useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmit(values);
      },
    });

    return (
      <Grid container className={classes.popupContainer}>
        <Grid container className={classes.popupDiv}>
          <Grid item xs={12}>
            <Typography className={classes.logoText}>Password Reset</Typography>
          </Grid>
          <Grid item xs={12}>
            {message && (
              <Alert style={{ marginBottom: 30 }} severity="success">
                {message}
              </Alert>
            )}
            <form onSubmit={formik.handleSubmit}>
              <TextField
                value={formik.values.email}
                label="Enter your username or Email"
                variant="outlined"
                className={classes.input}
                size="small"
                id="email"
                name="email"
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              {error && <Alert severity="error">{error}</Alert>}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.loginBtn}
              >
                Reset password
              </Button>
              <Typography
                onClick={openLogin}
                className={classes.afterResLoginBtn}
              >
                Login
              </Typography>
            </form>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default withStyles(ForgotPassPopupStyles)(ForgotPassPopup);
