import React from "react";
import { Typography, withStyles, WithStyles } from "@material-ui/core";
import LinearProgressBar from "../../../../components/general/linear-progress-bar/LinearProgressBar";
import {
  TypeScore,
  TypeCategoryElement,
} from "../../../../components/general/data-types/DataTypes";

import BotStatsStyles from "./BotStats.styles";

interface Props extends WithStyles<typeof BotStatsStyles> {
  matchupsScore: TypeScore;
  isMobile: boolean;
}

const BotStats: React.FC<Props> = ({ classes, matchupsScore, isMobile }) => {
  const itemColor = (item: TypeCategoryElement, team: string) => {
    if (item.isTied) {
      return "#FF9718";
    }
    if (team === "home") {
      return item.homeTeamWin ? "#518962" : "#a4a4ad";
    } else {
      return item.homeTeamWin ? "#a4a4ad" : "#518962";
    }
  };

  const textColor = (item: TypeCategoryElement, team: string) => {
    if (item.isTied) {
      return "#191847";
    }
    if (team === "home") {
      return item.homeTeamWin ? "#17612E" : "#DB2721";
    } else {
      return item.homeTeamWin ? "#DB2721" : "#17612E";
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {matchupsScore.stats.played.categories.map((score, index) => {
        return (
          <div
            className={classes.matchupStats}
            key={`${index}-${score.category}`}
          >
            <div className={classes.mlpFirst}>
              <LinearProgressBar
                progress={score.homeTeamPercentage}
                reverse
                color={itemColor(score, "home")}
                bgColor="#FBFBFB"
                height={isMobile ? 14 : 20}
              />
              <Typography
                className={classes.firstParagraph}
                style={{ color: textColor(score, "home") }}
              >
                {score.homeTeamValue}
                {score.isPercentage ? "%" : ""}
              </Typography>
            </div>
            <Typography className={classes.categoryTxt}>
              {score.category}
            </Typography>
            <div className={classes.mlpScond}>
              <Typography
                className={classes.secondParagraph}
                style={{ color: textColor(score, "visiting") }}
              >
                {score.visitingTeamValue}
                {score.isPercentage ? "%" : ""}
              </Typography>
              <LinearProgressBar
                progress={score.visitingTeamPercentage}
                color={itemColor(score, "visiting")}
                bgColor="#FBFBFB"
                height={isMobile ? 14 : 20}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withStyles(BotStatsStyles)(BotStats);
