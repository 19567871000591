import React, { useState } from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ReactComponent as YahooLogo } from "../../../static/images/Yahoo Logo.svg";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import HealingIcon from "@material-ui/icons/Healing";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PeopleIcon from "@material-ui/icons/People";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { TypeOverview } from "../../../components/general/data-types/DataTypes";
import {
  removeOverview,
  refreshOverview,
  changeSelectedOverview,
} from "../../../redux/actions/overviewsActions/overviewsActions";
import LinearProgressBar from "../../../components/general/linear-progress-bar/LinearProgressBar";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import adminTheme from "../../../themes/admin/adminTheme";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useDispatch } from "react-redux";

import DashboardLeagueCardStyles from "./DashboardLeagueCard.styles";

interface Props extends WithStyles<typeof DashboardLeagueCardStyles> {
  overview: TypeOverview;
}

const DashboardLeagueCard: React.FC<Props> = ({ classes, overview }) => {
  const analytics = getAnalytics();

  const [deletePopup, setDeletePopup] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(adminTheme.breakpoints.down("xs"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  const gameColor = (from?: string) => {
    if (overview.matchup.homeTeamWins === overview.matchup.visitingTeamWins) {
      return { color: "#49495C" };
    }
    const homeColor =
      overview.matchup.homeTeamWins > overview.matchup.visitingTeamWins
        ? "#17612E"
        : "#DB2721";
    const visitingColor =
      overview.matchup.homeTeamWins < overview.matchup.visitingTeamWins
        ? "#17612E"
        : "#DB2721";
    return { color: from === "home" ? homeColor : visitingColor };
  };

  const removeLeague = (id: string) => {
    dispatch(removeOverview(id));
    setDeletePopup(false);
    logEvent(analytics, "remove_league");
  };

  const refreshLeague = (id: string) => {
    dispatch(refreshOverview(id));
    logEvent(analytics, "refresh_league");
  };

  const onLeagueTitleClick = (id: string) => {
    dispatch(changeSelectedOverview(id));
    // localStorage.setItem("selectedOverview", id);
    navigate(`/league/${id}`);
  };

  const showOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        aria-labelledby="responsive-dialog-title"
        className={classes.delDialog}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete League"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure? This action can't be undone!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setDeletePopup(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => removeLeague(overview.league.id)}
            color="secondary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container className={classes.leagueCardContainer}>
        <Grid container className={classes.leagueCardHead}>
          <Grid
            item
            xs={6}
            sm={3}
            className={classes.logoAndName}
            onClick={() => onLeagueTitleClick(overview.league.id)}
          >
            {overview.league.logoUrl ? (
              <img src={overview.league.logoUrl} alt="leagueLogo" />
            ) : (
              <img
                src="https://s.yimg.com/cv/api/default/20180206/default-league-logo@2x.png"
                alt="leagueLogo"
              />
            )}
            <div>
              <Typography className={classes.logoName}>
                {overview.league.name}
              </Typography>
              <Typography className={classes.logoSummary}>
                {overview.league.summary}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.topDataContainer}>
            <div className={classes.topData}>
              <Typography className={classes.tpDataLabel}>Season</Typography>
              <Typography className={classes.tpDataVal}>
                {overview.league.season || "-"}
              </Typography>
            </div>
            <div className={classes.topData}>
              <Typography className={classes.tpDataLabel}>Teams</Typography>
              <Typography className={classes.tpDataVal}>
                {overview.league.numberOfTeams || "-"}
              </Typography>
            </div>
            <div className={classes.topData}>
              <Typography className={classes.tpDataLabel}>
                Scoring Type
              </Typography>
              <Typography className={classes.tpDataVal}>
                {overview.league.scoringType || "-"}
              </Typography>
            </div>
            <div className={classes.topData}>
              <Typography className={classes.tpDataLabel}>Week</Typography>
              <Typography className={classes.tpDataVal}>
                {overview.league.currentWeek || "-"}
              </Typography>
            </div>
            <div className={classes.topData}>
              <Typography className={classes.tpDataLabel}>Rank</Typography>
              <Typography className={classes.tpDataVal}>
                {overview.team.rank || "-"}
              </Typography>
            </div>
            <div className={classes.topData}>
              <Typography className={classes.tpDataLabel}>
                Games Back
              </Typography>
              <Typography className={classes.tpDataVal}>
                {overview.team.gamesBack || "-"}
              </Typography>
            </div>
            <div className={classes.topData}>
              <Typography className={classes.tpDataLabel}>Waiver</Typography>
              <Typography className={classes.tpDataVal}>
                {overview.team.waiverPriority || "-"}
              </Typography>
            </div>
            <div className={classes.topData}>
              <Typography className={classes.tpDataLabel}>Moves</Typography>
              <Typography className={classes.tpDataVal}>
                {overview.team.nrMoves || "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.topDataOtions}>
            {!isMobile && (
              <>
                <IconButton onClick={() => refreshLeague(overview.league.id)}>
                  <RefreshIcon />
                </IconButton>
                <IconButton onClick={() => setDeletePopup(true)}>
                  <DeleteIcon />
                </IconButton>
                {/* <IconButton aria-label="options" onClick={showOptions}>
                  <MoreVertIcon />
                </IconButton> */}
              </>
            )}
            {isMobile && (
              <>
                <div className={classes.topData}>
                  <Typography className={classes.tpDataLabel}>Rank</Typography>
                  <Typography className={classes.tpDataVal}>
                    {overview.team.rank || "-"}
                  </Typography>
                </div>
                <div className={classes.topData}>
                  <Typography className={classes.tpDataLabel}>Week</Typography>
                  <Typography className={classes.tpDataVal}>
                    {overview.league.currentWeek || "-"}
                  </Typography>
                </div>
                <IconButton aria-label="options" onClick={showOptions}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="options-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={handleClose}
                    className={classes.optionsMenu}
                  >
                    <RefreshIcon
                      onClick={() => refreshLeague(overview.league.id)}
                    />
                    <Typography>Refresh</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    className={classes.optionsMenu}
                  >
                    <DeleteIcon onClick={() => setDeletePopup(true)} />
                    <Typography>Delete</Typography>
                  </MenuItem>
                </Menu>{" "}
              </>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            sm={6}
            className={classes.firstCardContainer}
          >
            <Grid item xs={4} className={classes.healthContainer}>
              <div>
                <div style={{ marginBottom: 21 }}>
                  <div>
                    <LocalHospitalIcon className={classes.hoverIcon} />
                    <Typography>Players Health</Typography>
                  </div>
                </div>
                {overview.matchup && (
                  <div>
                    <div>
                      <HealingIcon className={classes.iconHeal} />
                      <Typography>
                        {overview.matchup.homeTeam.injuredPlayers}
                      </Typography>
                    </div>
                    <div>
                      <HelpOutlineIcon className={classes.iconQuestion} />
                      <Typography>
                        {overview.matchup.homeTeam.questionablePlayers}
                      </Typography>
                    </div>
                    <div>
                      <CheckCircleIcon className={classes.iconCheck} />
                      <Typography>
                        {overview.matchup.homeTeam.healthyPlayers}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={3} className={classes.playingToday}>
              <div>
                <div style={{ marginBottom: 21 }}>
                  <div>
                    <DirectionsRunIcon className={classes.hoverIcon} />
                    <Typography>Playing Today</Typography>
                  </div>
                </div>
                {overview.matchup && (
                  <div>
                    <PeopleIcon className={classes.iconPeople} />
                    <Typography style={{ lineHeight: "32px" }}>
                      {overview.matchup.homeTeam.playingToday}
                    </Typography>
                  </div>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              className={classes.gamesPlayed}
              onClick={() => navigate(`/matchup/${overview.matchup.id}`)}
            >
              {overview.matchup && (
                <>
                  <div className={classes.gamesPlayedData}>
                    <div className={classes.gamesPlayedText}>
                      <Typography className={classes.gpText}>
                        Games Played
                      </Typography>
                      <Typography className={classes.gpSecondText}>
                        {overview.matchup.homeTeamCompletedGames}/
                        {overview.matchup.homeTeamTotalGames}
                      </Typography>
                    </div>
                    <LinearProgressBar
                      progress={
                        (overview.matchup.homeTeamCompletedGames /
                          overview.matchup.homeTeamTotalGames) *
                        100
                      }
                      bgColor="#D2D2D2"
                      color="#558B95"
                      height={3}
                    />
                    <div className={classes.gamesPlayedDataUnder}>
                      <div className={classes.gamesPlayedUnder}>
                        <div>
                          <Typography
                            className={classes.gpuText}
                            style={{
                              fontWeight:
                                overview.matchup.homeTeam.name ===
                                overview.team.name
                                  ? 600
                                  : 500,
                            }}
                          >
                            {overview.matchup.homeTeam.name}
                          </Typography>
                          <Typography className={classes.gpuRankText}>
                            {overview.matchup.homeTeam.summary}
                            {isMobile && (
                              <span className={classes.smallHIcon}>
                                <HealingIcon />
                                {overview.matchup.homeTeam.injuredPlayers}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </div>
                      <img
                        src={overview.matchup.homeTeam.logo}
                        alt="Home team logo"
                      />
                    </div>
                  </div>
                  <Typography
                    className={classes.gpuNumber}
                    style={gameColor("home")}
                  >
                    {overview.matchup.homeTeamWins}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            className={classes.secondCardContainer}
          >
            <Grid item xs={4} className={classes.healthContainer}>
              <div>
                <div style={{ marginBottom: 21 }}>
                  <div>
                    <LocalHospitalIcon className={classes.hoverIcon} />
                    <Typography>Players Health</Typography>
                  </div>
                </div>
                {overview.matchup && (
                  <div>
                    <div>
                      <HealingIcon className={classes.iconHeal} />
                      <Typography>
                        {overview.matchup.visitingTeam.injuredPlayers}
                      </Typography>
                    </div>
                    <div>
                      <HelpOutlineIcon className={classes.iconQuestion} />
                      <Typography>
                        {overview.matchup.visitingTeam.questionablePlayers}
                      </Typography>
                    </div>
                    <div>
                      <CheckCircleIcon className={classes.iconCheck} />
                      <Typography>
                        {overview.matchup.visitingTeam.healthyPlayers}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={3} className={classes.playingToday}>
              <div>
                <div style={{ marginBottom: 21 }}>
                  <div>
                    <DirectionsRunIcon className={classes.hoverIcon} />
                    <Typography>Playing Today</Typography>
                  </div>
                </div>
                {overview.matchup && (
                  <div>
                    <PeopleIcon className={classes.iconPeople} />
                    <Typography style={{ lineHeight: "32px" }}>
                      {overview.matchup.visitingTeam.playingToday}
                    </Typography>
                  </div>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              className={classes.secondGamesPlayed}
              onClick={() => navigate(`/matchup/${overview.matchup.id}`)}
            >
              {overview.matchup && (
                <>
                  <Typography className={classes.gpuNumber} style={gameColor()}>
                    {overview.matchup.visitingTeamWins}
                  </Typography>
                  <div className={classes.gamesPlayedData}>
                    <div className={classes.gamesPlayedText}>
                      <Typography className={classes.gpSecondText}>
                        {overview.matchup.visitingTeamCompletedGames}/
                        {overview.matchup.visitingTeamTotalGames}
                      </Typography>
                      <Typography className={classes.gpText}>
                        Games Played
                      </Typography>
                    </div>

                    <LinearProgressBar
                      progress={
                        (overview.matchup.visitingTeamCompletedGames /
                          overview.matchup.visitingTeamTotalGames) *
                        100
                      }
                      reverse={isMobile ? false : true}
                      bgColor="#D2D2D2"
                      color="#558B95"
                      height={3}
                    />
                    <div className={classes.gamesPlayedDataUnder}>
                      <img
                        src={overview.matchup.visitingTeam.logo}
                        alt="Visiting team logo"
                      />
                      <div className={classes.gamesSecondPlayedUnder}>
                        <div>
                          <Typography
                            className={classes.gpuText}
                            style={{
                              fontWeight:
                                overview.matchup.visitingTeam.name ===
                                overview.team.name
                                  ? 600
                                  : 500,
                            }}
                          >
                            {overview.matchup.visitingTeam.name}
                          </Typography>
                          <Typography className={classes.gpuRankText}>
                            {overview.matchup.visitingTeam.summary}
                            {isMobile && (
                              <span className={classes.smallHIcon}>
                                <HealingIcon />
                                {overview.matchup.visitingTeam.injuredPlayers}
                              </span>
                            )}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.updateTime}>
            {overview.league.provider === "Yahoo" ? <YahooLogo /> : ""}
            <Typography>{overview.league.updatedTimeSpan}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(DashboardLeagueCardStyles)(DashboardLeagueCard);
