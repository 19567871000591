import { Button, Typography, withStyles, WithStyles } from "@material-ui/core";
import * as config from "../../../config/config";
import * as React from "react";
import YahooIcon from "../../../static/logo/YahooIcon.png";
import DashboardStyles from "../Dashboard.styles";

interface IConnectToYahooPageProps extends WithStyles<typeof DashboardStyles> {}

const ConnectToYahooPage: React.FC<IConnectToYahooPageProps> = ({
  classes,
}) => {
  const fetchYahooAuthUrl = () => {
    const yahooAuthUrl = `${config.yahooAuth.apiUrl}?client_id=${config.yahooAuth.clientId}&redirect_uri=${config.yahooAuth.redirectUri}&response_type=code`;
    // openNewWindow(yahooAuthUrl);
    window.location.href = yahooAuthUrl;
  };
  // const openNewWindow = (link: string) => {
  //   window.location.href = link;
  // };

  return (
    <>
      <div className={classes.hederContainer}>
        <Typography className={classes.connectYahooTitle}>
          <img
            src={YahooIcon}
            alt="Yahoo"
            style={{ maxWidth: 52, marginRight: 15 }}
          />
          Connect to Yahoo Fantasy Sports
        </Typography>
        <Typography className={classes.connectYahooSubTitle}>
          Sign in to your Yahoo Account
        </Typography>
        <Typography className={classes.connectYahooUnderTitle}>
          Click continue to complete the set up!
        </Typography>
      </div>
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        disableElevation
        onClick={() => fetchYahooAuthUrl()}
        className={classes.continueWYahoo}
      >
        Continue
      </Button>
    </>
  );
};

export default withStyles(DashboardStyles)(ConnectToYahooPage);
