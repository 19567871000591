import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import HeightIcon from "@material-ui/icons/Height";
import ThreeLineSkeleton from "../../skeletons/ThreeLineSkeleton/ThreeLineSkeleton";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";

import styles from "./RankBox.module.scss";

interface IRankBoxProps {}

const RankBox: React.FunctionComponent<IRankBoxProps> = () => {
  const {
    overviews: { selectedOverview },
  } = useSelector((state: RootState) => state);
  const overviewTeam = selectedOverview?.team;

  const showRankArrow = () => {
    if (overviewTeam) {
      const rank = overviewTeam.lastWeekRank - overviewTeam.rank;
      if (rank > 0) {
        return <ArrowUpwardIcon style={{ color: "#17612E" }} />;
      } else if (rank < 0) {
        return <ArrowDownwardIcon style={{ color: "#DB2721" }} />;
      }
      return <HeightIcon style={{ color: "#49495C" }} />;
    }
  };

  if (overviewTeam == null) {
    return (
      <Grid
        item
        xs={3}
        className={`${styles.shadowContainer} ${styles.stFirstRank}`}
      >
        <ThreeLineSkeleton />
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={3}
      className={`${styles.shadowContainer} ${styles.stFirstRank}`}
    >
      <Typography className={styles.hrRank}>Rank</Typography>
      <div className={styles.hrBox}>
        <div>
          {showRankArrow()}
          <Typography>{overviewTeam.ordinal}</Typography>
        </div>
        {overviewTeam.rank === overviewTeam.lastWeekRank ? (
          <Typography className={styles.sameWeekTxt}>
            Same as last week
          </Typography>
        ) : (
          <>
            <Typography className={styles.sameWeekTxt}>
              {overviewTeam.lastWeekRank - overviewTeam.rank > 0 ? "+" : ""}
              {overviewTeam.lastWeekRank - overviewTeam.rank} from last week
            </Typography>
          </>
        )}
      </div>
    </Grid>
  );
};

export default RankBox;
