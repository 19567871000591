import React from "react";
import { Typography, WithStyles, withStyles } from "@material-ui/core";
import { TypeLeague } from "../../../../components/general/data-types/DataTypes";
import LinearProgressBar from "../../../../components/general/linear-progress-bar/LinearProgressBar";

import DashboardStyles from "../Dashboard.styles";

interface Props extends WithStyles<typeof DashboardStyles> {
  league: TypeLeague;
}

const Week: React.FC<Props> = ({ classes, league }) => {
  return (
    <>
      <Typography className={classes.weekTitleTxt}>
        Week {league.currentWeek}
      </Typography>
      <LinearProgressBar
        progress={(league.currentWeek / league.endWeek) * 100}
        bgColor="#7388A95A"
        color="#FF9718"
        height={8}
      />
      <Typography className={classes.cwStats}>
        {league.currentWeek} of {league.endWeek}
      </Typography>
    </>
  );
};

export default withStyles(DashboardStyles)(Week);
