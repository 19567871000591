import { createMuiTheme } from "@material-ui/core/styles";

const adminTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#FF4133",
    },
    secondary: {
      main: "#558B95",
    },
  },
  typography: {
    fontFamily: "Montserrat, Roboto, sans-serif",
    h3: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 600,
      fontSize: "22px",
    },
    subtitle1: {
      fontFamily: "Poppins, Roboto, sans-serif",
      fontWeight: 600,
      fontSize: "14px",
    },
    body1: {
      fontFamily: "Montserrat, Roboto, sans-serif",
      fontWeight: 500,
      fontSize: "16px",
    },
    // subtitle2:{
    //   fontFamily: "Roboto, sans-serif",
    //   fontWeight: 600,
    //   fontSize: "14px",
    // }
  },
});

export default adminTheme;
