import { Dispatch } from "redux";
import * as types from "../action-types/actionTypes";
import { OverviewActions } from "./overviewsActionsInterface";
import {
  deleteLeague,
  fetchLeagueOverview,
  refreshLeague,
} from "../../../components/api/general/api";
import { beginApiCall } from "../apiStatusAction/apiStatusAction";
import {
  noResponse,
  TypeOverview,
} from "../../../components/general/data-types/DataTypes";

export function fetchOverviewsSuccess(data: TypeOverview[]): OverviewActions {
  return { type: types.FETCH_OVERVIEWS_SUCCESS, data };
}

export function fetchOverviewFailed(data: TypeOverview[]): OverviewActions {
  return { type: types.FETCH_OVERVIEWS_FAILED, data };
}

export function changeSelectedOverviewSuccess(id: string) {
  return { type: types.CHANGE_SELECTED_OVERVIEW_SUCCESS, id };
}

const getLeagueOverviews = (dispatch: Dispatch) => {
  fetchLeagueOverview()
    .then((res) => {
      const statusCode = res.status;
      const data = res.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(fetchOverviewsSuccess(data));
      } else {
        dispatch(fetchOverviewFailed(noResponse));
      }
    })
    .catch(() => {
      dispatch(fetchOverviewFailed(noResponse));
    });
};

export function loadOverviewsData() {
  return function (dispatch: Dispatch) {
    dispatch(beginApiCall("BEGIN_API_CALL_OVERVIEW"));
    return getLeagueOverviews(dispatch);
  };
}

export function changeSelectedOverview(id: string) {
  return function (dispatch: Dispatch) {
    dispatch(beginApiCall("NEW_LEAGUE"));
    return dispatch(changeSelectedOverviewSuccess(id));
  };
}

export function removeOverview(id: string) {
  return function (dispatch: Dispatch) {
    dispatch(beginApiCall("BEGIN_API_CALL_OVERVIEW"));
    return deleteLeague(id).then(() => getLeagueOverviews(dispatch));
  };
}

export function refreshOverview(id: string) {
  return function (dispatch: Dispatch) {
    dispatch(beginApiCall("BEGIN_API_CALL_OVERVIEW"));
    return refreshLeague(id).then(() => getLeagueOverviews(dispatch));
  };
}
