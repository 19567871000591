import * as config from "../../../config/config";
import Cookie from "js-cookie";
import { TypeOverview } from "../../general/data-types/DataTypes";

const tokenId = Cookie.get("tokenId");

export function signUp(userObject: any) {
  const request = new Request(`${config.auth.signUp}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userObject),
  });

  return fetch(request);
}

export function signIn(email: string) {
  const request = new Request(`${config.auth.signIn}?email=${email}`, {
    method: "GET",
  });

  return fetch(request);
}

export function fetchYahooUrl(tokenId: string) {
  const request = new Request(`${config.yahooAuth.fetchUrl}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenId,
    },
  });

  return fetch(request);
}

export function generateYahooToken(code: string) {
  const request = new Request(
    `${config.yahooAuth.generateToken}?code=${code}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export async function fetchYahooUserLeagues(): Promise<TypeOverview[]> {
  const request = new Request(`${config.yahooFantasyBasket.fetchUserLeagues}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenId,
    },
  });
  let response = await fetch(request);
  return await response.json();
}

export function addNewLeague(leagueKey: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${leagueKey}`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + tokenId,
        "Content-Type": "application/json",
      },
    }
  );

  return fetch(request);
}

export function fetchLeagues() {
  const request = new Request(`${config.yahooFantasyBasket.leagues}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenId,
    },
  });

  return fetch(request);
}

export function fetchLeageStandings(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/standings`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchLeagueMatchups(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/matchups`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchLeagueSchedule(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/schedule`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchLeagueOverview() {
  const newToken = Cookie.get("tokenId");
  const request = new Request(
    `${config.yahooFantasyBasket.fetchLeaguesOverview}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + newToken,
      },
    }
  );

  return fetch(request);
}

export function fetchWeeklyAdditions(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/standings/week-adds`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchMatchupWins(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/matchup-wins`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export async function fetchNextMatchup(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/next-matchup`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchMatchupScore(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.matchups}/${id}/score`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchMatchupForecast(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.matchups}/${id}/forecast`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function refreshLeague(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/refresh`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + tokenId,
        "Content-Type": "application/json",
      },
    }
  );

  return fetch(request);
}

export function deleteLeague(id: string) {
  const request = new Request(`${config.yahooFantasyBasket.leagues}/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + tokenId,
    },
  });

  return fetch(request);
}

export function fetchTeam(id: string) {
  const request = new Request(`${config.yahooFantasyBasket.teams}/${id}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenId,
    },
  });

  return fetch(request);
}

export function fetchTeamRooster(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.teams}/${id}/roster`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchTeamTotalStats(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/team-totals`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchTeamWinStats(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/team-wins`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchTeamAverageStats(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/team-averages`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchAllPlayers(
  pageNumber: number = 0,
  leagueId: string,
  filters?: any
) {
  const withLeagueId = `&filter=league.id:EQ:${leagueId}`;
  const withStatus = filters && filters.status ? `;${filters.status}` : "";
  const withOwner =
    filters && filters.fantasyTeam ? `;owner:EQ:${filters.fantasyTeam}` : "";
  const withHealth = filters && filters.health ? `;${filters.health}` : "";
  const withFranchise =
    filters && filters.franchise
      ? `;player.teamCode:EQ:${filters.franchise}`
      : "";
  const withName =
    filters && filters.name && filters.name !== "All"
      ? `;player.fullName:EQ:${filters.name}`
      : "";
  const withPosition =
    filters && filters.position
      ? `;player.displayPosition:LIKE:*${filters.position}*`
      : "";
  const withGamesRemaining =
    filters && filters.gamesRemaining
      ? `;player.weekGamesRemaining:EQ:${filters.gamesRemaining}`
      : "";
  const withDate = filters && filters.date ? `&gameDate=${filters.date}` : "";
  const request = new Request(
    `${config.yahooFantasyBasket.players}?pageNumber=${pageNumber}${withLeagueId}${withStatus}${withOwner}${withHealth}${withFranchise}${withName}${withPosition}${withGamesRemaining}${withDate}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchLeagueTeams(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/filter-teams`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchFranchises() {
  const request = new Request(
    `${config.nbaService.franchises}?attributes=fullName,tricode`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchLeaguePlayerNames(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/filter-players`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchNBASchedule() {
  const request = new Request(
    `${config.yahooFantasyBasket.games}/nba/schedule`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function positionFilters(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/filter-positions`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchTransactions(id: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${id}/transactions`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function fetchLeaguePlayer(leagueId: string, playerId: string) {
  const request = new Request(
    `${config.yahooFantasyBasket.leagues}/${leagueId}/players/${playerId}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function replacePlayer(
  id: string,
  dropPlayerId: string,
  addPlayerId: string
) {
  const request = new Request(
    `${config.yahooFantasyBasket.teams}/${id}/replace-player?dropPlayerId=${dropPlayerId}&addPlayerId=${addPlayerId}`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + tokenId,
      },
    }
  );

  return fetch(request);
}

export function verifyUser(email: string, token: string) {
  const request = new Request(`${config.auth.users}/${email}/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return fetch(request);
}

export function fetchUser(email: string) {
  const newToken = Cookie.get("tokenId");
  const request = new Request(
    `${config.auth.users}/find-by-email?email=${email}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + newToken,
      },
    }
  );

  return fetch(request);
}

export function deleteLocalUser(id: string) {
  const request = new Request(`${config.auth.users}/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + tokenId,
    },
  });

  return fetch(request);
}

export function retrieveStripeDonateSessionUrl(priceId: string) {
  const uri = `${
    config.payment.createDonateSession
  }?domain=${encodeURIComponent(config.backendCfg.domain!)}&priceId=${priceId}`;
  console.log("uri: ", uri);
  const request = new Request(uri, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + tokenId,
    },
  });

  return fetch(request);
}

export function fetchStripDonatePrices() {
  const request = new Request(`${config.payment.stripePriceList}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenId,
    },
  });

  return fetch(request);
}
