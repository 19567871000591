import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import LoadingCircle from "../../../components/general/LoadingCircle/LoadingCircle";
import {
  GameSchedule,
  TypeFrachise,
  TypeOverview,
} from "../../../components/general/data-types/DataTypes";
import { fetchFranchises } from "../../../components/api/general/api";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ScheduleStyles from "./Schedule.styles";

interface Props extends WithStyles<typeof ScheduleStyles> {
  schedule: GameSchedule[];
  selectedOverview: TypeOverview;
}

const Schedule: React.FC<Props> = ({ classes, schedule, selectedOverview }) => {
  const [franchises, setFranchises] = useState<TypeFrachise[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<number>(
    selectedOverview.league.currentWeek
  );
  const { innerWidth } = window;

  const sortSchedual = (selectedWeek: number) => {
    return schedule.slice().sort((a, b) => {
      const sch1 = a.schedule.find((item) => item.week === selectedWeek);
      const sch2 = b.schedule.find((item) => item.week === selectedWeek);
      if (sch1!.totalGames < sch2!.totalGames) {
        return 1;
      }
      if (sch1!.totalGames > sch2!.totalGames) {
        return -1;
      }
      return 0;
    });
  };
  const [scheduleShown, setScheduleShown] = useState<GameSchedule[]>(
    sortSchedual(selectedWeek)
  );

  useEffect(() => {
    if (franchises.length === 0) {
      fetchFranchises()
        .then((res) => res.json())
        .then((val) => setFranchises(val));
    }
  }, [franchises.length]);

  const onSortClick = (week: number) => {
    if (scheduleShown.length !== 1) {
      setSelectedWeek(week);
      setScheduleShown(sortSchedual(week));
    }
  };

  const getNumber = (number: number) => {
    if (number < 3) {
      return (
        <Typography className={classes.numberFont} style={{ color: "#DB2721" }}>
          {number}
        </Typography>
      );
    } else if (number === 3) {
      return (
        <Typography
          className={classes.numberFont}
          style={{ color: "#000000", opacity: 0.5 }}
        >
          {number}
        </Typography>
      );
    } else if (number === 4) {
      return (
        <Typography className={classes.numberFont} style={{ color: "#FF9718" }}>
          {number}
        </Typography>
      );
    } else {
      return (
        <Typography className={classes.numberFont} style={{ color: "#17612E" }}>
          {number}
        </Typography>
      );
    }
  };

  const getFilteredData = (filter: string | null) => {
    if (filter) {
      setScheduleShown(
        scheduleShown.filter((item) => item.teamName === filter)
      );
    } else {
      setScheduleShown(schedule);
    }
  };
  return (
    <>
      <Grid
        container
        className={classes.mainComp}
        style={{ maxWidth: innerWidth - 30 }}
      >
        {scheduleShown.length ? (
          <Grid container className={classes.body}>
            <TableContainer
              style={{
                maxWidth: innerWidth - 30,
              }}
            >
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    <TableCell
                      className={`${classes.tableSearch} ${classes.sticky}`}
                    >
                      <Autocomplete
                        options={franchises.map((item) => item.fullName)}
                        freeSolo
                        renderInput={(params) => (
                          <TextField {...params} label="Search" />
                        )}
                        className={classes.selectTeam}
                        onChange={(_, newValue) => {
                          getFilteredData(newValue);
                        }}
                      />
                    </TableCell>
                    {scheduleShown[0].schedule.map((item, i) => (
                      <TableCell
                        align="center"
                        key={i}
                        className={`${classes.headNumbers} ${
                          item.week === selectedWeek ? classes.activeHead : ""
                        }`}
                        onClick={() => onSortClick(item.week)}
                      >
                        {i + 1}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scheduleShown.map((item, i) => (
                    <TableRow key={i} hover>
                      <TableCell className={classes.sticky}>
                        <img
                          alt={item.teamName}
                          src={item.teamLogo}
                          className={classes.tableImg}
                        ></img>
                        <Typography className={classes.tableImgTxt}>
                          {item.teamName}
                        </Typography>
                      </TableCell>
                      {item.schedule.map((sc, index) => (
                        <TableCell
                          key={index}
                          className={
                            sc.week === selectedWeek ? classes.activeCell : ""
                          }
                          style={{
                            borderBottom:
                              sc.week === selectedWeek &&
                              i === scheduleShown.length - 1
                                ? "2px solid #f49719"
                                : "",
                          }}
                        >
                          {sc.week === index + 1 && sc.totalGames === 0 ? (
                            <div className={classes.emptyCell}>
                              <Typography>-</Typography>
                            </div>
                          ) : (
                            <>
                              <div className={classes.number}>
                                {sc.week === index + 1 &&
                                  getNumber(sc.totalGames)}
                              </div>
                              <div className={classes.summary}>
                                <div className={classes.sfText}>
                                  H{sc.homeGames}
                                </div>
                                <div>@{sc.visitingGames}</div>
                              </div>
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <LoadingCircle />
        )}
      </Grid>
    </>
  );
};

export default withStyles(ScheduleStyles)(Schedule);
