import React from "react";
import {
  Avatar,
  // Modal,
  TableCell,
  TableRow,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import moment from "moment";
// import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
// import SwapHorizontalCircleOutlinedIcon from "@material-ui/icons/SwapHorizontalCircleOutlined";

import {
  TypeOpponent,
  TypeOverview,
  TypeTeamPlayer,
  TypeLeaguePlayer,
} from "../general/data-types/DataTypes";
import { getHealth } from "../general/utils/Utils";
// import SwapModal from "../../containers/team/roster/swap-modal/SwapModal";

import PlayersTableStyles from "./PlayersTable.styles";

interface Props extends WithStyles<typeof PlayersTableStyles> {
  player: TypeLeaguePlayer | TypeTeamPlayer;
  index: number;
  weekDays: string[];
  selectedDay: string;
  selectedPlayersLength: number;
  selectedOverview: TypeOverview;
  swappable?: boolean;
}

const Row: React.FC<Props> = ({
  classes,
  player,
  index,
  weekDays,
  selectedDay,
  selectedPlayersLength,
  selectedOverview,
  swappable,
}) => {
  // const [showSwapModal, setShowSwapModal] = useState(false);

  const getOpponentData = (date: string, opponents: TypeOpponent[]) => {
    const opponent = opponents.filter((item) => item.date === date)[0];
    const isOldDate =
      selectedOverview &&
      opponent &&
      moment(opponent.date).diff(
        moment(selectedOverview.league.currentDate),
        "days"
      ) >= 0
        ? false
        : true;

    if (opponent) {
      return (
        <img
          style={{
            maxWidth: 35,
            maxHeight: 35,
            verticalAlign: "middle",
            opacity: isOldDate ? "0.5" : "1",
          }}
          src={opponent.logoUrl}
          alt={opponent.code}
        />
      );
    } else {
      return "";
    }
  };

  // const getStatusAction = (status: string, statusType: string) => {
  //   // if (swappable) {
  //   const teamName = selectedOverview.team.name;
  //   switch (statusType) {
  //     case "team":
  //       if (status === teamName) {
  //         return (
  //           <RemoveCircleOutlineIcon
  //             className={classes.redMinus}
  //             style={{ cursor: swappable ? "pointer" : "" }}
  //             onClick={() => swappable && setShowSwapModal(true)}
  //           />
  //         );
  //       } else {
  //         return (
  //           <SwapHorizontalCircleOutlinedIcon
  //             className={classes.graySwap}
  //             style={{ cursor: swappable ? "pointer" : "" }}
  //             onClick={() => swappable && setShowSwapModal(true)}
  //           />
  //         );
  //       }
  //     case "freeagents":
  //       return (
  //         <AddCircleOutlineIcon
  //           className={classes.greenPlus}
  //           style={{ cursor: swappable ? "pointer" : "" }}
  //           onClick={() => swappable && setShowSwapModal(true)}
  //         />
  //       );
  //     case "waivers":
  //       return (
  //         <AddCircleOutlineIcon
  //           className={classes.yellowPlus}
  //           style={{ cursor: swappable ? "pointer" : "" }}
  //           onClick={() => swappable && setShowSwapModal(true)}
  //         />
  //       );
  //     default:
  //       return (
  //         <AddCircleOutlineIcon
  //           className={classes.yellowPlus}
  //           style={{ cursor: swappable ? "pointer" : "" }}
  //           onClick={() => swappable && setShowSwapModal(true)}
  //         />
  //       );
  //   }
  // };

  return (
    <>
      {/* <Modal open={showSwapModal} onClose={() => setShowSwapModal(false)}>
        <SwapModal
          player={player}
          hidePopup={() => setShowSwapModal(false)}
          leagueId={selectedOverview.league.id}
          teamId={selectedOverview.team.id}
        />
      </Modal> */}
      <TableRow key={index} hover>
        <TableCell
          align="center"
          className={`${classes.lessPadding} ${classes.smallTD}`}
        >
          <Typography className={classes.tdText}>
            {player.type === "league" ? player.rank : player.position}
          </Typography>
        </TableCell>
        <TableCell className={classes.playersData}>
          <Avatar
            alt={player.name}
            src={player.headShotUrl}
            className={classes.avImage}
          />
          <div style={{ paddingTop: 4 }}>
            <Typography className={classes.playerName}>
              {player.name}
              {/* {getStatusAction(player.status, player.statusType)} */}
            </Typography>
            <div className={classes.playerDetails}>
              <span>{player.details}</span>
              {getHealth(player, true)}
            </div>
          </div>
        </TableCell>
        {player.type === "league" && (
          <TableCell align="center">{player.status}</TableCell>
        )}
        {weekDays.map((date, i) => {
          if (player.opponents && player.opponents.length > 0) {
            return (
              <TableCell
                align="center"
                key={`tri-${i}`}
                style={{
                  borderBottom:
                    selectedDay === date && index === selectedPlayersLength - 1
                      ? "2px solid #f49719"
                      : "",
                }}
                className={`${
                  selectedDay === date ? classes.selectedDayRow : ""
                } ${classes.lessPadding} ${classes.weekDayLogos}`}
              >
                {getOpponentData(date, player.opponents)}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={`tri-${i}`}
                className={`${classes.weekDayLogos} ${
                  selectedDay === date ? classes.selectedDayRow : ""
                }`}
              ></TableCell>
            );
          }
        })}
      </TableRow>
    </>
  );
};

export default withStyles(PlayersTableStyles)(Row);
