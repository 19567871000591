import { createStyles } from "@material-ui/core";
import adminTheme from "../../../themes/admin/adminTheme";

const TeamStatStyles = (theme) =>
  createStyles({
    tableCell: {
      "& img": {
        width: 38,
        borderRadius: "50%",
        float: "left",
        marginRight: 5,
      },
      padding: "0 !important",
    },
    tableDataContainer: {
      padding: "6px 5px 6px 5px",
      border: "1px solid transparent",
    },
    asColumn: {
      "& p": {
        fontSize: 12,
      },
      display: "flex",
      flexDirection: "column",
    },
    container: {
      "& .MuiTableCell-sizeSmall": {
        padding: "10px 5px 10px 5px",
      },
      maxWidth: 1600,
      margin: "0 auto 50px",
    },
    optionSelect: {
      "& p": {
        padding: "6px 16px",
        fontSize: 12,
        cursor: "pointer",
      },
      marginBottom: 16,
      display: "flex",
      boxShadow: "0px 1px 3px #00000029",
      borderRadius: 12,
      overflow: "hidden",
    },
    opActive: {
      backgroundColor: "#FF9718",
      color: "#fff",
    },
    filterContainer: {
      display: "flex",
      justifyContent: "flex-end",
      maxWidth: 1600,
      margin: "0 auto",
    },
    filterControl: {
      minWidth: 120,
      maxWidth: 250,
      width: "100%",
    },
    chip: {
      display: "flex",
      flexWrap: "wrap",
    },
    hidden: {
      opacity: 0.1,
    },
    resetBtn: {
      "& svg": {
        fontSize: 30,
      },
      margin: "9px 0 0 20px",
    },

    /////////////// - Media Queries Down - ///////////////
    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      sticky: {
        left: 0,
        position: "sticky",
        background: "#fbfbfb",
      },
      container: {
        padding: "0 15px",
      },
      tableDataContainer: {
        display: "flex",
        alignItems: "center",
      },
      tableCell: {
        "& img": {
          width: 24,
        },
        minWidth: 170,
      },
    },
  });

export default TeamStatStyles;
