import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { generateYahooToken } from "../../api/general/api";
import useRefresh from "../../useRefresh/useRefresh";
import { useLocation } from "react-router-dom";

const YahooCallbackComponent: React.FC = (props) => {
  const navigate = useNavigate();
  const refresh = useRefresh(navigate, "/overview");
  const location = useLocation();

  useEffect(() => {
    const userCode = location.search.split("=")[1];
    if (userCode) {
      generateYahooToken(userCode).then(() => {
        refresh();
      });
    }
  }, [props, refresh, location.search]);

  return <></>;
};

export default YahooCallbackComponent;
