import { createStyles } from "@material-ui/core";
import adminTheme from "../../themes/admin/adminTheme";

const DashboardStyles = (theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      display: "flex",
      justifyContent: "center",
    },
    conatiner: {
      maxWidth: 1508,
      flexWrap: "nowrap",
      flexDirection: "column",
    },
    tempPopup: {
      maxWidth: "800px",
      background: "#fff",
      zIndex: 9999,
      position: "absolute",
      top: 300,
      left: 400,
      borderRadius: 10,
      border: "1px solid gray",
      padding: 20,
    },
    linkBtn: {
      fontSize: 15,
      borderRadius: 30,
      height: 40,
      width: 161,
      fontFamily: "Montserrat",
      fontWeight: 500,
    },
    topContainer: {
      padding: "32px 0 16px",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      textAlign: "center",
      borderBottom: "1px solid #f0f0f0",
      marginBottom: 32,
    },
    addBtnsContainer: {
      "& button": {
        "&:hover": {
          borderWidth: 2,
        },
        borderRadius: 50,
        borderWidth: 2,
      },
      display: "flex",
      justifyContent: "space-between",
      minWidth: 305,
      marginTop: 15,
    },
    leaguesTableContainer: {},
    mobileAddIcon: {
      position: "fixed",
      bottom: 66,
      right: 15,
      width: 56,
      height: 56,
      background: "#558b95",
      color: "#fff",
    },
    selectLeaguesContainer: {
      position: "fixed",
      paddingBottom: 40,
      top: "16%",
      left: "calc(50% - 334px)",
      background: "#fff",
      width: 740,
      borderRadius: 12,
      zIndex: 9,
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
    noLeagueSubtext: {
      fontFamily: "Poppins",
      fontSize: 18,
      color: "#49495C",
      marginBottom: 30,
      textAlign: "center",
      marginTop: 20,
      letterSpacing: "1.8px",
    },
    addLeagueTitle: {
      textAlign: "center",
      fontSize: 36,
      fontWeight: 600,
    },
    connectYahooTitle: {
      fontSize: 32,
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      marginBottom: 100,
    },
    connectYahooSubTitle: {
      fontSize: 46,
      fontWeight: "bold",
      marginBottom: 15,
    },
    connectYahooUnderTitle: {
      fontSize: 18,
      color: "#49495C",
      letterSpacing: "1.8px",
    },
    hederContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 50,
    },
    continueWYahoo: {
      margin: "32px auto",
      width: 400,
      borderRadius: 5,
      borderWidth: "2px !important",
      fontWeight: "bold",
      letterSpacing: "1.8px",
      fontSize: 18,
    },
    /////////////// - Media Queries Down - ///////////////

    [adminTheme.breakpoints.down("xl")]: {},
    [adminTheme.breakpoints.down("lg")]: {},
    [adminTheme.breakpoints.down("md")]: {},
    [adminTheme.breakpoints.down("sm")]: {},
    [adminTheme.breakpoints.down("xs")]: {
      selectLeaguesContainer: {
        width: "100%",
        height: "100%",
        boxShadow: "none",
        borderRadius: "unset",
        top: "unset",
        left: "unset",
      },
      itemsContainer: {
        width: "100%",
        padding: 0,
      },
      cardLeft: {
        "& p": {
          fontSize: 14,
        },
      },
      topContainer: {
        display: "none",
      },
    },
  });

export default DashboardStyles;
