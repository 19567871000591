import { createStyles } from "@material-ui/core";

const SliderCardStyles = (theme) =>
  createStyles({
    top: {
      "& img": {
        width: 27,
        height: 27,
        borderRadius: "50%",
      },
      "& p": {
        fontSize: 10,
      },
      display: "flex",
      marginBottom: 20,
      justifyContent: "center",
    },
    topText: {
      textAlign: "left",
      paddingLeft: 8,
    },
    line: {
      content: "''",
      position: "absolute",
      top: -29,
      left: "calc(50% - 8px)",
      width: 11,
      height: 11,
      background: "#fff",
      border: "3px solid",
      borderRadius: "50%",
      zIndex: 9,
    },
    playerImg: {
      width: 60,
      marginBottom: 5,
    },
    pName: {
      fontWeight: 600,
      color: "#212529",
      marginBottom: 12,
      fontSize: 14,
      textAlign: "center",
    },
    subtitle: {
      fontSize: 10,
      color: "#00000080",
      textTransform: "uppercase",
      textAlign: "center",
      whiteSpace: "break-spaces",
    },
    icon: {
      fontSize: 20,
      marginRight: 7,
    },
    iconText: {
      fontSize: 10,
      lineHeight: "21px",
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      paddingTop: 6,
    },
    playerContent: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    profileContent: {
      display: "flex",
      minHeight: 170,
      direction: "initial",
      marginTop: 23,
      position: "relative",
    },
  });

export default SliderCardStyles;
