import {
  Grid,
  Typography,
  withStyles,
  WithStyles,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  TypeOverview,
  TypeScore,
  TypeTeamPlayer,
} from "../../../components/general/data-types/DataTypes";
import PlayersTable from "../../../components/players-table/PlayersTable";
import StatsComponent from "./StatsComponent";
import WeeklyMovesComponent from "./WeeklyMovesComponent";
import MatchupScoreComponent from "./MatchupScoreComponent";
import { RootState } from "../../../redux/reducers";
import {
  loadTeamRoster,
  changeSelectedTeam,
} from "../../../redux/actions/teamActions/teamActions";
import { fetchTeamRooster } from "../../../components/api/general/api";
import LoadingCircle from "../../../components/general/LoadingCircle/LoadingCircle";
import AdminTheme from "../../../themes/admin/adminTheme";
import MobileTable from "../../../components/mobile-table/MobileTable";
import ScoreStats from "../../../components/score-stats/ScoreStats";
import StatOptionsBar from "../../../components/stat-options-bar/StatOptionsBar";
import { useSelector, useDispatch } from "react-redux";

import ForecastStyles from "./Forecast.styles";

interface Props extends WithStyles<typeof ForecastStyles> {
  selectedOverview: TypeOverview;
  matchupsScore: TypeScore;
  isMyMatchup: boolean;
  teamRoster: TypeTeamPlayer[];
  setTeamRoster: React.Dispatch<React.SetStateAction<TypeTeamPlayer[]>>;
}

const Forecast: React.FC<Props> = ({
  classes,
  selectedOverview,
  matchupsScore,
  isMyMatchup,
  teamRoster,
  setTeamRoster,
}) => {
  const [options, setOptions] = useState("Forecast");
  const isMobile = useMediaQuery(AdminTheme.breakpoints.down("xs"));
  const [localLoading, setLocalLoading] = useState(false);

  const {
    team: { roster, loading, selectedTeam },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  console.log({ loading });

  const getStatsData = () => {
    switch (options) {
      case "Forecast":
        return (
          <StatsComponent category={matchupsScore.stats.forecast.categories} />
        );
      case "Projected":
        return (
          <StatsComponent category={matchupsScore.stats.projected.categories} />
        );
      case "Averages":
        return (
          <StatsComponent category={matchupsScore.stats.averages.categories} />
        );
      case "Played":
        return (
          <StatsComponent category={matchupsScore.stats.played.categories} />
        );
      default:
        return "";
    }
  };

  const showWeeklyMoves = () => {
    switch (options) {
      case "Projected":
      case "Forecast":
        return (
          <WeeklyMovesComponent
            future={true}
            homeAdds={matchupsScore.matchup.homeTeamAddsAvailable}
            visitingAdds={matchupsScore.matchup.visitingTeamAddsAvailable}
            homeGames={matchupsScore.matchup.homeTeamRemainingGames}
            visitingGames={matchupsScore.matchup.visitingTeamRemainingGames}
          />
        );
      case "Averages":
      case "Played":
        return (
          <WeeklyMovesComponent
            future={false}
            homeAdds={matchupsScore.matchup.homeTeamAddsMade}
            visitingAdds={matchupsScore.matchup.visitingTeamAddsMade}
            homeGames={matchupsScore.matchup.homeTeamCompletedGames}
            visitingGames={matchupsScore.matchup.visitingTeamCompletedGames}
          />
        );
      default:
        return "";
    }
  };

  const showMatchupScore = () => {
    switch (options) {
      case "Played":
        return (
          <MatchupScoreComponent
            stats={matchupsScore.stats.played}
            homeWins={matchupsScore.stats.played.homeTeamWins}
            visitingWins={matchupsScore.stats.played.visitingTeamWins}
          />
        );
      case "Averages":
        return (
          <MatchupScoreComponent
            stats={matchupsScore.stats.averages}
            homeWins={matchupsScore.stats.averages.homeTeamWins}
            visitingWins={matchupsScore.stats.averages.visitingTeamWins}
          />
        );
      case "Projected":
        return (
          <MatchupScoreComponent
            stats={matchupsScore.stats.projected}
            homeWins={matchupsScore.stats.projected.homeTeamWins}
            visitingWins={matchupsScore.stats.projected.visitingTeamWins}
          />
        );
      case "Forecast":
        return (
          <MatchupScoreComponent
            stats={matchupsScore.stats.forecast}
            homeWins={matchupsScore.stats.forecast.homeTeamWins}
            visitingWins={matchupsScore.stats.forecast.visitingTeamWins}
          />
        );
      default:
        return "";
    }
  };

  const onTeamSelect = (team: string) => {
    switch (team) {
      case "firstTeam":
        if (isMyMatchup) {
          dispatch(loadTeamRoster(matchupsScore.matchup.homeTeam.id));
          dispatch(changeSelectedTeam(matchupsScore.matchup.homeTeam.name));
        } else {
          setLocalLoading(true);
          fetchTeamRooster(matchupsScore.matchup.homeTeam.id)
            .then((res) => {
              const statusCode = res.status;
              const data = res.json();
              return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
              if (statusCode === 200) {
                setTeamRoster(data);
              }
              setLocalLoading(false);
            });
          dispatch(changeSelectedTeam(matchupsScore.matchup.homeTeam.name));
        }
        return;
      case "secondTeam":
        if (isMyMatchup) {
          dispatch(loadTeamRoster(matchupsScore.matchup.visitingTeam.id));
          dispatch(changeSelectedTeam(matchupsScore.matchup.visitingTeam.name));
        } else {
          setLocalLoading(true);
          fetchTeamRooster(matchupsScore.matchup.visitingTeam.id)
            .then((res) => {
              const statusCode = res.status;
              const data = res.json();
              return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
              if (statusCode === 200) {
                setTeamRoster(data);
              }
              setLocalLoading(false);
            });
          dispatch(changeSelectedTeam(matchupsScore.matchup.visitingTeam.name));
        }
        return;
    }
  };

  return (
    <Grid container>
      {!isMobile && (
        <StatOptionsBar options={options} setOptions={setOptions} />
      )}
      {isMobile ? (
        <ScoreStats
          selectedOverview={selectedOverview}
          matchupsScore={matchupsScore}
          isMobile={isMobile}
        />
      ) : (
        <Grid container className={classes.forecastMainContent}>
          <div className={classes.userData}>
            <div
              className={
                selectedTeam === matchupsScore.matchup.homeTeam.name
                  ? `${classes.withBorder} ${classes.teamDesc}`
                  : classes.teamDesc
              }
              onClick={() => onTeamSelect("firstTeam")}
              style={{ marginBottom: selectedTeam === "firstTeam" ? 51 : 55 }}
            >
              <img
                className={classes.userDataImg}
                src={matchupsScore.matchup.homeTeam.logo}
                alt="Home Team Logo"
              />
              <div>
                <Typography className={classes.teamTitle}>
                  {matchupsScore.matchup.homeTeam.name}
                </Typography>
                <Typography className={classes.teamSummary}>
                  {matchupsScore.matchup.homeTeam.summary}
                </Typography>
              </div>
            </div>
            <div
              className={
                selectedTeam === matchupsScore.matchup.visitingTeam.name
                  ? `${classes.withBorder} ${classes.teamDesc}`
                  : classes.teamDesc
              }
              onClick={() => onTeamSelect("secondTeam")}
            >
              <img
                className={classes.userDataImg}
                src={matchupsScore.matchup.visitingTeam.logo}
                alt="Visiting Team Logo"
              />
              <div>
                <Typography className={classes.teamTitle}>
                  {matchupsScore.matchup.visitingTeam.name}
                </Typography>
                <Typography className={classes.teamSummary}>
                  {matchupsScore.matchup.visitingTeam.summary}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.remainin}>{showWeeklyMoves()}</div>
          <div className={classes.stats}>{getStatsData()}</div>
          <div className={classes.score}>{showMatchupScore()}</div>
        </Grid>
      )}
      {loading || localLoading ? (
        <div style={{ width: "100%", height: 500 }}>
          <LoadingCircle />
        </div>
      ) : (
        <Grid container className={classes.playersTable}>
          {isMobile ? (
            <MobileTable
              players={isMyMatchup ? roster : teamRoster}
              selectedOverview={selectedOverview}
              startDay={new Date(selectedOverview.matchup.weekStart)}
              endDay={new Date(selectedOverview.matchup.weekEnd)}
              options={options}
              weeksPosFromTop={369}
            />
          ) : (
            <PlayersTable
              players={isMyMatchup ? roster : teamRoster}
              selectedOverview={selectedOverview}
              tableWidth={840}
              options={options}
              startDay={new Date(selectedOverview.matchup.weekStart)}
              endDay={new Date(selectedOverview.matchup.weekEnd)}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(ForecastStyles)(Forecast);
