import React, { useEffect, useRef, useState } from "react";
import {
  WithStyles,
  withStyles,
  Typography,
  Slider,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment";
import {
  TypeFilterPosition,
  TypeFilters,
  TypeFrachise,
  TypeOverview,
} from "../../general/data-types/DataTypes";
import { health, status } from "../../general/utils/Utils";

import PlayerStyles from "../../../containers/players/Players.styles";

interface Props extends WithStyles<typeof PlayerStyles> {
  selectedOverview: TypeOverview | undefined;
  filters: TypeFilters;
  setFilters: React.Dispatch<React.SetStateAction<TypeFilters>>;
  getPlayers: (
    pageNmber: number,
    filters: any,
    withFilter?: boolean | undefined
  ) => void;
  teams: any[];
  onFilterChange: (filter: string, value: any) => void | "";
  mobileFilterOpenPage: string;
  setMobileFilterOpenPage: React.Dispatch<React.SetStateAction<string>>;
  hideFilters: () => void;
  startDay: Date | undefined;
  endDay: Date | undefined;
  franchises: TypeFrachise[];
  positions: TypeFilterPosition[];
}

const MobileFiltersPage: React.FC<Props> = ({
  classes,
  selectedOverview,
  filters,
  setFilters,
  getPlayers,
  teams,
  onFilterChange,
  mobileFilterOpenPage,
  setMobileFilterOpenPage,
  hideFilters,
  startDay,
  endDay,
  franchises,
  positions,
}) => {
  const getSelectedDay = () => {
    if (selectedOverview) {
      if (
        moment(selectedOverview.league.currentDate).diff(
          selectedOverview.matchup.weekEnd,
          "days"
        ) > 0
      ) {
        return selectedOverview.matchup.weekEnd;
      } else {
        return selectedOverview.league.currentDate;
      }
    }
  };
  const [selectedDay, setSelectedDay] = useState(getSelectedDay());
  const [weekDays, setWeekDays] = useState<string[]>([]);

  const openFilterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let week: string[] = [];
    if (startDay) {
      const nrOfDays = moment(endDay).diff(moment(startDay), "days");
      for (let i = 0; i <= nrOfDays; i++) {
        let first = moment(startDay).add(i, "days");
        let day = first.toDate().toISOString().slice(0, 10);
        week = [...week, day];
      }
      setWeekDays(week);
    }
  }, [startDay, endDay]);

  useEffect(() => {
    //Close popup on outside click
    const handleClickOutside = (event: any) => {
      if (
        openFilterRef.current &&
        !openFilterRef.current.contains(event.target)
      ) {
        setMobileFilterOpenPage && setMobileFilterOpenPage("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openFilterRef, setMobileFilterOpenPage]);

  const onSetFilterMobile = (filterType: string, value: string) => {
    setFilters({ ...filters, [filterType]: value });
    filterType === "date" && setSelectedDay(value);
  };
  const gamesRemainin = [1, 2, 3, 4, 5];

  const onShowSideFilterClick = (type: string) => {
    if (mobileFilterOpenPage === "") {
      setMobileFilterOpenPage && setMobileFilterOpenPage(type);
    } else {
      setMobileFilterOpenPage && setMobileFilterOpenPage("");
    }
  };

  const onMobileFilterSubmit = () => {
    hideFilters();
    getPlayers(0, filters, true);
  };

  return (
    <div className={classes.filterPageContainer}>
      {mobileFilterOpenPage !== "" && <div className={classes.darkOverlay} />}
      <div className={classes.filterHeader}>
        <ExpandMoreIcon className={classes.filterArrowClose} />
        <Typography className={classes.filterTopT}>Filter</Typography>
      </div>
      <div className={classes.filterItemBox}>
        <Typography className={classes.filterTitle}>Status</Typography>
        <div className={classes.sButonsContainer}>
          <div
            className={`${classes.selectableButtons} ${
              filters.status === "" ? "active" : ""
            }`}
            onClick={() => onSetFilterMobile("status", "")}
          >
            <Typography>All</Typography>
          </div>
          {status.map((item) => (
            <div
              className={`${classes.selectableButtons} ${
                item.value === filters.status ? "active" : ""
              }`}
              onClick={() => onSetFilterMobile("status", item.value)}
            >
              <Typography>{item.label}</Typography>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.filterItemBox}>
        <Typography className={classes.filterTitle}>Health</Typography>
        <div className={classes.sButonsContainer}>
          <div
            className={`${classes.selectableButtons} ${
              filters.health === "" ? "active" : ""
            }`}
            onClick={() => onSetFilterMobile("health", "")}
          >
            <Typography>All</Typography>
          </div>
          {health.map((item) => (
            <div
              className={`${classes.selectableButtons} ${
                item.value === filters.health ? "active" : ""
              }`}
              onClick={() => onSetFilterMobile("health", item.value)}
            >
              <Typography>{item.label}</Typography>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.filterItemBox}>
        <div className={classes.filterPageOpener}>
          <Typography className={classes.filterOpenerTitle}>
            Position
          </Typography>
          <ArrowForwardIosIcon
            className={classes.filterOpenerArrow}
            onClick={() => onShowSideFilterClick("position")}
          />
        </div>
        <AnimatePresence>
          {mobileFilterOpenPage === "position" && (
            <motion.div
              className={classes.sideMenuContainer}
              initial={{ x: 400 }}
              animate={{ x: 80 }}
              exit={{ x: 400 }}
              ref={openFilterRef}
            >
              <CloseIcon
                className={classes.sideFilterCloseIcon}
                onClick={() => onShowSideFilterClick("")}
              />
              <Typography className={classes.sideFilterTitle}>
                {mobileFilterOpenPage}
              </Typography>
              <RadioGroup
                name="position"
                className={classes.filtersOptionsContainer}
                value={filters.position}
                onChange={(e) => onFilterChange("position", e.target.value)}
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                {positions.map((item, i) => (
                  <FormControlLabel
                    key={i}
                    value={item.gamePosition.abbreviation}
                    control={<Radio />}
                    label={item.gamePosition.displayName}
                  />
                ))}
              </RadioGroup>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className={classes.filterItemBox}>
        <Typography className={classes.filterTitle}>Playing on</Typography>

        <div style={{ display: "flex", marginBottom: 10 }}>
          {weekDays.map((item, index) => (
            <div
              className={`${selectedDay === item ? classes.selectedDay : ""} ${
                classes.weekDay
              }`}
              key={`thr-${index}`}
              onClick={() => onSetFilterMobile("date", item)}
            >
              {moment(item).format("ddd MMM DD").replace(" ", "\n")}
            </div>
          ))}
        </div>
      </div>
      <div className={classes.filterItemBox}>
        <Typography className={classes.filterTitle}>Games remaining</Typography>
        <div className={classes.sliderNumbers}>
          {gamesRemainin.map((item) => (
            <span>{item}</span>
          ))}
        </div>
        <Slider
          defaultValue={1}
          className={classes.slideComp}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          onChange={(_, val) =>
            onSetFilterMobile("gamesRemaining", val.toString())
          }
          step={1}
          marks
          min={1}
          max={5}
        />
      </div>
      <div className={classes.filterItemBox}>
        <div className={classes.filterPageOpener}>
          <Typography className={classes.filterOpenerTitle}>
            Fantasy team
          </Typography>
          <ArrowForwardIosIcon
            className={classes.filterOpenerArrow}
            onClick={() => onShowSideFilterClick("fantasy team")}
          />
        </div>
        <AnimatePresence>
          {mobileFilterOpenPage === "fantasy team" && (
            <motion.div
              className={classes.sideMenuContainer}
              initial={{ x: 400 }}
              animate={{ x: 80 }}
              exit={{ x: 400 }}
              ref={openFilterRef}
            >
              <CloseIcon
                className={classes.sideFilterCloseIcon}
                onClick={() => onShowSideFilterClick("")}
              />
              <Typography className={classes.sideFilterTitle}>
                {mobileFilterOpenPage}
              </Typography>
              <RadioGroup
                name="fantasyTeam"
                className={classes.filtersOptionsContainer}
                value={filters.fantasyTeam}
                onChange={(e) => onFilterChange("fantasyTeam", e.target.value)}
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                {teams.map((item, i) => (
                  <FormControlLabel
                    key={i}
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className={classes.filterItemBox}>
        <div className={classes.filterPageOpener}>
          <Typography className={classes.filterOpenerTitle}>
            NBA team
          </Typography>
          <ArrowForwardIosIcon
            className={classes.filterOpenerArrow}
            onClick={() => onShowSideFilterClick("NBA team")}
          />
        </div>
        <AnimatePresence>
          {mobileFilterOpenPage === "NBA team" && (
            <motion.div
              className={classes.sideMenuContainer}
              initial={{ x: 400 }}
              animate={{ x: 80 }}
              exit={{ x: 400 }}
              ref={openFilterRef}
            >
              <CloseIcon
                className={classes.sideFilterCloseIcon}
                onClick={() => onShowSideFilterClick("")}
              />
              <Typography className={classes.sideFilterTitle}>
                {mobileFilterOpenPage}
              </Typography>
              <RadioGroup
                name="NBATeam"
                className={classes.filtersOptionsContainer}
                value={filters.franchise}
                onChange={(e) => onFilterChange("franchise", e.target.value)}
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                {franchises.map((item, i) => (
                  <FormControlLabel
                    key={i}
                    value={item.tricode}
                    control={<Radio />}
                    label={item.fullName}
                  />
                ))}
              </RadioGroup>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className={classes.applyButtonContainer}>
        <Button
          variant="outlined"
          type="button"
          className={classes.applyButton}
          onClick={() => onMobileFilterSubmit()}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default withStyles(PlayerStyles)(MobileFiltersPage);
