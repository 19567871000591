import { Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import React from "react";
import { TypeMatchup } from "../../../../../components/general/data-types/DataTypes";
import LinearProgressBar from "../../../../../components/general/linear-progress-bar/LinearProgressBar";
import { useNavigate } from "react-router-dom";

import MatchupCardStyles from "./MatchupCard.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";

interface Props extends WithStyles<typeof MatchupCardStyles> {
  matchup: TypeMatchup;
}

const MatchupCard: React.FC<Props> = ({ classes, matchup }) => {
  const {
    overviews: { selectedOverview },
  } = useSelector((state: RootState) => state);

  const gameColor = (from?: string) => {
    if (matchup.homeTeamWins === matchup.visitingTeamWins) {
      return { color: "#49495C" };
    }
    const homeColor =
      matchup.homeTeamWins > matchup.visitingTeamWins ? "#17612E" : "#DB2721";
    const visitingColor =
      matchup.homeTeamWins < matchup.visitingTeamWins ? "#17612E" : "#DB2721";
    return { color: from === "home" ? homeColor : visitingColor };
  };
  const navigate = useNavigate();

  const onRowClick = () => {
    if (selectedOverview?.matchup.id === matchup.id) {
      navigate(`/my-matchup/${matchup.id}`);
    } else {
      navigate(`/matchup/${matchup.id}`);
    }
  };

  const getTeamName = (name: string) => {
    return <Typography className={classes.gpuText}>{name}</Typography>;
  };

  return (
    <>
      <Grid
        container
        className={classes.mainContainer}
        onClick={() => onRowClick()}
      >
        <Grid item xs={6} className={classes.gamesPlayed}>
          <div className={classes.gamesPlayedData}>
            <div className={classes.gamesPlayedText}>
              <Typography className={classes.gpSecondText}>
                {matchup.homeTeamCompletedGames}/
                {matchup.homeTeamCompletedGames +
                  matchup.homeTeamRemainingGames}
              </Typography>
            </div>
            <LinearProgressBar
              progress={
                (matchup.homeTeamCompletedGames /
                  (matchup.homeTeamCompletedGames +
                    matchup.homeTeamRemainingGames)) *
                100
              }
              reverse
              bgColor="#D2D2D2"
              color="#558B95"
              height={3}
            />
            <div className={classes.gamesPlayedDataUnder}>
              <div className={classes.gamesPlayedUnder}>
                <div>
                  {getTeamName(matchup.homeTeam.name)}
                  <Typography className={classes.gpuRankText}>
                    {matchup.homeTeam.summary}
                  </Typography>
                </div>
              </div>
              <img src={matchup.homeTeam.logo} alt="Home team logo" />
              <Typography
                className={`${classes.gpuNumber} ${classes.mobileOnly} ${classes.leftWinsNum}`}
                style={gameColor("home")}
              >
                {matchup.homeTeamWins}
              </Typography>
            </div>
          </div>
          <Typography
            className={`${classes.gpuNumber} ${classes.desktopOnly}`}
            style={gameColor("home")}
          >
            {matchup.homeTeamWins}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.secondCardContainer}>
          <Typography
            className={`${classes.gpuNumber} ${classes.desktopOnly}`}
            style={gameColor()}
          >
            {matchup.visitingTeamWins}
          </Typography>
          <div className={classes.secondGamesPlayed}>
            <div className={classes.gamesPlayedData}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography className={classes.gpSecondText}>
                  {matchup.visitingTeamCompletedGames}/
                  {matchup.visitingTeamCompletedGames +
                    matchup.visitingTeamRemainingGames}
                </Typography>
              </div>

              <LinearProgressBar
                progress={
                  (matchup.visitingTeamCompletedGames /
                    (matchup.visitingTeamCompletedGames +
                      matchup.visitingTeamRemainingGames)) *
                  100
                }
                bgColor="#D2D2D2"
                color="#558B95"
                height={3}
              />
              <div className={classes.gamesPlayedDataUnder}>
                <Typography
                  className={`${classes.gpuNumber} ${classes.mobileOnly}`}
                  style={gameColor()}
                >
                  {matchup.visitingTeamWins}
                </Typography>
                <img src={matchup.visitingTeam.logo} alt="Visiting team logo" />
                <div className={classes.gamesSecondPlayedUnder}>
                  <div>
                    {getTeamName(matchup.visitingTeam.name)}
                    <Typography className={classes.gpuRankText}>
                      {matchup.visitingTeam.summary}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(MatchupCardStyles)(MatchupCard);
