import { TypeTransaction } from "../../components/general/data-types/DataTypes";
import { TransactionActions } from "../actions/transactionActions/transactionActionInterface";
import * as types from "../actions/action-types/actionTypes";

export default function transactionsReducer(
  state = [] as TypeTransaction[],
  action: TransactionActions
) {
  switch (action.type) {
    case types.FETCH_TRANSACTIONS_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
